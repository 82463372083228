*, :before, :after {
  box-sizing: border-box;
  border-style: solid;
  border-width: 0;
  border-color: rgb(var(--text-color) / .12);
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  tab-size: 4;
  scroll-padding-block-start: calc(var(--sticky-area-height)  + 20px);
  line-height: 1.5;
}

body {
  --background: var(--background-primary);
  --text-color: var(--text-primary);
  background: rgb(var(--background));
  color: rgb(var(--text-color));
  font: var(--text-font-style) var(--text-font-weight) var(--text-base) / 1.6 var(--text-font-family);
  letter-spacing: var(--text-letter-spacing);
  margin: 0;
  position: relative;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  text-transform: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  text-align: inherit;
  background-color: #0000;
  background-image: none;
}

button, label, summary, [role="button"], [type="checkbox"], [type="radio"], [type="submit"] {
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  cursor: pointer;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  -webkit-user-select: none;
  user-select: none;
  list-style-type: none;
}

summary::-webkit-details-marker {
  display: none;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

:disabled {
  cursor: default;
}

img, video, iframe, object {
  vertical-align: middle;
  display: block;
}

img {
  color: #0000;
  max-width: min(var(--image-mobile-max-width, var(--image-max-width, 100%)), 100%);
  height: auto;
}

video {
  max-width: 100%;
  width: 100%;
  height: auto;
}

:focus:not(:focus-visible) {
  outline: none;
}

[hidden] {
  display: none !important;
}

height-observer, visibility-progress, scroll-area, video-media, model-media, media-carousel, impact-text, recently-viewed-products {
  display: block;
}

@media screen and (min-width: 700px) {
  img {
    max-width: min(var(--image-max-width, 100%), 100%);
  }
}

.pswp {
  --pswp-bg: rgb(var(--background-primary));
  --pswp-placeholder-bg: rgb(var(--background-primary));
  --pswp-root-z-index: 100000;
  --pswp-icon-color: rgb(var(--background-primary));
  --pswp-icon-color-secondary: rgb(var(--background-primary));
  --pswp-icon-stroke-color: rgb(var(--text-primary));
  --pswp-icon-stroke-width: 2px;
  --pswp-error-text-color: rgb(var(--text-primary));
  width: 100%;
  height: 100%;
  z-index: var(--pswp-root-z-index);
  touch-action: none;
  opacity: .003;
  contain: layout style size;
  -webkit-tap-highlight-color: #0000;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.pswp img {
  max-width: none;
}

.pswp--open {
  display: block;
}

.pswp, .pswp__bg {
  will-change: opacity;
  transform: translateZ(0);
}

.pswp__bg {
  opacity: .005;
  background: var(--pswp-bg);
}

.pswp, .pswp__scroll-wrap {
  overflow: hidden;
}

.pswp__scroll-wrap, .pswp__bg, .pswp__container, .pswp__item, .pswp__content, .pswp__img, .pswp__zoom-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.pswp__img, .pswp__zoom-wrap {
  width: auto;
  height: auto;
}

.pswp--click-to-zoom.pswp--zoom-allowed .pswp__img {
  cursor: var(--cursor-zoom-in-svg-url) 28 28, zoom-in;
}

.pswp--click-to-zoom.pswp--zoomed-in .pswp__img {
  cursor: grab;
}

.pswp--click-to-zoom.pswp--zoomed-in .pswp__img:active {
  cursor: grabbing;
}

.pswp--no-mouse-drag.pswp--zoomed-in .pswp__img, .pswp--no-mouse-drag.pswp--zoomed-in .pswp__img:active, .pswp__img {
  cursor: var(--cursor-zoom-out-svg-url) 28 28, zoom-out;
}

.pswp__container, .pswp__img, .pswp__button {
  -webkit-user-select: none;
  user-select: none;
}

.pswp__item {
  z-index: 1;
  overflow: hidden;
}

.pswp__hidden {
  display: none !important;
}

.pswp__content {
  pointer-events: none;
}

.pswp__content > * {
  pointer-events: auto;
}

.pswp__error-msg-container {
  display: grid;
}

.pswp__error-msg {
  color: var(--pswp-error-text-color);
  margin: auto;
  font-size: 1em;
  line-height: 1;
}

.pswp .pswp__hide-on-close {
  opacity: .005;
  will-change: opacity;
  transition: opacity var(--pswp-transition-duration) cubic-bezier(.4, 0, .22, 1);
  z-index: 10;
  pointer-events: none;
}

.pswp--ui-visible .pswp__hide-on-close {
  opacity: 1;
  pointer-events: auto;
}

.pswp__button {
  -webkit-touch-callout: none;
  display: block;
  position: relative;
  overflow: hidden;
}

.pswp__button:disabled {
  opacity: .3;
  cursor: auto;
}

.pswp__button svg {
  display: block;
}

.pswp__top-bar, .pswp__bottom-bar {
  width: 100%;
  z-index: 10;
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none !important;
}

.pswp__top-bar > *, .pswp__bottom-bar > * {
  pointer-events: auto;
  will-change: opacity;
}

.pswp__bottom-bar {
  justify-content: center;
  top: auto;
  bottom: 24px;
}

.pswp__bottom-bar .pagination {
  border-radius: var(--rounded-full);
}

.pswp__button--close {
  margin-block-start: 24px;
  margin-inline-end: 24px;
  transition: transform .2s;
}

.pswp__preloader {
  display: none;
}

@media screen and (min-width: 700px) {
  .pswp__bottom-bar {
    bottom: 48px;
  }

  .pswp__button--close {
    margin-block-start: 48px;
    margin-inline-end: 48px;
  }
}

@media screen and (pointer: fine) {
  .pswp__button--close:hover {
    transform: rotate(90deg);
  }
}

.accordion {
  --accordion-spacing: var(--spacing-5);
  box-sizing: content-box;
  border-block-start-width: 1px;
  border-block-end-width: 1px;
}

.accordion__toggle {
  gap: var(--spacing-2);
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  padding-block-start: var(--accordion-spacing);
  padding-block-end: var(--accordion-spacing);
  display: flex;
}

.accordion__content {
  margin-block-end: var(--accordion-spacing);
  padding-inline-end: var(--spacing-6);
  transform: translateY(-4px);
}

.accordion--lg {
  --accordion-spacing: var(--spacing-8);
}

.accordion + .accordion {
  border-block-start-width: 0;
}

.accordion-box {
  padding: var(--spacing-1) var(--spacing-6);
}

.accordion-box > :first-child {
  border-block-start-width: 0;
}

.accordion-box > :last-child {
  border-block-end-width: 0;
}

@media screen and (min-width: 1000px) {
  .accordion-box {
    padding: var(--spacing-5) var(--spacing-12);
  }
}

.blog-post-card {
  --blog-post-card-badge-spacing: var(--spacing-3);
  --blog-post-card-figure-gap: var(--spacing-5);
  --blog-post-card-meta-gap: var(--spacing-1) var(--spacing-5);
  gap: var(--blog-post-card-figure-gap);
  align-content: start;
  display: grid;
  overflow: hidden;
}

.blog-post-card__figure {
  display: block;
  position: relative;
  overflow: hidden;
}

.blog-post-card__figure > .badge {
  z-index: 1;
  position: absolute;
  top: var(--blog-post-card-badge-spacing);
}

.blog-post-card__figure > .badge:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: var(--blog-post-card-badge-spacing);
}

.blog-post-card__figure > .badge:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: var(--blog-post-card-badge-spacing);
}

.blog-post-card__meta {
  gap: var(--blog-post-card-meta-gap);
  flex-wrap: wrap;
  display: flex;
}

.blog-post-card--featured {
  grid-column: 1 / -1;
  gap: 0;
}

.blog-post-card--featured .blog-post-card__info {
  padding: var(--spacing-5);
}

@media screen and (min-width: 700px) {
  .blog-post-card {
    --blog-post-card-badge-spacing: var(--spacing-4);
    --blog-post-card-figure-gap: var(--spacing-6);
    --blog-post-card-meta-gap: var(--spacing-2) var(--spacing-6);
  }

  .blog-post-card--featured .blog-post-card__info {
    padding: var(--spacing-12);
  }
}

@media screen and (min-width: 1000px) {
  .blog-post-card--featured {
    grid-template-columns: 1fr .7fr;
    display: grid;
  }
}

@media screen and (min-width: 1150px) {
  .blog-post-card--featured {
    grid-template-columns: 1fr .54fr;
  }

  .blog-post-card--featured .blog-post-card__info {
    padding: var(--spacing-16);
  }
}

.blog-posts {
  --blog-posts-gap: var(--spacing-8) var(--grid-gutter);
  grid: var(--blog-posts-grid);
  gap: var(--blog-posts-gap);
  display: grid;
}

@media screen and (min-width: 700px) {
  .blog-posts {
    --blog-posts-gap: var(--spacing-12) var(--grid-gutter);
  }
}

@media screen and (min-width: 1000px) {
  .blog-posts {
    --blog-posts-gap: var(--spacing-12);
  }
}

@media screen and (min-width: 1400px) {
  .blog-posts {
    --blog-posts-gap: min(var(--section-inner-spacing-inline), var(--spacing-20));
  }
}

.button, .btn {
  --button-background: var(--button-background-primary) / var(--button-background-opacity, 1);
  --button-text-color: var(--button-text-primary);
  --button-outline-color: var(--button-background-primary);
  -webkit-appearance: none;
  appearance: none;
  border-radius: var(--rounded-button);
  background-color: rgb(var(--button-background));
  color: rgb(var(--button-text-color));
  text-align: center;
  font-size: var(--text-sm);
  letter-spacing: var(--text-letter-spacing);
  padding-block-start: var(--spacing-2-5);
  padding-block-end: var(--spacing-2-5);
  padding-inline-start: var(--spacing-5);
  padding-inline-end: var(--spacing-5);
  font-weight: bold;
  line-height: 1.6;
  transition: background-color .15s ease-in-out, color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
  position: relative;
}

.button--sm {
  font-size: var(--text-xs);
  padding-block-start: var(--spacing-2);
  padding-block-end: var(--spacing-2);
  line-height: 1.7;
}

.button--lg {
  font-size: var(--text-base);
  padding-block-start: .8125rem;
  padding-block-end: .8125rem;
  padding-inline-start: var(--spacing-6);
  padding-inline-end: var(--spacing-6);
}

.button--xl {
  font-size: var(--text-base);
  padding-block-start: var(--spacing-4);
  padding-block-end: var(--spacing-4);
  padding-inline-start: var(--spacing-8);
  padding-inline-end: var(--spacing-8);
}

.button--secondary {
  --button-background: var(--button-background-secondary) / var(--button-background-opacity, 1);
  --button-text-color: var(--button-text-secondary);
  --button-outline-color: var(--button-background-secondary);
}

.button--subdued {
  --button-background: var(--text-color) / .1 !important;
  --button-text-color: var(--text-color) !important;
  --button-outline-color: var(--text-color) !important;
}

.button--outline {
  color: rgb(var(--button-outline-color) / var(--button-background-opacity, 1));
  background: none;
  box-shadow: inset 0 0 0 2px;
}

.button__loader, .button__feedback {
  gap: var(--spacing-1-5);
  opacity: 0;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.button__loader > * {
  width: var(--spacing-1-5);
  height: var(--spacing-1-5);
  border-radius: var(--rounded-full);
  background: currentColor;
}

@media screen and (min-width: 700px) {
  .button, .btn {
    padding-block-start: var(--spacing-3);
    padding-block-end: var(--spacing-3);
    padding-inline-start: var(--spacing-6);
    padding-inline-end: var(--spacing-6);
  }

  .button--sm {
    padding-block-start: var(--spacing-2);
    padding-block-end: var(--spacing-2);
    padding-inline-start: var(--spacing-5);
    padding-inline-end: var(--spacing-5);
  }

  .button--lg {
    padding-block-start: var(--spacing-3-5);
    padding-block-end: var(--spacing-3-5);
    padding-inline-start: var(--spacing-8);
    padding-inline-end: var(--spacing-8);
  }

  .button--xl {
    padding-block-start: 1.075rem;
    padding-block-end: 1.075rem;
    padding-inline-start: var(--spacing-10);
    padding-inline-end: var(--spacing-10);
  }
}

.back-button {
  align-items: center;
  gap: var(--spacing-4);
  padding: var(--spacing-2) var(--spacing-4);
  border-radius: var(--rounded-button);
  opacity: .7;
  border-width: 1px;
  transition: all .2s ease-in-out;
  display: flex;
}

@media screen and (pointer: fine) {
  .back-button:hover {
    opacity: 1;
  }
}

@media screen and (min-width: 700px) {
  .back-button {
    padding: var(--spacing-3) var(--spacing-6);
  }
}

.circle-button {
  width: var(--spacing-12);
  height: var(--spacing-12);
  border-radius: var(--rounded-full);
  place-items: center;
  transition: opacity .15s ease-in;
  display: grid;
}

.circle-button[disabled] {
  opacity: .5;
  pointer-events: none;
}

.circle-button--fill {
  background: rgb(var(--background-primary));
  color: rgb(var(--text-primary));
}

.circle-button--bordered {
  border: 2px solid;
}

.circle-button--sm {
  width: var(--spacing-10);
  height: var(--spacing-10);
}

.circle-button--lg {
  width: var(--spacing-14);
  height: var(--spacing-14);
}

.animated-arrow {
  pointer-events: none;
  place-items: center;
  display: grid;
}

.animated-arrow:before, .animated-arrow:after {
  content: "";
  grid-area: 1 / -1;
  transition: all .2s ease-in-out;
}

.animated-arrow:before {
  width: .5rem;
  height: .5rem;
  transform: rotate(calc(var(--transform-logical-flip) * 45deg));
  border-color: currentColor;
  border-block-start-width: 2px;
  border-inline-end-width: 2px;
  position: relative;
}

.animated-arrow:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  left: -1px;
}

.animated-arrow:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  right: -1px;
}

.animated-arrow:after {
  width: var(--spacing-2-5);
  height: var(--spacing-0-5);
  transform-origin: center;
  opacity: 0;
  background: currentColor;
  transform: scaleX(.5);
}

@media screen and (pointer: fine) {
  .group:hover .animated-arrow:before {
    transform: translateX(calc(var(--transform-logical-flip) * var(--spacing-0-5))) rotate(calc(var(--transform-logical-flip) * 45deg));
  }

  .group:hover .animated-arrow:after {
    opacity: 1;
    transform: scaleX(1);
  }
}

.animated-arrow--reverse {
  transform: rotate(180deg);
}

.circle-chevron {
  width: var(--spacing-6);
  height: var(--spacing-6);
  border-radius: var(--rounded-full);
  background: rgb(var(--text-color) / .1);
  flex-shrink: 0;
  place-items: center;
  transition: all .2s ease-in-out;
  display: grid;
}

.circle-chevron[disabled] {
  opacity: .5;
}

.group[aria-expanded="true"] .circle-chevron.group-expanded\:colors:not([disabled]) {
  background: rgb(var(--text-color));
  color: rgb(var(--background));
}

.group[aria-expanded="true"] .circle-chevron.group-expanded\:rotate {
  transform: rotate(calc(var(--transform-logical-flip) * 180deg));
}

@media screen and (pointer: fine) {
  .group:hover .circle-chevron.group-hover\:colors:not([disabled]), .circle-chevron.hover\:colors:hover:not([disabled]) {
    background: rgb(var(--text-color));
    color: rgb(var(--background));
  }
}

scroll-carousel {
  position: relative;
}

.content-over-media {
  grid-template: 0 minmax(0, 1fr) 0 / minmax(0, 1fr) minmax(0, min(var(--container-max-width), 100% - var(--content-over-media-gap, var(--content-over-media-column-gap, var(--container-gutter))) * 2)) minmax(0, 1fr);
  gap: var(--content-over-media-row-gap, var(--content-over-media-gap, var(--container-gutter))) var(--content-over-media-column-gap, var(--content-over-media-gap, var(--container-gutter)));
  place-items: center;
  display: grid;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
}

.content-over-media:before {
  content: "";
  background: rgb(var(--content-over-media-overlay));
  border-radius: inherit;
  z-index: 1;
  pointer-events: none;
  transition: background .2s ease-in-out;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.content-over-media > :is(img, video, iframe, svg, video-media) {
  height: var(--content-over-media-height, auto);
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  overflow-wrap: anywhere;
  object-fit: cover;
  object-position: center;
  transform-origin: bottom;
  border-radius: inherit;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  grid-area: 1 / 1 / span 3 / span 3;
  position: relative;
}

.content-over-media > :is(img, video, iframe, svg, video-media) > * {
  border-radius: inherit;
}

.content-over-media > :not(img, video, iframe, svg, video-media) {
  max-width: var(--content-over-media-content-max-width, 780px);
  z-index: 1;
  grid-area: 2 / 2 / auto / span 1;
  position: relative;
}

[allow-transparent-header] :is(.content-over-media > :not(img, video, iframe, svg, video-media)) {
  padding-block-start: calc(var(--header-height) * var(--section-is-first));
}

.content-over-media > video-media:not([autoplay]) ~ * {
  transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
}

.content-over-media > video-media:not([autoplay])[loaded] ~ * {
  opacity: 0;
  visibility: hidden;
}

.content-over-media:has( > video-media[loaded]:not([autoplay])):before {
  background: none;
  transition-delay: .1s;
}

.content-over-media--sm {
  --content-over-media-height: 375px;
}

.content-over-media--md {
  --content-over-media-height: 480px;
}

.content-over-media--lg {
  --content-over-media-height: 560px;
}

.content-over-media--fill {
  --content-over-media-height: calc(100vh - var(--sticky-area-height));
}

.shopify-section:first-child [allow-transparent-header] .content-over-media--fill {
  --content-over-media-height: calc(100vh - var(--announcement-bar-height, 0px));
}

@supports (height: 100svh) {
  .content-over-media--fill {
    --content-over-media-height: calc(100svh - var(--sticky-area-height));
  }

  .shopify-section:first-child [allow-transparent-header] .content-over-media--fill {
    --content-over-media-height: calc(100svh - var(--announcement-bar-height, 0px));
  }
}

@media screen and (min-width: 700px) {
  .content-over-media--sm {
    --content-over-media-height: 400px;
  }

  .content-over-media--md {
    --content-over-media-height: 460px;
  }

  .content-over-media--lg {
    --content-over-media-height: 560px;
  }
}

@media screen and (min-width: 1400px) {
  .content-over-media--sm {
    --content-over-media-height: 440px;
  }

  .content-over-media--md {
    --content-over-media-height: 500px;
  }

  .content-over-media--lg {
    --content-over-media-height: 640px;
  }

  .content-over-media--fill > :is(img, video, iframe, svg, video-media) {
    max-height: 1200px;
  }
}

@media screen and (min-width: 1600px) {
  .content-over-media--md {
    --content-over-media-height: 560px;
  }

  .content-over-media--lg {
    --content-over-media-height: 720px;
  }
}

.page-dots {
  justify-content: center;
  gap: var(--spacing-2-5) var(--spacing-4);
  flex-wrap: wrap;
  display: flex;
}

.page-dots > * {
  width: var(--spacing-1-5);
  height: var(--spacing-1-5);
  border-radius: var(--rounded-full);
  opacity: .3;
  background: currentColor;
  transition: opacity .2s ease-in-out;
}

.page-dots > *[aria-current="true"] {
  opacity: 1;
}

.page-dots--blurred {
  padding: var(--spacing-2) var(--spacing-4);
  border-radius: var(--rounded-full);
  background: rgb(var(--background) / .7);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}

.stretching-dots {
  align-items: center;
  gap: var(--spacing-5);
  display: flex;
}

.stretching-dots > * {
  width: var(--spacing-2);
  height: var(--spacing-2);
  border-radius: var(--spacing-2);
  opacity: .5;
  background: currentColor;
  transition: opacity .5s cubic-bezier(.34, 1.56, .64, 1), width .5s cubic-bezier(.34, 1.56, .64, 1);
}

.stretching-dots > *[aria-current="true"] {
  width: var(--spacing-6);
  opacity: 1;
}

.numbered-dots {
  align-items: center;
  gap: var(--spacing-2);
  display: flex;
}

.numbered-dots__item {
  width: var(--spacing-8);
  height: var(--spacing-8);
  will-change: transform;
  border-radius: 100%;
  place-items: center;
  font-weight: bold;
  transition: color .2s;
  display: grid;
}

.numbered-dots__item > * {
  grid-area: 1 / -1;
}

.numbered-dots__item svg {
  width: inherit;
  height: inherit;
  transform: rotate(-90deg);
}

.numbered-dots__item circle {
  fill: none;
  stroke: currentColor;
  stroke-linecap: butt;
  stroke-opacity: .3;
  stroke-width: 2px;
}

.numbered-dots__item[aria-current="true"] circle:last-child {
  --radius: 15;
  --circumference: calc(2px * (22 / 7) * var(--radius));
  stroke-opacity: 1;
  stroke-dasharray: calc(var(--circumference) * var(--progress, 1)), var(--circumference);
}

.prev-next-buttons {
  align-items: center;
  gap: var(--spacing-4);
  display: flex;
}

:is(.drawer, .popover):not(:defined) {
  display: none;
}

:is(.drawer, .popover)::part(outside-close-button), :is(.drawer, .popover)::part(close-button) {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  border: none;
  padding: 0;
}

:is(.drawer, .popover)::part(outside-close-button) {
  width: var(--spacing-12);
  height: var(--spacing-12);
  bottom: 100%;
  left: calc(50% - var(--spacing-6));
  background: rgb(var(--dialog-background));
  color: rgb(var(--text-color));
  border-radius: var(--rounded-full);
  z-index: 1;
  place-items: center;
  margin-block-end: var(--spacing-4);
  margin-inline-start: auto;
  margin-inline-end: auto;
  display: grid;
  position: absolute;
}

:is(.drawer, .popover)::part(close-button) {
  color: currentColor;
  background: none;
  margin-inline-start: auto;
  display: none;
}

@media screen and (min-width: 700px) {
  :is(.drawer, .popover)::part(outside-close-button) {
    display: none;
  }

  :is(.drawer, .popover)::part(close-button) {
    display: grid;
  }
}

.popover {
  --background: var(--dialog-background);
  --text-color: var(--text-primary);
  --popover-title-padding: var(--spacing-5);
  --popover-body-padding: var(--spacing-4) 0;
  --popover-content-max-height: 75vh;
  --popover-content-max-width: none;
  --popover-anchor-block-spacing: var(--spacing-4);
  --popover-anchor-inline-spacing: 0;
  width: 100%;
  visibility: hidden;
  padding: var(--spacing-2);
  z-index: 999;
  display: none;
  position: fixed;
}

.popover::part(overlay) {
  min-height: 100lvh;
  cursor: var(--cursor-close-svg-url) 28 28, auto;
  background: #0006;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.popover::part(title) {
  padding: var(--popover-title-padding);
  border-block-end: 1px solid rgb(var(--border-color));
  text-align: center;
}

.popover::part(content) {
  height: 100%;
  max-height: var(--popover-content-max-height);
  max-width: var(--popover-content-max-width);
  background: rgb(var(--dialog-background));
  color: rgb(var(--text-color));
  border-radius: 8px;
  flex-direction: column;
  display: flex;
  position: relative;
}

.popover::part(body) {
  overscroll-behavior-y: contain;
  padding: var(--popover-body-padding);
  text-align: center;
  overflow-y: auto;
}

@media screen and (min-width: 1000px) {
  .popover {
    --popover-content-max-height: 350px;
    --popover-content-max-width: 380px;
    width: auto;
    z-index: 10;
    padding: 0;
    position: absolute;
  }

  .popover::part(content) {
    width: max-content;
    border-radius: var(--rounded-input);
    box-shadow: var(--shadow-md);
    border: 1px solid rgb(var(--border-color));
  }

  .popover::part(title), .popover::part(overlay) {
    display: none;
  }

  .popover::part(body) {
    text-align: start;
  }
}

.popover-listbox__option {
  justify-content: center;
  align-items: center;
  gap: var(--spacing-2);
  width: 100%;
  padding: var(--spacing-2) var(--spacing-6);
  color: rgb(var(--text-color) / .7);
  transition: color .2s ease-in-out;
  display: flex;
}

.popover-listbox__option.is-disabled {
  text-decoration: line-through;
}

.popover-listbox__option[aria-selected="true"], .popover-listbox__option:hover {
  color: rgb(var(--text-color));
}

@media screen and (min-width: 1000px) {
  .popover-listbox__option {
    justify-content: start;
    padding-block-start: var(--spacing-1-5);
    padding-block-end: var(--spacing-1-5);
    padding-inline-end: var(--spacing-10);
  }

  .popover-listbox--sm .popover-listbox__option {
    line-height: 1.7;
    font-size: var(--text-sm);
    padding-block-start: var(--spacing-1);
    padding-block-end: var(--spacing-1);
  }
}

.drawer {
  --container-outer-width: var(--spacing-6);
  --background: var(--dialog-background);
  --text-color: var(--text-primary);
  --drawer-header-padding: var(--spacing-5);
  --drawer-body-padding: var(--spacing-5) var(--spacing-6);
  --drawer-footer-padding: var(--spacing-6);
  --drawer-content-max-height: none;
  width: 100%;
  visibility: hidden;
  padding: var(--spacing-2);
  z-index: 999;
  position: fixed;
  right: 0;
}

.drawer::part(overlay) {
  min-height: 100lvh;
  background: #0006;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.drawer.show-close-cursor::part(overlay) {
  cursor: var(--cursor-close-svg-url) 28 28, auto;
}

.drawer::part(content) {
  height: 100%;
  max-height: var(--drawer-content-max-height);
  border-radius: var(--rounded-sm);
  background: rgb(var(--dialog-background));
  color: rgb(var(--text-color));
  flex-direction: column;
  display: flex;
  position: relative;
}

.drawer::part(header) {
  padding: var(--drawer-header-padding);
}

.drawer::part(body) {
  padding: var(--drawer-body-padding);
  overscroll-behavior-y: contain;
  flex: auto;
  align-items: start;
  display: grid;
  overflow-y: auto;
}

.drawer::part(footer) {
  padding: var(--drawer-footer-padding);
  border-block-start: 1px solid rgb(var(--border-color));
  margin-block-start: auto;
  position: relative;
}

.drawer > [is="close-button"] {
  display: none;
  position: absolute;
  top: 1.5rem;
}

.drawer > [is="close-button"]:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: 1.5rem;
}

.drawer > [is="close-button"]:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: 1.5rem;
}

@media screen and (max-width: 699px) {
  .drawer {
    --drawer-content-max-height: 75vh;
  }

  .drawer::part(header) {
    text-align: center;
    border-bottom: 1px solid rgb(var(--border-color));
  }
}

@media screen and (min-width: 700px) {
  .drawer {
    --container-outer-width: var(--spacing-10);
    --drawer-header-padding: var(--spacing-8) var(--spacing-10);
    --drawer-body-padding: var(--spacing-8) var(--spacing-10);
    --drawer-footer-padding: var(--spacing-8) var(--spacing-10);
    height: 100%;
    width: 510px;
    padding: var(--spacing-4);
  }

  .drawer::part(header) {
    align-items: center;
    gap: var(--spacing-4);
    display: flex;
    position: relative;
  }

  .drawer[header-bordered]::part(header) {
    border-bottom: 1px solid rgb(var(--border-color));
  }

  .drawer:not([header-bordered])::part(header) {
    padding-block-end: 0;
  }

  .drawer:not([header-bordered])::part(header):after {
    content: "";
    width: 100%;
    height: var(--spacing-8);
    background: linear-gradient(to bottom, rgb(var(--dialog-background)), rgb(var(--dialog-background) / .6) 50%, rgb(var(--dialog-background) / 0));
    z-index: 1;
    position: absolute;
    top: 100%;
    left: 0;
  }

  .drawer > [is="close-button"] {
    display: grid;
    top: 2.25rem;
  }

  .drawer > [is="close-button"]:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    right: 2.25rem;
  }

  .drawer > [is="close-button"]:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    left: 2.25rem;
  }

  .drawer--lg {
    width: 680px;
  }
}

.banner {
  align-items: start;
  gap: var(--spacing-3-5);
  padding: var(--spacing-3-5);
  border-radius: var(--rounded-xs);
  font-size: var(--text-sm);
  color: rgb(var(--banner-color));
  background: rgb(var(--banner-background));
  grid-template-columns: max-content auto;
  display: grid;
}

.banner--error {
  --banner-background: var(--error-background);
  --banner-color: var(--error-text);
}

.banner--success {
  --banner-background: var(--success-background);
  --banner-color: var(--success-text);
}

.banner--warning {
  --banner-background: var(--warning-background);
  --banner-color: var(--warning-text);
}

.banner--with-icon > .button {
  grid-column-start: 2;
  justify-self: start;
}

@media screen and (min-width: 700px) {
  .banner--with-icon {
    grid-template-columns: max-content auto max-content;
    align-items: center;
  }

  .banner--with-icon svg {
    --icon-offset: 0;
  }

  .banner--with-icon > .button {
    grid-column-start: 3;
  }
}

.badge {
  --badge-background: var(--text-color) / .05;
  --badge-color: var(--text-color);
  align-items: center;
  gap: var(--spacing-1-5);
  padding: 3px var(--spacing-2);
  width: max-content;
  background: rgb(var(--badge-background));
  color: rgb(var(--badge-color));
  border-radius: var(--rounded-button);
  font-size: 9px;
  font-weight: bold;
  display: flex;
}

.badge--primary {
  --badge-background: var(--primary-badge-background);
  --badge-color: var(--primary-badge-text);
}

.badge--sold-out {
  --badge-background: var(--sold-out-badge-background);
  --badge-color: var(--sold-out-badge-text);
}

.badge--on-sale {
  --badge-background: var(--on-sale-badge-background);
  --badge-color: var(--on-sale-badge-text);
}

.badge--current {
  --badge-background: var(--text-color) / .12;
  --badge-color: var(--text-color);
}

.badge--lg {
  padding: var(--spacing-1) var(--spacing-3);
  font-size: var(--text-sm);
}

@media screen and (min-width: 700px) {
  .badge:not(.badge--lg) {
    font-size: var(--text-xs);
    padding-block-start: var(--spacing-0-5);
    padding-block-end: var(--spacing-0-5);
  }
}

.progress-bar {
  height: var(--spacing-1);
  border-radius: var(--rounded-full);
  background: rgb(var(--text-color) / .3);
  overflow: hidden;
}

.progress-bar:before {
  content: "";
  height: inherit;
  background: rgb(var(--accent));
  transform-origin: var(--transform-origin-start);
  transform: scaleX(var(--progress, 0));
  transition: transform .5s ease-in-out;
  display: block;
}

.scrollbar {
  align-items: center;
  gap: var(--spacing-10);
  color: rgb(var(--text-color));
  display: flex;
}

.scrollbar__progress {
  width: 100%;
  height: var(--spacing-0-5);
  background: rgb(var(--text-color) / .1);
  display: block;
  position: relative;
}

.scrollbar__progress:before {
  content: "";
  transform-origin: var(--transform-origin-start);
  transform: scaleX(var(--scroll-progress));
  background: rgb(var(--text-color));
  will-change: transform;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.scrollbar__buttons {
  display: none;
}

@media screen and (min-width: 1000px) {
  .scrollbar__buttons {
    gap: var(--spacing-4);
    display: flex;
  }
}

.count-bubble {
  --size: var(--spacing-4);
  width: var(--size);
  height: var(--size);
  min-width: min-content;
  border-radius: var(--rounded-full);
  background: rgb(var(--button-background-primary));
  color: rgb(var(--button-text-primary));
  place-items: center;
  padding-inline-start: var(--spacing-1);
  padding-inline-end: var(--spacing-1);
  font-size: 9px;
  font-weight: bold;
  line-height: 1;
  display: grid;
}

.count-bubble--md, .count-bubble--lg {
  --size: var(--spacing-5);
  font-size: var(--text-xs);
}

@media screen and (min-width: 700px) {
  .count-bubble--md {
    --size: var(--spacing-6);
  }

  .count-bubble--lg {
    --size: var(--spacing-7);
  }
}

.text-with-bubble {
  position: relative;
}

.text-with-bubble > .count-bubble {
  margin-inline-start: var(--spacing-2);
  position: absolute;
  top: 0;
}

.text-with-bubble > .count-bubble:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: 100%;
}

.text-with-bubble > .count-bubble:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: 100%;
}

.pill-loader {
  width: 2rem;
  height: 1rem;
  background: rgb(var(--background-primary));
  border-radius: var(--rounded-full);
  visibility: hidden;
  display: grid;
}

.pill-loader > * {
  grid-area: 1 / -1;
  place-self: center;
}

.loader-dots {
  justify-items: center;
  gap: .25rem;
  display: flex;
}

.loader-dots > * {
  width: .25rem;
  height: .25rem;
  border-radius: var(--rounded-full);
  background: currentColor;
}

.loader-checkmark {
  opacity: 0;
}

.form {
  align-content: start;
  gap: var(--spacing-4);
  display: grid;
}

.fieldset, .input-row {
  gap: var(--input-gap);
  display: grid;
}

.fieldset-link {
  margin-block-start: var(--spacing-2);
  margin-block-end: var(--spacing-2);
}

.fieldset-with-submit {
  align-items: start;
  gap: var(--spacing-4);
  display: grid;
}

@media screen and (min-width: 700px) {
  .form:not(.form--tight) {
    gap: var(--spacing-6);
  }

  .input-row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .fieldset-link {
    margin-block-start: 0;
    margin-block-end: var(--spacing-2);
  }

  .fieldset-with-submit {
    grid-template-columns: minmax(0, 1fr) max-content;
  }
}

.input, .textarea, .select {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  height: var(--input-height);
  border-radius: var(--rounded-input);
  background: rgb(var(--input-background, transparent));
  color: rgb(var(--input-text-color, var(--text-color)));
  text-align: start;
  border-width: 1px;
  padding-inline-start: var(--input-padding-inline);
  padding-inline-end: var(--input-padding-inline);
}

.input:focus, .textarea:focus, .select:focus-visible {
  border-color: currentColor;
  outline: none;
  box-shadow: inset 0 0 0 1px;
}

.input.is-floating, .select.is-floating {
  height: calc(var(--input-height)  + .625rem);
  padding-block-start: var(--spacing-4);
}

.textarea {
  height: auto;
  vertical-align: top;
  padding-block-start: var(--spacing-4);
  padding-block-end: var(--spacing-4);
}

.textarea.is-floating {
  padding-block-start: var(--spacing-6);
  padding-block-end: var(--spacing-2);
}

.select {
  gap: var(--spacing-4);
  justify-content: space-between;
  align-items: center;
  padding-inline-end: calc(var(--input-padding-inline) * 2);
  display: flex;
}

.select-chevron {
  position: absolute;
  top: calc(50% - 3.5px);
}

.select-chevron:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: var(--input-padding-inline);
}

.select-chevron:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: var(--input-padding-inline);
}

.form-control {
  text-align: start;
  position: relative;
}

.block-label {
  width: -moz-fit-content;
  width: fit-content;
  margin-block-end: var(--spacing-2);
  display: block;
}

.floating-label {
  height: calc(var(--input-height)  + .625rem);
  top: 0;
  opacity: .5;
  pointer-events: none;
  transform-origin: var(--transform-origin-start);
  grid-auto-flow: column;
  align-items: center;
  column-gap: .5rem;
  margin-inline-start: var(--spacing-4);
  transition: transform .2s ease-in-out;
  display: grid;
  position: absolute;
}

.floating-label:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: 1px;
}

.floating-label:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: 1px;
}

:is(.input, .textarea):focus ~ .floating-label, :is(.input, .textarea):not(:placeholder-shown) ~ .floating-label, :is(.input, .textarea, .select):-webkit-autofill ~ .floating-label, .select:valid ~ .floating-label {
  transform: scale(.65) translateY(calc(-1 * var(--spacing-4)));
}

:is(.input, .textarea, .select):-webkit-autofill ~ .floating-label {
  color: #000;
}

.self-submit-button {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}

.self-submit-button:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: var(--spacing-4);
}

.self-submit-button:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: var(--spacing-4);
}

@media screen and (min-width: 700px) {
  .floating-label {
    margin-inline-start: var(--spacing-5);
  }

  .self-submit-button:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    right: var(--spacing-5);
  }

  .self-submit-button:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    left: var(--spacing-5);
  }
}

.checkbox-container {
  align-items: baseline;
  display: flex;
}

.checkbox {
  -webkit-appearance: none;
  appearance: none;
  width: var(--spacing-4);
  height: var(--spacing-4);
  background: rgb(var(--text-color) / .15) var(--checkmark-svg-url) no-repeat center;
  background-size: 0;
  border-radius: 2px;
  flex-shrink: 0;
  transition: background-color .2s ease-in-out;
  position: relative;
  top: .1875rem;
  box-shadow: inset 1px 1px 2px #0000001a;
}

.checkbox:checked {
  background-color: rgb(var(--accent));
  background-size: 10px 8px;
}

.checkbox:checked ~ label {
  opacity: 1;
  transition: all .2s ease-in-out;
}

.checkbox ~ label {
  opacity: .7;
  padding-inline-start: var(--spacing-3);
}

.checkbox:disabled {
  opacity: .5;
}

.checkbox:disabled ~ label {
  color: rgb(var(--text-color) / .5);
}

.fieldset > .checkbox-container {
  margin-block-start: var(--spacing-1);
}

.checkbox-list {
  gap: var(--spacing-2);
  display: grid;
}

@media screen and (min-width: 700px) {
  .checkbox {
    top: var(--spacing-0-5);
  }
}

.switch {
  -webkit-appearance: none;
  appearance: none;
  height: var(--spacing-5);
  width: var(--spacing-10);
  border-radius: var(--rounded-full);
  background: rgb(var(--text-color) / .15);
  padding: 3px;
  transition: background .2s ease-in-out;
  display: grid;
}

.switch:after {
  content: "";
  width: var(--spacing-3-5);
  height: var(--spacing-3-5);
  background: rgb(var(--background));
  border-radius: var(--rounded-full);
  transition: transform .2s ease-in-out;
  box-shadow: 0 1px 1px #0003;
}

.switch:checked {
  background: rgb(var(--accent));
}

.switch:checked:after {
  transform: translateX(calc(var(--transform-logical-flip) * var(--spacing-5)));
}

.horizontal-product {
  --horizontal-product-image-size: var(--spacing-16);
  align-items: flex-start;
  gap: var(--spacing-4);
  padding: var(--spacing-4);
  min-width: 0;
  display: flex;
}

.horizontal-product__image {
  width: var(--horizontal-product-image-size);
  flex: 0 0 var(--horizontal-product-image-size);
}

.horizontal-product__info {
  gap: var(--spacing-2);
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  display: grid;
}

@media screen and (min-width: 700px) {
  .horizontal-product {
    --horizontal-product-image-size: var(--spacing-20);
    gap: var(--spacing-5);
    align-items: center;
  }

  .horizontal-product--sm {
    --horizontal-product-image-size: var(--spacing-16);
  }

  .horizontal-product__info {
    gap: var(--spacing-4);
    display: flex;
  }

  .horizontal-product__cta {
    flex-shrink: 0;
    margin-inline-end: var(--spacing-4);
  }
}

.horizontal-product-list.separate {
  gap: var(--spacing-2);
  display: grid;
}

.horizontal-product-list-carousel > .horizontal-product-list {
  grid: auto / auto-flow 73vw;
  display: grid;
}

.horizontal-product-list-carousel > .horizontal-product-list:not(.separate) {
  border-radius: var(--rounded-xs);
  border-width: 1px;
}

@media screen and (min-width: 700px) {
  .horizontal-product-list-carousel {
    border-radius: var(--rounded-xs);
    grid-template-columns: minmax(0, 1fr);
  }

  .horizontal-product-list-carousel > .horizontal-product-list {
    grid: auto / auto-flow var(--horizontal-product-width, 100%);
  }
}

.v-stack {
  display: grid;
}

.h-stack {
  align-items: center;
  display: flex;
}

.container {
  --container-inner-width: min((100vw - var(--scrollbar-width, 0px))  - var(--container-gutter) * 2, var(--container-max-width));
  --container-outer-width: calc(((100vw - var(--scrollbar-width, 0px))  - var(--container-inner-width)) / 2);
  margin-inline-start: max(var(--container-gutter), 50% - var(--container-max-width) / 2);
  margin-inline-end: max(var(--container-gutter), 50% - var(--container-max-width) / 2);
}

.container--narrow {
  --container-max-width: var(--container-narrow-max-width);
}

@media screen and (min-width: 700px) {
  .sm\:container {
    --container-inner-width: min((100vw - var(--scrollbar-width, 0px))  - var(--container-gutter) * 2, var(--container-max-width));
    --container-outer-width: calc(((100vw - var(--scrollbar-width, 0px))  - var(--container-inner-width)) / 2);
    margin-inline-start: max(var(--container-gutter), 50% - var(--container-max-width) / 2);
    margin-inline-end: max(var(--container-gutter), 50% - var(--container-max-width) / 2);
  }
}

.bleed {
  scroll-padding-inline: var(--container-outer-width);
  margin-inline-start: calc(-1 * var(--container-outer-width));
  margin-inline-end: calc(-1 * var(--container-outer-width));
  padding-inline-start: var(--container-outer-width);
  padding-inline-end: var(--container-outer-width);
  display: grid;
}

.full-bleed {
  margin-inline-start: calc(-1 * var(--container-outer-width));
  margin-inline-end: calc(-1 * var(--container-outer-width));
  display: grid;
}

@media screen and (min-width: 700px) {
  .sm\:unbleed {
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
    scroll-padding-inline: 0;
  }
}

@media screen and (min-width: 1000px) {
  .md\:unbleed {
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
    scroll-padding-inline: 0;
  }
}

@media screen and (min-width: 1150px) {
  .lg\:unbleed {
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
    scroll-padding-inline: 0;
  }
}

[role="main"] .shopify-section {
  --section-is-first: 0;
  --hash-difference: calc(var(--section-background-hash)  - var(--previous-section-background-hash, -1));
  --hash-difference-abs: max(var(--hash-difference), -1 * var(--hash-difference));
  --background-differs-from-previous: max(0, min(var(--hash-difference-abs, 1), 1));
  background: rgb(var(--background));
  position: relative;
}

[role="main"] .shopify-section:not(.contents) {
  display: flow-root;
}

[role="main"] .shopify-section:empty {
  display: none;
}

[role="main"] .shopify-section:first-child {
  --section-is-first: 1;
}

.section {
  --context-section-spacing-block-start: var(--section-outer-spacing-block);
  --context-section-spacing-block-end: var(--section-outer-spacing-block);
  --context-section-spacing-inline: var(--container-gutter);
  --calculated-section-spacing-block-start: var(--section-spacing-block-start, var(--section-spacing-block, var(--context-section-spacing-block-start)));
  --calculated-section-spacing-block-end: var(--section-spacing-block-end, var(--section-spacing-block, var(--context-section-spacing-block-end)));
  --calculated-section-spacing-inline: var(--section-spacing-inline, var(--context-section-spacing-inline));
  --container-inner-width: min((100vw - var(--scrollbar-width, 0px))  - var(--calculated-section-spacing-inline, 0px) * 2, var(--container-max-width));
  --container-outer-width: calc(((100vw - var(--scrollbar-width, 0px))  - var(--container-inner-width, 0px)) / 2);
  --section-stack-spacing-inline: var(--section-inner-spacing-inline, 0px);
  padding-inline-start: max(var(--calculated-section-spacing-inline), 50% - var(--container-max-width) / 2);
  padding-inline-end: max(var(--calculated-section-spacing-inline), 50% - var(--container-max-width) / 2);
}

.section--tight {
  --calculated-section-spacing-block-start: min(48px, var(--section-spacing-block-start, var(--section-spacing-block, var(--context-section-spacing-block-start))));
  --calculated-section-spacing-block-end: min(48px, var(--section-spacing-block-end, var(--section-spacing-block, var(--context-section-spacing-block-end))));
  --calculated-section-spacing-inline: min(48px, var(--section-spacing-inline, var(--context-section-spacing-inline)));
}

.section--narrow {
  --container-max-width: var(--container-narrow-max-width);
}

.section {
  padding-block-start: calc(var(--background-differs-from-previous) * var(--calculated-section-spacing-block-start));
  padding-block-end: var(--calculated-section-spacing-block-end);
}

@media screen and (min-width: 700px) {
  .section--tight {
    --calculated-section-spacing-block-start: min(48px, var(--section-spacing-block-start, var(--section-spacing-block, var(--context-section-spacing-block-start, 0px))));
    --calculated-section-spacing-block-end: min(48px, var(--section-spacing-block-end, var(--section-spacing-block, var(--context-section-spacing-block-end, 0px))));
  }

  .section-boxed {
    --context-section-spacing-block-start: min(var(--section-inner-max-spacing-block, var(--section-outer-spacing-block)));
    --context-section-spacing-block-end: min(var(--section-inner-max-spacing-block, var(--section-outer-spacing-block)));
    --context-section-spacing-inline: var(--section-inner-spacing-inline);
    --container-inner-width: calc(var(--container-max-width)  - var(--container-outer-width) * 2);
    --container-outer-width: var(--calculated-section-spacing-inline);
    border-radius: var(--rounded-lg);
    box-shadow: var(--shadow-block);
    margin-block-start: calc(var(--background-differs-from-previous) * var(--section-outer-spacing-block-start, var(--section-outer-spacing-block)));
    margin-block-end: var(--section-outer-spacin-block-end, var(--section-outer-spacing-block));
    margin-inline-start: max(var(--container-gutter), 50% - var(--container-max-width) / 2);
    margin-inline-end: max(var(--container-gutter), 50% - var(--container-max-width) / 2);
    padding-block-start: var(--calculated-section-spacing-block-start);
    padding-inline-start: var(--calculated-section-spacing-inline);
    padding-inline-end: var(--calculated-section-spacing-inline);
  }

  .shopify-section:first-child {
    --section-outer-spacing-block-start: 24px;
  }
}

@media screen and (min-width: 1600px) {
  .section--tight {
    --calculated-section-spacing-block-start: min(64px, var(--section-spacing-block-start, var(--section-spacing-block, var(--context-section-spacing-block-start, 0px))));
    --calculated-section-spacing-block-end: min(64px, var(--section-spacing-block-end, var(--section-spacing-block, var(--context-section-spacing-block-end, 0px))));
  }
}

.section-header {
  gap: var(--spacing-4);
  justify-items: start;
  display: grid;
}

@media screen and (min-width: 1150px) {
  .section-header {
    grid-template-columns: 700px;
    justify-content: space-between;
    align-items: end;
  }

  .section-header > .text-with-icon {
    grid-column-start: 2;
  }
}

.section-stack {
  gap: var(--section-stack-spacing-block, 0px) var(--section-stack-spacing-inline, 0px);
  grid-auto-columns: minmax(0, 1fr);
  display: grid;
}

@media screen and (min-width: 1150px) {
  .section-stack--horizontal {
    justify-content: center;
    display: flex;
  }

  .section-stack--center {
    justify-items: center;
  }

  .section-stack--reverse {
    flex-direction: row-reverse;
  }

  .section-stack__intro {
    width: var(--section-stack-intro, 50%);
  }

  .section-stack__main {
    width: var(--section-stack-main, 50%);
  }
}

.page-spacer {
  margin-block-start: var(--spacing-8);
  margin-block-end: var(--section-outer-spacing-block);
}

@media screen and (min-width: 700px) {
  .page-spacer {
    margin-block-start: var(--spacing-12);
  }
}

@media screen and (min-width: 1000px) {
  .page-spacer {
    margin-block-start: var(--spacing-16);
  }
}

.box {
  padding: var(--spacing-8);
}

@media screen and (min-width: 700px) {
  .box {
    padding: var(--spacing-12);
  }
}

.empty-state {
  justify-items: center;
  gap: var(--spacing-5);
  text-align: center;
  margin-block-start: var(--spacing-12);
  margin-block-end: var(--spacing-12);
  display: grid;
}

.empty-state__icon-wrapper {
  position: relative;
}

.empty-state__icon-wrapper > .count-bubble {
  position: absolute;
  top: calc(-1 * var(--spacing-1-5));
}

.empty-state__icon-wrapper > .count-bubble:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: calc(-1 * var(--spacing-1-5));
}

.empty-state__icon-wrapper > .count-bubble:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: calc(-1 * var(--spacing-1-5));
}

@media screen and (min-width: 700px) {
  .empty-state {
    gap: var(--spacing-8);
    margin-block-start: var(--spacing-32);
    margin-block-end: var(--spacing-32);
  }

  .empty-state__icon-wrapper > svg {
    width: var(--spacing-12);
    height: var(--spacing-12);
  }

  .empty-state__icon-wrapper > .count-bubble {
    top: calc(-1 * var(--spacing-3));
  }

  .empty-state__icon-wrapper > .count-bubble:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    right: calc(-1 * var(--spacing-3));
  }

  .empty-state__icon-wrapper > .count-bubble:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    left: calc(-1 * var(--spacing-3));
  }
}

.scrollable-with-controls {
  gap: var(--spacing-6);
  grid-auto-columns: minmax(0, 1fr);
  display: grid;
}

@media screen and (min-width: 700px) {
  .scrollable-with-controls {
    gap: var(--spacing-10);
  }
}

.line-item {
  --line-item-image-width: var(--spacing-20);
  align-items: start;
  gap: var(--spacing-5);
  display: flex;
}

.line-item__media-wrapper {
  width: var(--line-item-image-width);
  min-width: var(--line-item-image-width);
  position: relative;
}

.line-item__media-wrapper > .pill-loader {
  position: absolute;
  top: calc(50% - .5rem);
}

.line-item__media-wrapper > .pill-loader:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: calc(50% - 1rem);
}

.line-item__media-wrapper > .pill-loader:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: calc(50% - 1rem);
}

.line-item__info {
  justify-items: start;
  gap: var(--spacing-1);
  display: grid;
}

.line-item__info line-item-quantity {
  margin-block-start: var(--spacing-1);
}

@media screen and (min-width: 700px) {
  .line-item {
    --line-item-image-width: var(--spacing-24);
    align-items: center;
  }

  .line-item__actions {
    margin-inline-start: auto;
  }
}

video-media, model-media {
  border-radius: inherit;
  aspect-ratio: var(--aspect-ratio, var(--default-aspect-ratio));
  position: relative;
}

@supports not (aspect-ratio: 1) {
  :is(video-media, model-media):before {
    content: "";
    padding-block-end: calc(100% / (var(--aspect-ratio, var(--default-aspect-ratio))));
    display: block;
  }

  :is(video-media, model-media) > * {
    height: 100%;
    top: 0;
    left: 0;
    position: absolute !important;
  }
}

video-media {
  --default-aspect-ratio: 16 / 9;
}

video-media[host] {
  align-items: center;
  display: grid;
}

video-media[autoplay], video-media:not([autoplay]) ~ * {
  pointer-events: none;
}

video-media:not([playing]) {
  cursor: pointer;
}

video-media > :is(video, iframe, img, svg) {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
}

video-media > video[controls] {
  pointer-events: auto;
}

video-media > video:not(:-webkit-full-screen), video-media > img, video-media > svg {
  object-fit: cover;
  object-position: center;
}

video-media > video:not(:fullscreen), video-media > img, video-media > svg {
  object-fit: cover;
  object-position: center;
}

video-media > :is(iframe, img, svg) {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

video-media:not([loaded]) > :is(video, iframe), video-media[loaded] > img, video-media[loaded] > svg, video-media[loaded]::part(play-button) {
  opacity: 0;
  visibility: hidden;
}

video-media[suspended] ~ * {
  pointer-events: none;
}

video-media::part(play-button) {
  z-index: 1;
  will-change: transform;
  transition: transform .2s ease-in-out, opacity .2s ease-in-out, visibility .2s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(1);
}

video-media:hover::part(play-button) {
  transform: translate(-50%, -50%)scale(1.1);
}

@media screen and (min-width: 700px) {
  video-media::part(play-button) {
    width: 64px;
    height: 64px;
  }
}

model-media {
  --default-aspect-ratio: 1;
}

model-media model-viewer, model-media .shopify-model-viewer-ui {
  width: 100%;
  height: 100%;
}

.video-play-button {
  --play-button-shadow-size: 12px;
  filter: drop-shadow(0 16px 50px #0000000d);
  position: relative;
}

.video-play-button:before {
  content: "";
  box-sizing: content-box;
  top: calc(-1 * var(--play-button-shadow-size));
  left: calc(-1 * var(--play-button-shadow-size));
  width: 100%;
  height: 100%;
  padding: var(--play-button-shadow-size);
  background: radial-gradient(50% 50% at 50% 50%, rgb(var(--text-color) / 0) 0%, rgb(var(--text-color)) 100%);
  opacity: .3;
  border-radius: 100%;
  animation: 2s ease-in-out infinite alternate ping;
  position: absolute;
}

@media screen and (min-width: 700px) {
  .video-play-button {
    --play-button-shadow-size: 20px;
  }

  .video-play-button svg {
    width: 5rem;
    height: 5rem;
  }
}

.order-summary__header {
  display: none;
}

.order-summary__body td {
  border-top-width: 0;
  padding-block-start: 0;
  padding-block-end: var(--spacing-4);
}

.order-summary__body td:first-child {
  width: 60%;
}

@media screen and (min-width: 700px) {
  .order-summary__header {
    display: table-header-group;
  }

  .order-summary__body td {
    padding-block-end: var(--spacing-6);
  }

  .order-summary__body tr:first-child td {
    padding-block-start: var(--spacing-8);
  }

  .order-summary__body tr:last-child td {
    padding-block-end: var(--spacing-8);
  }
}

.quantity-input {
  width: 44px;
  border-radius: var(--rounded-input);
  text-align: center;
  font-size: var(--text-xs);
  -webkit-touch-callout: none;
  -webkit-appearance: none;
  background: none;
  border-width: 1px;
  outline: none;
  padding-block-start: var(--spacing-1);
  padding-block-end: var(--spacing-1);
}

@media screen and (min-width: 700px) {
  .quantity-input {
    width: 48px;
    padding-block-start: var(--spacing-2);
    padding-block-end: var(--spacing-2);
  }
}

.pagination {
  background: rgb(var(--background));
  border-radius: var(--rounded-button);
  border-width: 1px;
  justify-self: center;
  display: flex;
}

.pagination__item {
  place-content: center;
  padding-inline-start: var(--spacing-4);
  padding-inline-end: var(--spacing-4);
  display: grid;
}

.pagination__item--disabled {
  opacity: .5;
}

.pagination__current {
  padding: var(--spacing-2) var(--spacing-1);
}

@media screen and (min-width: 700px) {
  .pagination__item {
    padding-inline-start: var(--spacing-5);
    padding-inline-end: var(--spacing-5);
  }

  .pagination__current {
    padding: var(--spacing-3) var(--spacing-2);
  }
}

.range {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  background: none;
}

.range::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.range::-webkit-slider-thumb {
  height: 16px;
  width: 16px;
  background: rgb(var(--text-color));
  cursor: pointer;
  border: 2px solid rgb(var(--background));
  z-index: 1;
  box-shadow: none;
  border-radius: 100%;
  margin-block-start: -6px;
  position: relative;
}

.range::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: rgb(var(--text-color) / .12);
  border: none;
  border-radius: 2px;
}

.range::-moz-range-thumb {
  height: 12px;
  width: 12px;
  border: 2px solid rgb(var(--background));
  background: rgb(var(--text-color));
  cursor: pointer;
  border-radius: 100%;
}

.range::-moz-range-progress, .range::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  border: none;
  border-radius: 2px;
}

.range::-moz-range-progress {
  background-color: rgba(var(--text-color));
}

.range::-moz-range-track {
  background-color: rgb(var(--border-color));
}

@media not screen and (pointer: fine) {
  .range::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    margin-top: -9px;
  }

  .range::-moz-range-thumb {
    height: 16px;
    width: 16px;
  }
}

.range-group {
  height: 4px;
  background: linear-gradient(to var(--transform-origin-end), rgb(var(--text-color) / .12) var(--range-min), rgb(var(--text-color)) var(--range-min), rgb(var(--text-color)) var(--range-max), rgb(var(--text-color) / .12) var(--range-max));
  border-radius: 2px;
  position: relative;
}

.range-group .range {
  pointer-events: none;
  height: 4px;
  vertical-align: top;
}

.range-group .range::-webkit-slider-runnable-track {
  background: none;
}

.range-group .range::-webkit-slider-thumb {
  pointer-events: auto;
}

.range-group .range::-moz-range-progress, .range-group .range::-moz-range-track {
  background: none;
}

.range-group .range::-moz-range-thumb {
  pointer-events: auto;
}

.range-group .range:last-child {
  position: absolute;
  top: 0;
}

.range-group .range:last-child:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: 0;
}

.range-group .range:last-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: 0;
}

.input-group {
  align-items: center;
  column-gap: 20px;
  display: flex;
}

.input-prefix {
  min-width: 0;
  border-radius: min(10px, var(--rounded-button));
  border: 1px solid rgb(var(--text-color) / .12);
  flex: 1 0 0;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  display: flex;
}

.input-prefix:focus-within {
  border-color: rgb(var(--text-color));
  box-shadow: inset 0 0 0 1px rgb(var(--text-color));
}

.input-prefix > .field {
  -webkit-appearance: textfield;
  appearance: textfield;
  min-width: 0;
  width: 100%;
  text-align: end;
  background: none;
  border: none;
  margin-inline-start: 10px;
  padding: 0;
}

.input-prefix > .field:focus {
  outline: none;
}

.input-prefix > .field::-webkit-outer-spin-button, .input-prefix > .field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.price-range {
  gap: 24px;
  padding-block-start: 4px;
  display: grid;
}

.price-range--inline {
  padding: var(--spacing-1);
  grid-template-columns: minmax(110px, auto) 380px minmax(110px, auto);
  align-items: center;
}

@media screen and not (pointer: fine) {
  .price-range {
    row-gap: 18px;
    padding-block-start: 10px;
  }
}

.product-list {
  grid: var(--product-list-grid);
  gap: var(--product-list-gap);
  scroll-padding-top: calc(var(--sticky-area-height)  + 20px);
  display: grid;
  position: relative;
}

.product-list__promo {
  grid-column: var(--product-list-promo-grid-column, span 2);
  min-height: 200px;
  display: grid;
}

@media screen and (min-width: 700px) {
  @supports (grid-template-rows: subgrid) {
    .product-list {
      grid-template-rows: auto auto;
    }

    .product-list > * {
      grid-template-rows: subgrid;
      grid-row: span 2;
      gap: 0;
    }

    .product-list__promo :is(img, video-media) {
      position: absolute;
    }

    .product-list:not(:has(.product-card--blends)) .product-list__promo .content-over-media {
      grid-row: span 2;
    }

    .product-card__info {
      align-content: start;
    }
  }
}

.rating {
  align-items: center;
  gap: var(--spacing-1-5);
  display: flex;
}

.rating__stars {
  display: contents;
}

.rating__star {
  color: rgb(var(--star-color));
  position: relative;
  top: -1px;
}

.rating__star--empty {
  filter: grayscale();
}

@media screen and (min-width: 700px) {
  .rating__star {
    width: 15px;
    height: 15px;
  }
}

.rating-with-text {
  gap: var(--spacing-2);
  grid-template-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  align-items: baseline;
  display: grid;
}

.buy-buttons {
  align-items: start;
  gap: var(--spacing-2);
  display: grid;
}

.buy-buttons--compact {
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

@media screen and (min-width: 700px) {
  .buy-buttons--multiple {
    gap: var(--spacing-4);
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
}

.product-card {
  --product-card-badge-spacing: var(--spacing-2);
  --product-card-info-padding: var(--spacing-4);
  --product-card-quick-buy-inset: var(--spacing-2);
  border-radius: var(--rounded-sm);
  grid-template-columns: minmax(0, 1fr);
  align-content: start;
  display: grid;
  position: relative;
}

.product-card__figure {
  display: block;
  position: relative;
}

.product-card__badge-list {
  gap: var(--spacing-2);
  pointer-events: none;
  z-index: 5;
  display: grid;
  position: absolute;
  top: var(--product-card-badge-spacing);
}

.product-card__badge-list:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: var(--product-card-badge-spacing);
}

.product-card__badge-list:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: var(--product-card-badge-spacing);
}

.product-card__image {
  object-fit: contain;
  object-position: center;
  transition: opacity .2s ease-in-out;
}

.product-card__image:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: var(--rounded-sm);
  border-top-right-radius: var(--rounded-sm);
}

.product-card__image:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: var(--rounded-sm);
  border-top-left-radius: var(--rounded-sm);
}

.product-card__image--secondary {
  display: none;
}

.product-card__title {
  line-height: 1.5;
}

.product-card__quick-buy {
  position: absolute;
  bottom: var(--product-card-quick-buy-inset);
}

.product-card__quick-buy:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: var(--product-card-quick-buy-inset);
}

.product-card__quick-buy:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: var(--product-card-quick-buy-inset);
}

.product-card__mobile-quick-buy-button {
  height: 2.25rem;
  width: 2.25rem;
  background: rgb(var(--background-primary));
  color: rgb(var(--text-primary) / .7);
  border-radius: var(--rounded-button);
  border-width: 1px;
  place-content: center;
  display: grid;
}

.product-card__mobile-quick-buy-button > .button__loader {
  gap: var(--spacing-1);
}

.product-card__mobile-quick-buy-button > .button__loader > * {
  width: var(--spacing-1);
  height: var(--spacing-1);
}

.product-card__info {
  gap: var(--spacing-1);
  padding: var(--product-card-info-padding);
  grid-template-columns: minmax(0, 1fr);
  justify-items: start;
  display: grid;
}

.product-card--blends .product-card__info {
  padding-block-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
}

.product-card__info--center {
  text-align: center;
  justify-content: center;
  justify-items: center;
}

.product-card__variant-list {
  display: none;
}

@media screen and (min-width: 700px) {
  .product-card {
    --product-card-badge-spacing: var(--spacing-4);
    --product-card-info-padding: var(--spacing-5);
    --product-card-quick-buy-inset: var(--spacing-4);
  }

  .product-card__aside {
    margin-block-start: var(--spacing-1);
  }

  .product-card__variant-list {
    gap: var(--spacing-2);
    flex-wrap: wrap;
    margin-block-start: var(--spacing-2);
    display: flex;
  }
}

@media screen and (min-width: 1400px) {
  .product-card__info {
    --product-card-info-padding: var(--spacing-6) var(--spacing-8) var(--spacing-8) var(--spacing-8);
  }
}

@media screen and (pointer: fine) {
  .product-card--show-secondary-media .product-card__figure:hover .product-card__image--primary {
    opacity: 0;
  }

  .product-card--show-secondary-media .product-card__figure:hover .product-card__image--secondary {
    opacity: 1;
  }

  .product-card__image--secondary {
    opacity: 0;
    display: block;
  }

  .product-card__quick-buy {
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s ease-in-out, transform .2s ease-in-out, visibility .2s ease-in-out;
    transform: translateY(5px);
  }

  .product-card:hover .product-card__quick-buy {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.model-viewer {
  position: relative;
}

.model-viewer model-viewer {
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
}

.model-viewer .shopify-model-viewer-ui {
  display: block;
}

.shopify-model-viewer-ui.shopify-model-viewer-ui .shopify-model-viewer-ui__controls-area {
  background: rgb(var(--background-primary));
  border-color: rgb(var(--text-primary) / .25);
}

.shopify-model-viewer-ui.shopify-model-viewer-ui .shopify-model-viewer-ui__button {
  color: rgb(var(--text-primary));
}

.shopify-model-viewer-ui.shopify-model-viewer-ui .shopify-model-viewer-ui__button--control:hover {
  color: rgb(var(--text-primary) / .55);
}

.shopify-model-viewer-ui.shopify-model-viewer-ui .shopify-model-viewer-ui__button--control:active, .shopify-model-viewer-ui.shopify-model-viewer-ui .shopify-model-viewer-ui__button--control.focus-visible:focus {
  color: rgb(var(--text-primary) / .55);
  background: rgb(var(--text-primary) / .25);
}

.shopify-model-viewer-ui.shopify-model-viewer-ui .shopify-model-viewer-ui__button--control:not(:last-child):after {
  border-color: rgb(var(--text-primary) / .25);
}

.shopify-model-viewer-ui.shopify-model-viewer-ui .shopify-model-viewer-ui__button--poster {
  background: rgb(var(--background-primary));
  border-color: rgb(var(--text-primary) / .25);
  border-radius: var(--rounded-button);
}

.shopify-model-viewer-ui.shopify-model-viewer-ui .shopify-model-viewer-ui__button--poster:hover, .shopify-model-viewer-ui.shopify-model-viewer-ui .shopify-model-viewer-ui__button--poster:focus {
  color: rgb(var(--text-primary) / .55);
}

.price-list {
  align-items: baseline;
  gap: var(--spacing-0-5) var(--spacing-2);
  flex-wrap: wrap;
  display: flex;
}

.price-list--lg {
  gap: var(--spacing-3);
}

.pickup-drawer::part(close-button) {
  align-self: start;
  margin-block-start: .375rem;
}

.pickup-drawer::part(body) {
  padding-block-start: 0;
  padding-block-end: 0;
}

.pickup-availability__closest-location {
  align-items: start;
  gap: var(--spacing-3);
  display: flex;
}

.pickup-availability__media {
  width: var(--spacing-16);
}

.pickup-availability__location {
  gap: var(--spacing-2);
  padding-block-start: var(--spacing-5);
  padding-block-end: var(--spacing-5);
  display: grid;
}

@media screen and (min-width: 700px) {
  .pickup-availability__media {
    width: var(--spacing-20);
  }

  .pickup-availability__location {
    padding-block-start: var(--spacing-6);
    padding-block-end: var(--spacing-6);
  }
}

.quantity-selector {
  height: var(--input-height);
  background: rgb(var(--input-background, transparent));
  color: rgb(var(--input-text-color, var(--text-color)));
  border-radius: var(--rounded-button);
  border-width: 1px;
  display: inline-flex;
}

.quantity-selector__button {
  padding-inline-start: var(--spacing-5);
  padding-inline-end: var(--spacing-5);
}

.quantity-selector__input {
  -webkit-appearance: none;
  appearance: none;
  min-width: var(--spacing-6);
  background: inherit;
  text-align: center;
}

.quantity-selector__input:focus {
  outline: none;
}

@media screen and (min-width: 700px) {
  .quantity-selector__button {
    padding-inline-start: var(--spacing-6);
    padding-inline-end: var(--spacing-6);
  }
}

.product-quick-add {
  width: 100%;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  padding: 1.25rem;
  transition: visibility .15s ease-in, opacity .15s ease-in, transform .15s ease-in;
  position: fixed;
  bottom: 0;
  transform: translateY(10px);
}

.product-quick-add:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: 0;
}

.product-quick-add:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: 0;
}

.product-quick-add.is-visible {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

@media screen and (min-width: 700px) {
  .product-quick-add {
    width: 35rem;
    border-radius: var(--rounded-sm);
    background: rgb(var(--dialog-background));
    border-width: 1px;
    padding: 1rem;
    bottom: 1rem;
    transform: translateY(0);
  }

  .product-quick-add:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    right: 1rem;
  }

  .product-quick-add:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    left: 1rem;
  }

  .product-quick-add__variant {
    grid-template-columns: 80px minmax(0, 1fr) auto;
    align-items: center;
    column-gap: 1.5rem;
    display: grid;
  }

  .product-quick-add__variant.no-image {
    grid-template-columns: minmax(0, 1fr) auto;
  }
}

.quick-buy-drawer {
  --drawer-header-padding: var(--spacing-6);
  --drawer-body-padding: var(--spacing-6);
  --shopify-payment-button-padding: .8125rem var(--spacing-6);
  display: none;
}

.quick-buy-drawer::part(close-button) {
  align-self: start;
  margin-block-start: .25rem;
  margin-inline-end: .25rem;
}

.quick-buy-drawer .variant-picker {
  text-align: center;
}

.quick-buy-drawer .variant-picker__option-info, .quick-buy-drawer .variant-picker__option-values {
  justify-content: center;
}

.quick-buy-drawer .variant-picker__option-values--color {
  max-width: 300px;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.quick-buy-drawer .block-swatch {
  padding: var(--spacing-2-5) var(--spacing-5);
  height: 2.625rem;
}

.quick-buy-drawer__media {
  max-width: var(--spacing-20);
}

.quick-buy-drawer__info {
  gap: var(--spacing-6);
  display: grid;
}

@media screen and (min-width: 700px) {
  .quick-buy-drawer {
    --drawer-header-padding: var(--spacing-6);
    height: auto;
    width: 560px;
  }

  .quick-buy-drawer .variant-picker__option-info, .quick-buy-drawer .block-swatch {
    font-size: var(--text-sm);
  }

  .quick-buy-drawer .color-swatch {
    --swatch-size: 1.5rem;
  }

  .quick-buy-drawer .color-swatch--rectangle {
    --swatch-size: 2rem;
  }

  .quick-buy-drawer :is(.button, .shopify-payment-button__button--unbranded, .shopify-payment-button__button--branded [role="button"]) {
    padding: var(--spacing-3) var(--spacing-6) !important;
    font-size: var(--text-sm) !important;
  }

  .quick-buy-drawer__info {
    gap: var(--spacing-8);
  }
}

.variant-picker {
  gap: var(--spacing-6);
  grid-template-columns: minmax(0, 1fr);
  display: grid;
}

.variant-picker .popover {
  --popover-anchor-block-spacing: var(--spacing-2);
  --popover-content-max-width: none;
}

.variant-picker .popover, .variant-picker .popover::part(content) {
  width: 100%;
}

.variant-picker__option-info {
  justify-content: space-between;
  align-items: baseline;
  margin-block-end: var(--spacing-2);
  display: flex;
}

.variant-picker__option-values {
  display: flex;
}

.variant-picker__option-values.scroll-area {
  padding: 2px 1px;
}

@media screen and (min-width: 700px) {
  .variant-picker__option-values {
    flex-wrap: wrap;
  }
}

.prose ol, .list-decimal {
  list-style: decimal inside;
}

.prose ul, .list-disc {
  list-style: inside;
}

.prose :is(ol, ul) :is(ol, ul) {
  margin-block-start: 1em;
  margin-inline-start: 1em;
}

.prose > :first-child, .prose > :first-child :first-child {
  margin-block-start: 0 !important;
}

.prose > :last-child, .prose > :last-child :last-child {
  margin-block-end: 0 !important;
}

.prose * + :is(p, div, ul, ol) {
  margin-block-start: var(--spacing-3);
}

.prose * + :is(.h0, .h1, .h2, h1, h2) {
  margin-block-start: var(--spacing-4);
}

.prose * + :is(.h3, .h4, .h5, .h6, h3, h4, h5, h6) {
  margin-block-start: var(--spacing-3);
}

.prose * + .button {
  margin-block-start: var(--spacing-6) !important;
}

.prose * + span:not(:empty) {
  display: inline-block;
}

.prose :is(.h0, .h1, .h2, .h3, .h4, h1, h2, h3, h4) + * {
  margin-block-start: var(--spacing-5);
}

.prose :is(.h5, .h6, h5, h6) + * {
  margin-block-start: var(--spacing-4);
}

.prose :is(p img:not([style*="float"]):only-child, div img:not([style*="float"]):only-child, figure, video) {
  margin-block: var(--spacing-8);
}

.prose figcaption {
  font-style: italic;
  font-size: var(--text-sm);
  color: rgb(var(--text-color) / .7);
  margin-block-start: .5em;
}

.prose ul, .prose ol {
  row-gap: .6em;
  display: grid;
}

.prose :is(iframe[src*="youtube"], iframe[src*="youtu.be"], iframe[src*="vimeo"]) {
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto;
}

@media screen and (min-width: 700px) {
  .prose * + :is(p, div, ul, ol) {
    margin-block-start: var(--spacing-4);
  }

  .prose * + :is(.h0, .h1, .h2, h1, h2) {
    margin-block-start: var(--spacing-6);
  }

  .prose * + :is(.h3, .h4, h3, h4) {
    margin-block-start: var(--spacing-5);
  }

  .prose * + :is(.h5, .h6, h5, h6) {
    margin-block-start: var(--spacing-4);
  }

  .prose * + .button {
    margin-block-start: var(--spacing-8) !important;
  }

  .prose .h0 + .button {
    margin-block-start: var(--spacing-10) !important;
  }

  .prose :is(.h0, .h1, .h2, .h3, h1, h2, h3) + * {
    margin-block-start: var(--spacing-6);
  }

  .prose :is(.h4, h4) + * {
    margin-block-start: var(--spacing-5);
  }

  .prose :is(.h5, .h6, h5, h6) + * {
    margin-block-start: var(--spacing-4);
  }

  .prose :is(p img:not([style*="float"]):only-child, div img:not([style*="float"]):only-child, figure, video) {
    margin-block: var(--spacing-12);
  }
}

@media screen and (min-width: 1150px) {
  .prose * + p {
    margin-block-start: var(--spacing-6);
  }

  .prose * + :is(.h0, .h1, h1) {
    margin-block-start: var(--spacing-8);
  }

  .prose * + :is(.h2, .h3, .h4, h2, h3, h4) {
    margin-block-start: var(--spacing-6);
  }

  .prose * + :is(.h5, .h6, h5, h6) {
    margin-block-start: var(--spacing-4);
  }

  .prose :is(.h0, .h1, .h2, h1, h2) + * {
    margin-block-start: var(--spacing-8);
  }

  .prose :is(.h3, .h4, h3, h4) + * {
    margin-block-start: var(--spacing-6);
  }

  .prose :is(.h5, .h6, h5, h6) + * {
    margin-block-start: var(--spacing-4);
  }
}

.link, .prose a:not(.button) {
  text-underline: none;
  background: linear-gradient(to right, currentColor, currentColor) 0 min(100%, 1.35em) / 100% 1px no-repeat;
  transition: background-size .3s ease-in-out, color .3s ease-in-out;
}

@media screen and (pointer: fine) {
  :is(.link, .prose a:not(.button)):hover {
    background-size: 0 1px;
  }

  .text-subdued :is(.link, .prose a:not(.button)):hover {
    color: rgb(var(--text-color));
  }
}

.reversed-link {
  text-underline: none;
  background: linear-gradient(to right, currentColor, currentColor) 0 min(100%, 1.35em) / 0 1px no-repeat;
  transition: background-size .3s ease-in-out;
}

@media screen and (pointer: fine) {
  .group:hover .reversed-link, .reversed-link.hover\:show:hover {
    background-size: 100% 1px;
  }
}

.link-faded {
  opacity: .7;
  transition: opacity .2s ease-in-out;
}

@media screen and (pointer: fine) {
  .link-faded:hover {
    opacity: 1;
  }
}

.link-faded-reverse {
  transition: opacity .2s ease-in-out;
}

@media screen and (pointer: fine) {
  .link-faded-reverse:hover {
    opacity: .7;
  }
}

table {
  width: 100%;
}

th {
  text-align: start;
}

th, td {
  padding: var(--spacing-4);
  vertical-align: top;
}

:is(th, td):first-child {
  padding-inline-start: 0;
}

:is(th, td):last-child {
  padding-inline-end: 0;
}

thead th {
  border-block-end-width: 1px;
}

tbody tr + tr > *, thead + tbody tr > * {
  border-top-width: 1px;
}

tfoot td {
  border-top-width: 1px;
  padding-block-start: var(--spacing-6);
  padding-block-end: 0;
}

.table-row-hover {
  cursor: pointer;
  transition: background .2s ease-in-out;
}

@media screen and (pointer: fine) {
  .table-row-hover:hover {
    background: rgb(var(--text-color) / .05);
  }
}

@media screen and (min-width: 700px) {
  th, td {
    padding-block-start: var(--spacing-6);
    padding-block-end: var(--spacing-6);
  }

  tfoot td {
    padding-block-start: var(--spacing-8);
  }
}

.blockquote, .prose blockquote {
  --quote-width: 51px;
  --quote-height: 37px;
  --quote-inset-block-start: calc(var(--spacing-5) * -1);
  --quote-inset-inline-start: var(--spacing-1);
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: bold;
  position: relative;
}

:is(.blockquote, .prose blockquote):before {
  content: "";
  width: var(--quote-width);
  height: var(--quote-height);
  background: rgb(var(--text-color) / .1);
  -webkit-mask-size: var(--quote-width) var(--quote-height);
  mask-size: var(--quote-width) var(--quote-height);
  position: absolute;
  top: var(--quote-inset-block-start);
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='86' height='55' viewBox='0 0 86 55' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M71.3163 54.6H42.5163L60.3163 0.400024H85.5163L71.3163 54.6ZM29.3163 54.6H0.716309L18.9163 0.400024H44.1163L29.3163 54.6Z' fill='%23252627'/%3E%3C/svg%3E%0A");
  mask-image: url("data:image/svg+xml,%3Csvg width='86' height='55' viewBox='0 0 86 55' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M71.3163 54.6H42.5163L60.3163 0.400024H85.5163L71.3163 54.6ZM29.3163 54.6H0.716309L18.9163 0.400024H44.1163L29.3163 54.6Z' fill='%23252627'/%3E%3C/svg%3E%0A");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

:is(.blockquote, .prose blockquote):not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  left: var(--quote-inset-inline-start);
}

:is(.blockquote, .prose blockquote):is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  right: var(--quote-inset-inline-start);
}

.blockquote {
  margin-block-start: var(--spacing-5);
}

.prose blockquote {
  --quote-inset-block-start: calc(var(--spacing-4) * -1);
  --quote-inset-inline-start: calc(50% - (var(--quote-width) / 2));
  text-align: center;
  font-size: var(--text-h3);
  margin-block-start: var(--spacing-14);
  margin-block-end: var(--spacing-10);
  line-height: 1.2;
}

@media screen and (min-width: 700px) {
  .blockquote, .prose blockquote {
    --quote-width: 70px;
    --quote-height: 45px;
  }

  .blockquote {
    --quote-inset-block-start: calc(var(--spacing-6) * -1);
    margin-block-start: var(--spacing-8);
  }
}

@media screen and (min-width: 1150px) {
  .prose blockquote {
    --quote-width: 85px;
    --quote-height: 55px;
    --quote-inset-block-start: calc(var(--spacing-5) * -1);
    margin-block-start: var(--spacing-18);
    margin-block-end: var(--spacing-12);
  }

  .blockquote {
    --quote-inset-inline-start: var(--spacing-2-5);
  }
}

.shape-circle, .shape-square, .shape-diamond {
  --size: var(--spacing-1-5);
  width: var(--size);
  height: var(--size);
  background: currentColor;
  display: block;
}

.shape-circle {
  border-radius: var(--rounded-full);
}

.shape-diamond {
  transform: rotateZ(45deg);
}

.shape-line {
  height: 100%;
  width: 1px;
  background: rgb(var(--text-color) / .1);
}

.shape--sm {
  --size: var(--spacing-1);
}

.shape--lg {
  --size: var(--spacing-2);
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.skeleton {
  background: rgb(var(--text-color) / .15);
  border-radius: 4px;
  flex-shrink: 0;
  animation: 2.5s cubic-bezier(.4, 0, .6, 1) infinite pulse;
  display: block;
}

.skeleton--tab {
  width: 88px;
  height: 20px;
}

.skeleton--thumbnail {
  width: 80px;
  height: 80px;
}

.skeleton--text {
  height: 12px;
  width: var(--skeleton-text-width, 100%);
}

@media screen and (min-width: 700px) {
  .skeleton--tab {
    width: 148px;
    height: 34px;
  }

  .skeleton--thumbnail {
    width: 96px;
    height: 96px;
  }
}

.color-swatch {
  --swatch-offset: 3px;
  --swatch-size: var(--spacing-7);
  --swatch-margin: calc(var(--swatch-offset) * 2);
  width: var(--swatch-size);
  height: var(--swatch-size);
  margin: var(--swatch-margin);
  background: var(--swatch-background) center / cover;
  place-items: center;
  display: grid;
  position: relative;
}

:disabled + .color-swatch, .color-swatch.is-disabled {
  background: linear-gradient(to bottom right, transparent calc(50% - 1px), rgb(var(--background)) calc(50% - 1px) calc(50% + 1px), transparent calc(50% + 1px)), var(--swatch-background) center / cover;
}

.color-swatch:before {
  content: "";
  inset: calc(-1 * var(--swatch-offset));
  opacity: 0;
  border-radius: inherit;
  will-change: transform;
  transition: opacity .2s ease-in-out, transform .2s ease-in-out;
  position: absolute;
  transform: scale(.75);
  box-shadow: 0 0 0 2px;
}

:checked + .color-swatch:before, .color-swatch.is-selected:before {
  opacity: 1;
  transform: scale(1);
}

:focus-visible + .color-swatch {
  outline-offset: calc(2px + var(--swatch-offset) * 2);
}

.color-swatch--rectangle {
  --swatch-size: var(--spacing-10);
  height: calc(var(--swatch-size) * .4);
  margin: 0;
}

.color-swatch--rectangle:before {
  width: 100%;
  bottom: -3px;
  box-shadow: 0 2px;
}

.color-swatch--rectangle:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  left: 0;
}

.color-swatch--rectangle:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  right: 0;
}

.color-swatch--sm {
  --swatch-size: var(--spacing-3-5);
  --swatch-offset: 2px;
}

.color-swatch--rectangle.color-swatch--sm {
  --swatch-size: var(--spacing-5);
  margin-block-start: 7px;
  margin-block-end: 6px;
}

.color-swatch--rectangle.color-swatch--sm:before {
  bottom: -2px;
}

.color-swatch__view-more {
  padding: 1px var(--spacing-2);
  color: rgb(var(--text-color) / .7);
  border-width: 1px;
  margin-inline-start: var(--spacing-1-5);
}

.media-swatch {
  --swatch-size: 60px;
  width: var(--swatch-size);
  height: var(--swatch-size);
  border-radius: min(4px, var(--rounded-input));
  flex-shrink: 0;
  display: block;
  position: relative;
}

.media-swatch:before {
  content: "";
  border-radius: inherit;
  opacity: 0;
  will-change: transform;
  transition: opacity .2s ease-in-out, transform .2s ease-in-out;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scale(.95);
  box-shadow: 0 0 0 2px;
}

.media-swatch.is-disabled:after {
  content: "";
  background-image: linear-gradient(to bottom right, transparent calc(50% - 1px), rgb(var(--text-color) / .5) calc(50% - 1px) calc(50% + 1px), transparent calc(50% + 1px));
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.media-swatch > img {
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

:checked + .media-swatch:before, .media-swatch.is-selected:before {
  opacity: 1;
  transform: scale(1);
}

:focus-visible + .media-swatch {
  outline-offset: 4px;
}

.media-swatch--sm {
  --swatch-size: 48px;
}

.media-swatch__view-more {
  width: var(--spacing-12);
  height: var(--spacing-12);
  border-radius: min(4px, var(--rounded-input));
  border-width: 1px;
  place-items: center;
  display: grid;
}

.block-swatch {
  place-items: center;
  gap: var(--spacing-3);
  height: var(--input-height);
  white-space: nowrap;
  border-radius: var(--rounded-button);
  background: rgb(var(--input-background, var(--background))) no-repeat;
  border-width: 1px;
  padding-inline-start: var(--spacing-5);
  padding-inline-end: var(--spacing-5);
  display: flex;
  position: relative;
}

.block-swatch.is-disabled {
  color: rgb(var(--text-color) / .5);
  background-image: linear-gradient(to bottom right, #0000 50%, currentColor 50% calc(50% + 2px), #0000 calc(50% + 2px));
}

.block-swatch:before {
  content: "";
  box-shadow: 0 0 0 2px rgb(var(--text-color));
  border-radius: inherit;
  opacity: 0;
  will-change: transform;
  transition: opacity .2s ease-in-out, transform .2s ease-in-out;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scale(.95);
}

:checked + .block-swatch.is-disabled {
  background-image: linear-gradient(to bottom right, transparent 50%, rgb(var(--text-color)) 50% calc(50% + 2px), transparent calc(50% + 2px));
}

:checked + .block-swatch:before, .block-swatch.is-selected:before {
  opacity: 1;
  transform: scale(1);
}

:focus-visible + .block-swatch {
  outline-offset: 4px;
}

.block-swatch__color {
  width: var(--spacing-3-5);
  height: var(--spacing-3-5);
  border-radius: var(--rounded-full);
  background: var(--swatch-background) center / cover;
}

@media screen and (min-width: 700px) {
  .block-swatch {
    padding-inline-start: var(--spacing-6);
    padding-inline-end: var(--spacing-6);
  }
}

[data-tooltip] {
  position: relative;
}

@media screen and (pointer: fine) {
  [data-tooltip]:after {
    content: attr(data-tooltip);
    bottom: calc(100% + var(--spacing-2));
    width: max-content;
    border-radius: min(24px, var(--rounded-button));
    background: rgb(var(--text-color));
    color: rgb(var(--background));
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    padding: 2px 10px;
    font-size: 12px;
    font-weight: bold;
    transition: opacity .2s ease-in-out, visibility .2s ease-in-out, transform .2s ease-in-out;
    position: absolute;
    left: 50%;
    transform: translateX(-50%)scale(.9);
  }

  [data-tooltip]:hover:after {
    visibility: visible;
    opacity: 1;
    will-change: transform;
    transform: translateX(-50%)scale(1);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .js .page-transition {
    opacity: 0;
  }
}

.lock {
  overflow: hidden;
}

.text-with-icon {
  gap: var(--spacing-3);
  align-items: center;
  display: flex;
}

.text-with-icon > svg {
  flex-shrink: 0;
}

.offer {
  gap: var(--spacing-2);
  padding: var(--spacing-5);
  border-radius: var(--rounded-sm);
  display: grid;
}

.offer--center {
  text-align: center;
  justify-items: center;
}

.social-media {
  gap: var(--spacing-3) var(--spacing-6);
  flex-wrap: wrap;
  display: flex;
}

.social-media--sm svg {
  width: 1.25rem;
  height: 1.25rem;
}

@media screen and (min-width: 700px) {
  .social-media--sm svg {
    width: 1.7rem;
    height: 1.7rem;
  }
}

.share-buttons {
  align-items: center;
  gap: var(--spacing-2-5);
  display: flex;
}

.share-buttons__item {
  width: var(--spacing-8-5);
  height: var(--spacing-8-5);
  opacity: .7;
  background: rgb(var(--text-color) / 0);
  border-radius: var(--rounded-full);
  place-content: center;
  transition: opacity .2s ease-in-out, background .2s ease-in-out;
  display: grid;
}

.share-buttons__item:hover {
  opacity: 1;
  background: rgb(var(--text-color) / .1);
}

.floating-controls-container {
  position: relative;
}

.floating-controls-container .circle-button {
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out, visibility .1s ease-in-out;
  position: absolute;
  top: calc(50% - 24px);
  transform: scale(.8);
}

.floating-controls-container .circle-button:first-of-type:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: var(--spacing-5);
}

.floating-controls-container .circle-button:first-of-type:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: var(--spacing-5);
}

.floating-controls-container .circle-button:last-of-type:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: var(--spacing-5);
}

.floating-controls-container .circle-button:last-of-type:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: var(--spacing-5);
}

@media screen and (pointer: fine) {
  .floating-controls-container:hover .is-scrollable ~ .circle-button:not([disabled]) {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
}

.icon {
  vertical-align: middle;
  display: block;
}

[dir="rtl"] .reverse-icon {
  transform: rotate(180deg);
}

.offset-icon {
  --icon-baseline-distance: calc(1em * 1.6);
  --icon-offset: calc((var(--icon-baseline-distance)  - var(--icon-height)) / 2);
  top: var(--icon-offset);
  position: relative;
}

.icon-chevron-bottom {
  transition: transform .2s ease-in-out;
}

.group[aria-expanded="true"] > .icon-chevron-bottom {
  transform: rotate(180deg);
}

.icon-block {
  padding: var(--spacing-6);
  background: rgb(var(--background));
}

.image-icon {
  width: var(--mobile-icon-max-width, var(--icon-max-width));
}

@media screen and (min-width: 700px) {
  .image-icon {
    width: var(--icon-max-width);
  }
}

custom-cursor {
  z-index: 2;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  scale: .5;
  transition: opacity .1s, scale .1s, visibility .1s !important;
}

@media screen and (pointer: fine) {
  custom-cursor {
    display: block;
  }

  custom-cursor.is-visible {
    opacity: 1;
    visibility: visible;
    scale: 1;
  }
}

:has( > custom-cursor:not([hidden])) {
  cursor: none;
}

scroll-shadow {
  --scroll-shadow-size: 30px;
}

split-lines {
  overflow-wrap: anywhere;
}

.customer-form__image {
  display: none;
}

.customer-form__box {
  padding: var(--spacing-14) var(--container-gutter);
}

.customer-form__box-inner {
  max-width: 440px;
  margin-inline-start: auto;
  margin-inline-end: auto;
  display: block;
}

@media screen and (min-width: 700px) {
  .customer-form {
    margin-block-start: var(--spacing-10);
    margin-block-end: var(--spacing-10);
  }

  .customer-form__box {
    border-radius: var(--rounded);
    padding-inline-start: var(--spacing-16);
    padding-inline-end: var(--spacing-16);
  }
}

@media screen and (min-width: 1000px) {
  .customer-form {
    gap: var(--spacing-6);
    grid: auto / auto-flow minmax(0, 680px);
    justify-content: center;
    display: grid;
  }

  .customer-form__image {
    height: 100%;
    object-fit: cover;
    object-position: center;
    display: block;
  }
}

.account {
  gap: var(--spacing-6);
  max-width: 1350px;
  margin-inline-start: auto;
  margin-inline-end: auto;
  display: grid;
}

.account-nav__item {
  padding-block-start: var(--spacing-4-5);
  padding-block-end: var(--spacing-4-5);
}

.account-nav__item[aria-current="page"] {
  border-color: currentColor;
  border-bottom-width: 2px;
}

.account-header, .account__block-list {
  gap: var(--spacing-6);
  display: grid;
  position: relative;
}

@media screen and (min-width: 700px) {
  .account {
    gap: var(--spacing-12);
  }

  .account-nav__item--logout {
    position: absolute;
  }

  .account-nav__item--logout:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    right: 0;
  }

  .account-nav__item--logout:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    left: 0;
  }

  .account-header--back {
    text-align: center;
    justify-content: center;
  }

  .account-header--back > .back-button {
    position: absolute;
  }
}

.order-table-list {
  display: none;
}

.order-grid-list {
  gap: var(--spacing-5);
  display: grid;
}

.order-grid-item {
  gap: var(--spacing-4);
  padding: var(--spacing-6);
  border-width: 1px;
  padding-block-start: var(--spacing-5);
  display: grid;
}

.order-grid-item__categories {
  gap: var(--spacing-4);
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
}

@media screen and (min-width: 700px) {
  .order-grid-list {
    display: none;
  }

  .order-table-list {
    max-width: 1024px;
    margin-inline-start: auto;
    margin-inline-end: auto;
    display: table;
  }

  .order-table-list th, .order-table-list td {
    padding-inline-start: var(--spacing-4);
    padding-inline-end: var(--spacing-4);
  }
}

.addresses-list {
  --addresses-per-row: 1;
  gap: var(--spacing-5);
  display: grid;
}

.address {
  width: 100%;
  gap: var(--spacing-2);
  padding: var(--spacing-6);
  border-width: 1px;
  flex-direction: column;
  display: flex;
}

.address__actions {
  gap: var(--spacing-6);
  margin-block-start: auto;
  padding-block-start: var(--spacing-2);
  display: flex;
}

.address-form {
  gap: var(--spacing-3);
  display: grid;
}

@media screen and (min-width: 700px) {
  .addresses-list {
    --addresses-per-row: 2;
    grid-template-columns: repeat(auto-fit, minmax(0, calc(100% / var(--addresses-per-row)  - var(--spacing-6) / var(--addresses-per-row) * (var(--addresses-per-row)  - 1))));
    gap: var(--spacing-6);
    justify-content: center;
    justify-items: center;
  }

  .address {
    padding: var(--spacing-8) var(--spacing-10);
  }

  .address-form {
    gap: var(--spacing-6);
  }
}

@media screen and (min-width: 1000px) {
  .addresses-list {
    --addresses-per-row: 4;
  }
}

.order {
  align-items: start;
  gap: var(--spacing-6);
  display: grid;
}

.order-addresses-list {
  gap: var(--spacing-5);
  display: grid;
}

@media screen and (min-width: 700px) {
  .order {
    gap: var(--spacing-12);
  }

  .order-addresses-list {
    gap: var(--spacing-6);
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (min-width: 1150px) {
  .order {
    grid-template-columns: 1fr 380px;
  }

  .order-addresses-list {
    grid-template-columns: none;
  }
}

@media screen and (min-width: 1400px) {
  .order {
    gap: var(--spacing-24);
  }
}

.announcement-bar {
  padding-block-start: var(--spacing-2-5);
  padding-block-end: var(--spacing-2-5);
}

.announcement-bar__wrapper {
  gap: var(--spacing-4);
  max-width: 450px;
  margin-inline-start: auto;
  margin-inline-end: auto;
  display: flex;
}

.announcement-bar__scrolling-list {
  grid: auto / auto-flow max-content;
  justify-content: center;
  display: grid;
  overflow: hidden;
}

.announcement-bar__static-list {
  text-align: center;
  place-items: center;
  display: grid;
}

.announcement-bar__static-list > * {
  grid-area: 1 / -1;
}

.announcement-bar__item {
  grid: auto / auto-flow auto var(--spacing-20);
  place-items: center;
  display: grid;
}

@media screen and (min-width: 700px) {
  .announcement-bar {
    padding-block-start: var(--spacing-3-5);
    padding-block-end: var(--spacing-3-5);
  }

  .announcement-bar__item {
    grid-auto-columns: auto var(--spacing-40);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .announcement-bar__item {
    animation: translateFull var(--marquee-animation-duration, 0s) linear infinite;
  }
}

.article {
  --article-margin-block-end: var(--spacing-14);
}

.article > .container {
  justify-content: safe center;
  gap: var(--spacing-10);
  margin-block-end: var(--article-margin-block-end);
  display: grid;
}

@media screen and (min-width: 700px) {
  .article {
    --article-margin-block-end: var(--spacing-28);
  }

  .article > .container {
    gap: var(--spacing-16);
  }
}

.article-banner {
  grid: var(--article-banner-grid);
  column-gap: var(--article-banner-column-gap);
  align-items: var(--article-banner-horizontal-alignement, center);
  max-width: var(--article-banner-max-width);
  box-sizing: content-box;
  justify-items: center;
  margin-inline-start: auto;
  margin-inline-end: auto;
  padding-block-start: var(--article-banner-padding-block-start, 0);
  display: grid;
  position: relative;
}

.article-banner:before {
  content: "";
  width: calc((100vw - var(--scrollbar-width, 0px)));
  height: var(--article-banner-before-height);
  pointer-events: none;
  background-color: rgb(var(--article-banner-background));
  position: absolute;
  top: 0;
}

.article-banner__image {
  z-index: 1;
  width: 100%;
}

.article-banner__image > img {
  width: 100%;
}

.article-banner__content {
  gap: var(--spacing-6);
  padding: var(--article-banner-content-padding-block-start) var(--article-banner-content-padding-inline) var(--article-banner-content-padding-block-end);
  justify-items: var(--article-banner-vertical-alignement, center);
  text-align: var(--article-banner-vertical-alignement, center);
  z-index: 2;
  display: grid;
}

.article-banner__content > .badge {
  --badge-background: var(--article-banner-badge-background);
  padding: var(--spacing-1) var(--spacing-3);
  display: inline-block;
}

.article__meta {
  justify-content: var(--article-banner-meta-vertical-alignment, center);
  row-gap: var(--spacing-2);
  column-gap: var(--spacing-4);
  flex-wrap: wrap;
  display: flex;
}

.article__meta .text-with-icon {
  gap: var(--spacing-2);
}

@media screen and (min-width: 1150px) {
  .article-banner {
    padding-inline-start: var(--container-gutter);
    padding-inline-end: var(--container-gutter);
  }

  .article-banner__content {
    grid-area: var(--article-banner-grid-area, content);
  }

  .article-banner__image {
    grid-area: var(--article-banner-grid-area, image);
    position: relative;
  }

  .article-banner__image:before {
    content: "";
    background: rgb(var(--article-banner-image-overlay));
    border-radius: inherit;
    z-index: 1;
    pointer-events: none;
    transition: background .2s ease-in-out;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .article__meta {
    column-gap: var(--spacing-6);
  }

  .article__meta .text-with-icon {
    gap: var(--spacing-2-5);
  }
}

.article-content {
  max-width: var(--article-max-width);
  grid-template-columns: minmax(0, 1fr);
  margin-inline-start: auto;
  margin-inline-end: auto;
  padding-block-start: var(--spacing-10);
}

.article-content > .share-buttons .share-buttons__item {
  width: var(--spacing-10);
  height: var(--spacing-10);
  opacity: 1;
}

@media screen and (min-width: 1000px) {
  .article-content {
    border-bottom-width: 1px;
    padding-block-start: var(--spacing-16);
    padding-block-end: var(--spacing-18);
  }
}

.article-navigation {
  gap: var(--spacing-6);
  max-width: var(--article-max-width);
  display: grid;
}

.article-prev-next {
  gap: var(--spacing-5);
  grid: auto / auto-flow 75vw;
  display: grid;
}

@media screen and (min-width: 700px) {
  .article-navigation {
    --navigation-margin-block: var(--spacing-16);
    gap: var(--spacing-10);
  }

  .article-navigation__title > svg {
    width: 27px;
    height: 24px;
  }

  .article-prev-next {
    gap: var(--spacing-12);
    grid: auto / repeat(2, minmax(0, 1fr));
  }
}

.article-comments {
  --comments-padding-inner: var(--spacing-6);
  gap: var(--spacing-12);
  max-width: var(--article-max-width);
  display: grid;
}

.comments-list {
  gap: var(--spacing-4);
  padding: var(--comments-padding-inner);
  display: grid;
}

.comment {
  gap: var(--spacing-3);
  display: grid;
}

.comment:not(:first-child) {
  padding-block-start: var(--spacing-4);
}

.article-comments__form {
  padding: var(--comments-padding-inner);
}

@media screen and (min-width: 700px) {
  .article-comments {
    --comments-padding-inner: var(--spacing-12);
  }

  .comments-list {
    gap: var(--spacing-8);
  }

  .comment {
    gap: var(--spacing-6);
    display: flex;
  }

  .comment:not(:first-child) {
    padding-block-start: var(--spacing-8);
  }

  .comment__gravatar {
    width: var(--spacing-12);
    height: var(--spacing-12);
  }
}

.before-after {
  --before-after-label-spacing: var(--spacing-5);
  -webkit-user-select: none;
  user-select: none;
  position: relative;
}

.before-after__label {
  position: absolute;
}

.before-after__label--left:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: var(--before-after-label-spacing);
}

.before-after__label--left:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: var(--before-after-label-spacing);
}

.before-after__label--right:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: var(--before-after-label-spacing);
}

.before-after__label--right:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: var(--before-after-label-spacing);
}

.before-after__label--top {
  top: var(--before-after-label-spacing);
}

.before-after__label--bottom {
  bottom: var(--before-after-label-spacing);
}

.before-after__after-image {
  clip-path: inset(0 0 0 calc(var(--before-after-initial-drag-position, 50%)  + var(--clip-path-offset, 0px)));
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

[dir="rtl"] .before-after__after-image {
  clip-path: inset(0 calc(var(--before-after-initial-drag-position, 50%)  - var(--clip-path-offset, 0px)) 0 0);
}

.before-after__cursor-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.before-after__cursor {
  height: 100%;
  width: max-content;
  touch-action: none;
  transform: translate(calc(var(--transform-logical-flip) * -50% + var(--clip-path-offset, 0px)), -50%);
  cursor: grab;
  filter: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
  will-change: transform;
  place-items: center;
  display: grid;
  position: relative;
}

.before-after__cursor:before {
  content: "";
  height: 100%;
  width: var(--spacing-0-5);
  background: rgb(var(--background));
  z-index: -1;
  position: absolute;
}

.before-after__cursor {
  top: 50%;
}

.before-after__cursor:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: var(--before-after-initial-drag-position, 0px);
}

.before-after__cursor:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: var(--before-after-initial-drag-position, 0px);
}

.before-after__cursor:active {
  cursor: grabbing;
}

@media screen and (min-width: 700px) {
  .before-after {
    --before-after-label-spacing: var(--spacing-8);
  }

  .before-after__cursor svg {
    width: var(--spacing-10);
    height: var(--spacing-10);
  }
}

.blog-posts__container {
  gap: var(--spacing-8);
  margin-block-start: var(--spacing-10);
  margin-block-end: var(--spacing-14);
  display: grid;
}

@media screen and (min-width: 700px) {
  .blog-posts__container {
    gap: var(--spacing-10);
  }
}

@media screen and (min-width: 1400px) {
  .blog-posts__container {
    gap: var(--spacing-20);
    margin-block-start: var(--spacing-20);
    margin-block-end: var(--spacing-28);
  }
}

.blog-banner {
  --banner-container-gap: var(--spacing-16);
  padding-block-start: var(--banner-spacing-block-added, 0px);
}

.blog-banner-content {
  padding-block-start: var(--banner-content-padding-block-start);
  padding-block-end: var(--spacing-16);
}

.blog-filter-list {
  --filter-list-item-padding: var(--spacing-2-5) var(--spacing-5);
  white-space: nowrap;
}

.blog-filter-list [aria-selected="true"] {
  background-color: rgb(var(--background-primary));
  color: rgb(var(--text-primary));
}

.blog-filter-list [aria-selected="false"] {
  opacity: .5;
  transition: opacity .2s ease-in-out;
}

@media screen and (pointer: fine) {
  .blog-filter-list [aria-selected="false"]:hover {
    opacity: 1;
  }
}

.blog-filter-list a {
  padding: var(--filter-list-item-padding);
  display: block;
}

.blog-banner__form {
  max-width: 500px;
  width: 100%;
  margin-inline-start: auto;
  margin-inline-end: auto;
  padding-inline-start: var(--spacing-6);
  padding-inline-end: var(--spacing-6);
}

@media screen and (min-width: 700px) {
  .blog-banner {
    --banner-container-gap: var(--spacing-28);
  }

  .blog-banner-content {
    padding-block-end: var(--spacing-28);
  }

  .blog-banner__form {
    box-sizing: content-box;
  }

  .blog-filter-list {
    --filter-list-item-padding: var(--spacing-4) var(--spacing-6);
  }
}

.blog-posts {
  --blog-articles-gap: var(--spacing-8);
  align-items: flex-start;
  gap: var(--blog-articles-gap);
  display: grid;
}

.blog-posts-newsletter {
  padding: var(--spacing-8);
  align-content: flex-start;
}

.blog-posts-newsletter > .form {
  gap: var(--spacing-2);
}

@media screen and (min-width: 700px) {
  .blog-posts {
    --article-per-row: var(--blog-posts-per-row, 2);
    --blog-articles-gap: var(--spacing-10);
    --blog-articles-margin-block: var(--spacing-14);
    grid: auto / repeat(var(--article-per-row), minmax(0, 1fr));
  }

  .blog-posts-newsletter {
    padding: var(--spacing-10);
  }

  .blog-posts-newsletter > .form {
    gap: var(--spacing-4);
  }

  .blog-posts-newsletter__content > svg {
    width: var(--spacing-8);
    height: var(--spacing-8);
  }
}

@media screen and (min-width: 1150px) {
  .blog-posts {
    --article-per-row: var(--blog-posts-per-row, 3);
  }
}

@media screen and (min-width: 1400px) {
  .blog-posts {
    --blog-articles-gap: var(--spacing-20);
  }
}

.cart {
  gap: var(--section-stack-spacing-block);
  max-width: 1350px;
  margin-inline-start: auto;
  margin-inline-end: auto;
  display: grid;
}

.cart-header {
  gap: var(--spacing-6);
  text-align: center;
  justify-items: center;
  display: grid;
}

.cart-header .free-shipping-bar {
  max-width: 450px;
}

.cart-order {
  align-items: start;
  gap: var(--spacing-6);
  display: grid;
}

.cart-form {
  gap: var(--spacing-5);
  padding: var(--spacing-6);
  border-width: 1px;
  display: grid;
}

@media screen and (min-width: 700px) {
  .cart-order {
    gap: var(--spacing-12);
  }

  .cart-form {
    gap: var(--spacing-6);
    padding: var(--spacing-10) var(--spacing-12);
  }
}

@media screen and (min-width: 1150px) {
  .cart-order {
    grid-template-columns: 1fr 380px;
  }

  .cart-order__recap {
    top: calc(var(--sticky-area-height)  + 20px);
    position: sticky;
  }
}

@media screen and (min-width: 1400px) {
  .cart-order {
    gap: var(--spacing-24);
  }
}

.free-shipping-bar {
  gap: var(--spacing-2);
  width: 100%;
  display: grid;
}

@media screen and (min-width: 700px) {
  .free-shipping-bar {
    gap: var(--spacing-4);
  }
}

.shipping-estimator {
  gap: var(--spacing-6);
  display: grid;
}

@media screen and (min-width: 700px) {
  .shipping-estimator__form {
    flex-wrap: wrap;
    margin-inline-end: var(--spacing-6);
    display: flex;
  }
}

.cart-drawer {
  --drawer-footer-padding: 1rem 1.5rem 1.5rem 1.5rem;
  --drawer-content-max-height: none;
  height: 100%;
  top: 0;
}

.cart-drawer::part(outside-close-button) {
  display: none;
}

.cart-drawer > [is="close-button"] {
  display: grid;
}

.cart-drawer__top {
  gap: var(--spacing-2);
  padding-block-end: var(--spacing-6);
  display: grid;
}

.cart-drawer__line-items > * + * {
  padding-block-start: var(--spacing-4);
}

.cart-drawer__note {
  width: 100%;
  visibility: hidden;
  transition: visibility .2s ease-in-out;
  position: absolute;
  bottom: calc(100% + 1px);
  overflow: hidden;
}

.cart-drawer__note:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: 0;
}

.cart-drawer__note:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: 0;
}

.cart-drawer__note-inner {
  padding: var(--spacing-5);
  background: rgb(var(--dialog-background));
  border-block-start-width: 1px;
  transition: transform .2s ease-in-out;
  transform: translateY(100%);
}

.cart-drawer__note[open] {
  visibility: visible;
}

.cart-drawer__note[open] > .cart-drawer__note-inner {
  transform: translateY(0);
}

.cart-drawer__recommendations {
  --horizontal-product-width: 390px;
}

@media screen and (min-width: 700px) {
  .cart-drawer {
    --drawer-footer-padding: 1.5rem 2.5rem 2.5rem 2.5rem;
  }

  .cart-drawer .free-shipping-bar {
    gap: var(--spacing-2-5);
  }

  .cart-drawer .horizontal-product-list-carousel {
    grid-template-columns: none;
  }

  .cart-drawer__top {
    gap: var(--spacing-4);
    padding-block-end: var(--spacing-8);
  }

  .cart-drawer__line-items > * + * {
    padding-block-start: var(--spacing-6);
  }

  .cart-drawer__note-inner {
    padding: var(--spacing-8) var(--spacing-10) var(--spacing-10) var(--spacing-10);
  }
}

.collection {
  --collection-sidebar-width: 250px;
  gap: var(--spacing-8);
  grid-template-columns: minmax(0, 1fr);
  margin-block-start: var(--spacing-8);
  margin-block-end: var(--section-outer-spacing-block);
  display: grid;
}

.collection__top-bar, .collection__facets {
  display: none;
}

.collection__results {
  scroll-padding-top: calc(var(--sticky-area-height)  + 20px);
  transition: opacity .1s;
}

.is-loading .collection__results {
  opacity: .4;
}

@media screen and (min-width: 700px) {
  .collection {
    margin-block-start: var(--spacing-12);
  }
}

@media screen and (min-width: 1000px) {
  .collection {
    column-gap: var(--spacing-12);
  }

  .collection--filters-sidebar {
    grid-template-columns: var(--collection-sidebar-width) minmax(0, 1fr);
  }

  .collection__top-bar {
    column-gap: var(--spacing-6);
    grid-column: 1 / -1;
    grid-template-columns: auto minmax(0, 1fr);
    display: grid;
  }

  .collection--filters-sidebar .collection__top-bar {
    grid-template-columns: inherit;
    column-gap: inherit;
  }

  .collection--filters-horizontal .collection__top-bar {
    row-gap: var(--spacing-8);
    grid-template-columns: 100%;
  }

  .collection__facets {
    display: block;
  }

  .facets-summary {
    align-items: start;
    gap: var(--spacing-4);
    grid-column-end: -1;
    display: flex;
  }

  .collection__facets-scroller {
    display: block;
    position: sticky;
    top: calc(var(--sticky-area-height)  + 20px);
  }

  .collection__pagination {
    grid-column-end: -1;
  }

  .availability-facet {
    align-items: center;
    gap: var(--spacing-4);
    display: flex;
  }

  .collection--filters-horizontal .availability-facet, .collection--filters-horizontal .sort-by-facet {
    min-width: max-content;
    flex: 1 0 0;
  }
}

@media screen and (min-width: 1400px) {
  .collection {
    --collection-sidebar-width: 300px;
  }
}

.sort-by-facet {
  justify-content: end;
  gap: var(--spacing-1);
  flex-shrink: 0;
  margin-inline-start: auto;
  display: flex;
  position: relative;
}

.facets__floating-filter {
  width: 100%;
  text-align: center;
  z-index: 1;
  position: fixed;
  bottom: var(--spacing-8);
}

.facets__floating-filter:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: 0;
}

.facets__floating-filter:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: 0;
}

.facets-drawer::part(header) {
  padding-block: var(--spacing-6);
}

.facets-drawer::part(body) {
  flex-grow: 1;
  padding-block-start: 0;
  padding-block-end: 0;
}

.facets-drawer .facets-vertical {
  min-height: 100%;
  flex-direction: column;
  display: flex;
}

.facets-drawer .accordion {
  border-block-start-width: 0;
}

.facets-drawer__floating-apply {
  background: linear-gradient(180deg, rgb(var(--background-primary) / 0), rgb(var(--background)));
  margin-block-start: auto;
  margin-inline-start: calc(-1 * var(--spacing-3));
  margin-inline-end: calc(-1 * var(--spacing-3));
  padding-block-start: var(--spacing-5);
  padding-block-end: var(--spacing-5);
  position: sticky;
  bottom: 0;
}

@media screen and (max-width: 699px) {
  .facets-drawer::part(header) {
    display: none;
  }

  .facets-drawer::part(content) {
    max-height: none;
    height: 65vh;
  }
}

@media screen and (min-width: 700px) {
  .facets-drawer__floating-apply {
    margin-inline-start: calc(-1 * var(--spacing-4));
    margin-inline-end: calc(-1 * var(--spacing-4));
    padding-block-start: var(--spacing-6);
    padding-block-end: var(--spacing-6);
  }
}

.facets-horizontal {
  justify-content: center;
  align-items: center;
  gap: var(--spacing-5) var(--spacing-10);
  flex-wrap: wrap;
  display: flex;
  position: relative;
}

.facets-horizontal > [aria-expanded] {
  transition: opacity .2s ease-in-out;
}

@supports selector(:has(*)) {
  .facets-horizontal > :has( ~ [aria-expanded="true"]), .facets-horizontal > [aria-expanded="true"] ~ [aria-expanded] {
    opacity: .5;
  }
}

.active-facets {
  align-items: center;
  gap: var(--spacing-2);
  flex-wrap: wrap;
  display: flex;
}

.active-facets > .facet-clear-all {
  margin-inline-start: var(--spacing-2);
}

@media screen and (min-width: 1000px) {
  .active-facets {
    margin-block-start: -12px;
    margin-block-end: -12px;
  }

  .active-facets > .facet-clear-all {
    margin-inline-start: var(--spacing-4);
  }

  .collection--filters-horizontal .active-facets {
    margin-block-start: 0;
    margin-block-end: 0;
  }
}

.removable-facet {
  gap: var(--spacing-3);
  padding: var(--spacing-2-5) var(--spacing-4);
  background: rgb(var(--text-color) / .1);
  color: rgb(var(--text-color));
  border-radius: var(--rounded-button);
  flex-shrink: 0;
  align-items: baseline;
  display: flex;
}

@media screen and (min-width: 700px) {
  .removable-facet {
    padding: var(--spacing-3) var(--spacing-5);
    gap: var(--spacing-4);
  }
}

.facet-dialog {
  width: max-content;
  max-width: min(1100px, 80vw);
  padding: var(--spacing-4);
  border-radius: min(8px, var(--rounded-button));
  background: rgb(var(--background-primary));
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  border-width: 1px;
  transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
  position: absolute;
  top: calc(100% + var(--spacing-6));
}

.facet-dialog:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: 0;
}

.facet-dialog:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: 0;
}

.facet-dialog > .color-list {
  margin: var(--spacing-1) var(--spacing-2);
  max-width: 550px;
}

.availability-facet + .facets-horizontal > .facet-dialog:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: auto;
}

.availability-facet + .facets-horizontal > .facet-dialog:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: auto;
}

.facet-dialog[open] {
  visibility: visible;
  opacity: 1;
}

.facet-dialog-option {
  padding: var(--spacing-3) var(--spacing-6);
  border-radius: var(--rounded-full);
  opacity: .7;
  transition: opacity .2s ease-in-out, background .2s ease-in-out;
  display: block;
}

:checked + .facet-dialog-option, .facet-dialog-option:hover {
  background: rgb(var(--text-primary) / .04);
  opacity: 1;
}

.collection-header {
  text-align: center;
  margin-block-start: var(--spacing-8);
  margin-block-end: var(--spacing-8);
}

@media screen and (min-width: 700px) {
  .collection-header {
    margin-block-start: var(--spacing-16);
    margin-block-end: var(--spacing-16);
  }
}

.collection-featured-product {
  max-width: 290px;
  display: grid;
}

.collection-featured-product > * {
  background: rgb(var(--text-primary) / .2);
  -webkit-backdrop-filter: blur(80px);
  backdrop-filter: blur(80px);
}

.collection-featured-product__title {
  padding: var(--spacing-1-5) var(--spacing-4);
  border-top-left-radius: var(--rounded-xs);
  border-top-right-radius: var(--rounded-xs);
  justify-self: end;
}

.collection-featured-product__content {
  border-radius: var(--rounded-xs);
  border-top-right-radius: 0;
}

.collection-list {
  grid: var(--collection-list-grid, none);
  align-items: start;
  gap: var(--grid-gutter);
  display: grid;
}

.collection-card {
  --transition-direction: 1;
  scroll-snap-align: center;
  scroll-snap-stop: always;
  overflow: hidden;
}

.collection-card--reverse-transition {
  --transition-direction: -1;
}

.collection-card__content-wrapper svg {
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s, visibility .2s, transform .2s;
  display: inline-block;
}

.collection-card__content + svg {
  transform: translateY(calc(var(--transition-direction) * 48px));
}

.collection-card__content {
  transition: transform .2s;
}

.collection-card__content:first-child {
  margin-block-end: -3rem;
}

@media screen and (min-width: 1000px) {
  .collection-card {
    scroll-snap-align: none;
  }
}

@media screen and (pointer: fine) {
  .collection-card:hover .collection-card__content {
    transform: translateY(calc(var(--transition-direction) * -8px));
  }

  .collection-card:hover .collection-card__content-wrapper svg {
    opacity: 1;
    visibility: visible;
    transform: translateY(-8px);
  }

  .collection-card:hover .collection-card__content + svg {
    transform: translateY(56px);
  }
}

@supports selector(:has(.selector)) {
  .shopify-section--collection-banner + .shopify-section--collection-list:not(:has(.section-header)) {
    --section-outer-spacing-block: var(--grid-gutter);
  }
}

@supports not selector(:has(.selector)) {
  .shopify-section--collection-banner + .shopify-section--collection-list {
    --section-outer-spacing-block: var(--grid-gutter);
  }
}

.contact-form {
  padding: var(--spacing-6);
}

@media screen and (min-width: 700px) {
  .contact-form {
    padding: var(--spacing-10) var(--spacing-12);
  }
}

.faq-availability {
  gap: var(--spacing-6);
  text-align: center;
  justify-items: center;
  display: grid;
}

.faq-availability > * {
  justify-items: inherit;
}

.section-stack__intro .faq-availability {
  text-align: start;
  justify-items: start;
}

.feature-chart {
  padding: 0 var(--container-gutter);
  scroll-padding-top: var(--sticky-area-height);
  display: block;
}

.feature-chart__table-row {
  --feature-chart-heading-width: 140px;
  grid-template-columns: var(--feature-chart-heading-width) repeat(var(--feature-chart-values-columns-count), minmax(140px, 1fr));
  gap: var(--spacing-1) var(--spacing-5);
  min-width: min-content;
  padding-block-start: var(--spacing-4);
  padding-block-end: var(--spacing-4);
  display: grid;
}

.feature-chart__toggle {
  text-align: center;
  border-top-width: 1px;
  justify-content: center;
  padding-block-start: var(--spacing-4);
  padding-block-end: var(--spacing-4);
  display: grid;
}

.feature-chart.is-expanded .feature-chart__toggle .circle-chevron {
  transform: rotate(180deg);
}

.feature-chart__product {
  --feature-chart-product-image-width: 80px;
  gap: var(--spacing-5);
  place-content: start;
  display: grid;
  position: relative;
}

.feature-chart__product img {
  width: var(--feature-chart-product-image-width);
}

.feature-chart__product .color-swatch {
  margin: 0;
}

.feature-chart__product .badge {
  position: absolute;
  top: 0;
}

.feature-chart__product .badge:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: 0;
}

.feature-chart__product .badge:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: 0;
}

.feature-chart__table-row--sticky {
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  background: rgb(var(--background));
  padding-block: var(--spacing-5);
  border-bottom-width: 1px;
  transition: opacity .2s, visibility .2s;
  position: fixed;
  top: var(--sticky-area-height);
  border-top-width: 0 !important;
}

.feature-chart__table-row--sticky.is-visible {
  opacity: 1;
  visibility: visible;
}

.feature-chart__table-row--sticky .feature-chart__product img {
  max-width: 48px;
}

.feature-chart__table-row--sticky .feature-chart__view-button-container {
  display: none;
}

@media screen and (max-width: 699px) {
  .feature-chart, .feature-chart__table {
    margin-inline-start: calc(-1 * var(--container-gutter));
    margin-inline-end: calc(-1 * var(--container-gutter));
    padding-inline-start: var(--container-gutter);
    padding-inline-end: var(--container-gutter);
    display: grid;
  }

  .feature-chart__table-row {
    grid-template-columns: repeat(var(--feature-chart-values-columns-count), minmax(140px, 1fr));
  }

  .feature-chart__table--multi-columns .feature-chart__table-row {
    grid-template-columns: repeat(var(--feature-chart-values-columns-count), 150px);
  }

  .feature-chart__heading {
    position: sticky;
  }

  .feature-chart__heading:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    left: 0;
  }

  .feature-chart__heading:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    right: 0;
  }

  .feature-chart__value {
    grid-row: 2;
  }
}

@media screen and (min-width: 700px) {
  .feature-chart {
    border-radius: var(--rounded);
  }

  .feature-chart__table-row--product > :first-child {
    grid-column-start: 2;
  }
}

@media screen and (min-width: 1000px) {
  .feature-chart {
    padding: var(--spacing-4) var(--spacing-12);
  }

  .feature-chart__table-row {
    --feature-chart-heading-width: 180px;
    column-gap: var(--spacing-6);
    padding-block-start: var(--spacing-6);
    padding-block-end: var(--spacing-6);
  }

  .feature-chart__table-row--product {
    padding-block-end: var(--spacing-10);
  }

  .feature-chart__table-row--sticky {
    padding-block: var(--spacing-6);
  }

  .feature-chart__toggle {
    padding-block-start: var(--spacing-5);
    padding-block-end: var(--spacing-6);
  }

  .feature-chart__product {
    --feature-chart-product-image-width: 150px;
    max-width: 260px;
  }

  .feature-chart__table-row--sticky .feature-chart__product {
    grid-auto-flow: column;
    justify-content: start;
  }

  .feature-chart__table-row--sticky .feature-chart__product img {
    max-width: 80px;
  }
}

.footer {
  --background: var(--footer-background);
  --text-color: var(--footer-text);
  --footer-padding-block: var(--spacing-12);
  --footer-part-gap: var(--spacing-10);
  --footer-block-list-gap: var(--spacing-10);
  --footer-block-gap: var(--spacing-4);
  --footer-aside-gap: var(--spacing-10);
  background: rgb(var(--background));
  color: rgb(var(--text-color));
  padding-block-start: var(--footer-padding-block);
  padding-block-end: var(--footer-padding-block);
}

.footer__wrapper {
  gap: var(--footer-part-gap);
  display: grid;
}

.footer__block-list {
  gap: var(--footer-block-list-gap);
  align-items: start;
  justify-content: var(--footer-block-list-justify-content, start);
  grid: auto-flow dense / repeat(2, minmax(0, 1fr));
  display: grid;
}

.footer__block {
  gap: var(--footer-block-gap);
  display: grid;
}

.footer__block--text, .footer__block--newsletter {
  grid-column: span 2;
}

.footer__block--newsletter {
  gap: var(--spacing-8);
  display: grid;
}

.footer__block--menu {
  max-width: 250px;
}

.footer__aside {
  gap: var(--footer-aside-gap);
  display: grid;
}

.footer__aside-top, .footer__aside-bottom {
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-8);
  display: grid;
}

@media screen and (min-width: 700px) {
  .footer {
    --footer-padding-block: var(--spacing-16);
    --footer-part-gap: var(--spacing-16);
    --footer-block-list-gap: var(--spacing-10) var(--spacing-24);
    --footer-block-gap: var(--spacing-6);
    --footer-aside-gap: var(--spacing-12);
  }

  .footer__block-list {
    flex-wrap: wrap;
    display: flex;
  }

  .footer__block--text {
    flex-basis: 25%;
  }

  .footer__block--newsletter {
    flex-basis: 100%;
  }

  .footer__block--menu {
    flex-shrink: 0;
  }

  .footer__aside-top, .footer__aside-bottom {
    display: flex;
  }

  .footer__copyright {
    order: -1;
  }
}

@media screen and (min-width: 1000px) {
  .footer {
    --footer-block-list-gap: var(--spacing-10) var(--spacing-12);
  }

  .footer__block-list {
    flex-wrap: nowrap;
  }

  .footer__block--newsletter {
    flex-basis: 33.3333%;
  }

  .footer__block--newsletter:only-child {
    flex-basis: 50%;
  }

  .footer__newsletter-form {
    max-width: 400px;
  }
}

@media screen and (min-width: 1150px) {
  .footer {
    --footer-block-list-gap: var(--spacing-10) var(--spacing-24);
  }

  .footer__block--menu:last-child {
    margin-inline-end: var(--spacing-10);
  }
}

.shopify-section--privacy-banner {
  z-index: 2;
  position: relative;
}

.privacy-bar {
  margin: var(--spacing-2);
  z-index: 1;
  transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
  position: fixed;
  bottom: 0;
  display: block !important;
}

.privacy-bar:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: 0;
}

.privacy-bar:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: 0;
}

.privacy-bar[hidden] {
  visibility: hidden;
  opacity: 0;
}

.privacy-bar__inner {
  width: 100%;
  padding: var(--spacing-6);
  background: rgb(var(--background-primary));
  color: rgb(var(--text-primary));
  border-radius: var(--rounded-sm);
  border-width: 1px;
}

.privacy-bar__close {
  position: absolute;
  top: var(--spacing-6);
}

.privacy-bar__close:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: var(--spacing-6);
}

.privacy-bar__close:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: var(--spacing-6);
}

@media screen and (min-width: 700px) {
  .privacy-bar {
    margin: var(--spacing-4);
  }

  .privacy-bar__inner {
    max-width: 445px;
  }
}

.gift-card {
  gap: var(--spacing-8);
  padding-block-start: var(--spacing-4);
  padding-block-end: var(--spacing-14);
  display: grid;
}

.gift-card__image-wrapper {
  justify-items: center;
  gap: var(--spacing-6);
  display: grid;
}

.gift-card__image {
  max-width: 160px;
}

.gift-card__info {
  gap: var(--spacing-5);
  display: grid;
}

.gift-card__issued-info {
  place-content: center;
  align-items: center;
  gap: var(--spacing-2);
  padding: var(--spacing-6);
  text-align: center;
  display: grid;
}

.gift-card__balance {
  line-height: 1;
  font-size: var(--gift-card-balance-font-size);
}

.gift-card__redeem-info:last-child {
  border-width: 1px;
}

.gift-card__redeem-box {
  padding: var(--spacing-6);
  align-items: center;
  gap: var(--spacing-4);
  display: grid;
}

.gift-card__redeem-box:last-child:not(:only-child) {
  border-top-width: 1px;
}

.gift-card__redeem-code {
  padding: var(--spacing-3-5);
  text-align: center;
  border-radius: var(--rounded-xs);
  border-width: 1px;
  font-weight: bold;
}

.gift-card__qr-code {
  width: 120px;
  aspect-ratio: 1;
  display: block;
}

.gift-card__qr-code > canvas {
  display: none;
}

.gift-card__scan {
  justify-content: center;
  align-items: center;
  gap: var(--spacing-8);
  display: flex;
}

@media screen and (min-width: 700px) {
  .gift-card {
    gap: var(--spacing-12);
    padding-block-start: var(--spacing-12);
    padding-block-end: var(--spacing-32);
  }

  .gift-card__image-wrapper {
    gap: var(--spacing-12);
  }

  .gift-card__image {
    max-width: 270px;
  }

  .gift-card__info {
    gap: var(--spacing-12);
  }

  .gift-card__issued-info {
    padding: var(--spacing-12);
  }

  .gift-card__redeem-info {
    display: flex;
  }

  .gift-card__redeem-box {
    padding: var(--spacing-12);
    gap: var(--spacing-6);
    max-width: 380px;
  }

  .gift-card__scan {
    justify-items: center;
    display: grid;
  }
}

@media screen and (min-width: 1000px) {
  .gift-card__info {
    justify-content: center;
    display: flex;
  }

  .gift-card__redeem-box:last-child:not(:only-child) {
    border-top-width: 0;
    border-inline-start-width: 1px;
  }
}

@media screen and (min-width: 1150px) {
  .gift-card__issued-info {
    flex-basis: var(--spacing-80);
  }
}

.header {
  --background: var(--header-background);
  --text-color: var(--header-text);
  --header-part-gap: var(--spacing-3);
  --header-icon-list-spacing: var(--spacing-4);
  --header-link-list-spacing: var(--spacing-6);
  --header-logo-opacity: 1;
  will-change: transform;
  margin-inline-start: auto;
  margin-inline-end: auto;
  transition: background .25s;
  display: block;
}

.header__wrapper {
  grid-template: var(--header-grid-template);
  align-items: center;
  gap: var(--header-part-gap);
  padding: var(--header-padding-block) max(var(--container-gutter), 50% - var(--container-max-width) / 2);
  color: rgb(var(--text-color));
  background: rgb(var(--background) / var(--header-background-opacity));
  -webkit-backdrop-filter: blur(var(--header-background-blur-radius));
  backdrop-filter: blur(var(--header-background-blur-radius));
  border-radius: inherit;
  transition: inherit;
  display: grid;
}

.header__logo {
  max-width: max-content;
  grid-area: logo;
  display: block;
  position: relative;
}

.header__logo-image {
  width: var(--header-logo-width);
  height: var(--header-logo-height);
  opacity: var(--header-logo-opacity, 1);
}

.header__logo-image--transparent {
  opacity: calc(1 - var(--header-logo-opacity));
  position: absolute;
  top: 0;
}

.header__logo-image--transparent:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: 0;
}

.header__logo-image--transparent:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: 0;
}

.header__logo-image:not(.header__logo-image--transparent):last-child {
  --header-logo-opacity: 1;
}

.header__logo-text {
  max-width: min(50vw, 350px);
  display: block;
}

.header__main-nav {
  grid-area: main-nav;
}

.header__secondary-nav {
  justify-content: flex-end;
  justify-self: end;
  align-items: center;
  gap: var(--spacing-3) var(--header-part-gap);
  flex-wrap: wrap;
  grid-area: secondary-nav;
  display: flex;
}

.header__link-list {
  display: none;
}

.header__link-list .icon-chevron-bottom {
  position: relative;
  top: 1px;
}

.header__icon-list {
  align-items: center;
  gap: var(--header-icon-list-spacing);
  display: flex;
}

.header__cart-count {
  top: -.3125rem;
  position: absolute;
}

.header__cart-count:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: .875rem;
}

.header__cart-count:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: .875rem;
}

@media screen and (min-width: 700px) {
  .header {
    --header-part-gap: var(--spacing-6);
    --header-icon-list-spacing: var(--spacing-6);
  }
}

@media screen and (min-width: 1150px) {
  .header__link-list {
    gap: var(--spacing-4) var(--header-link-list-spacing);
    display: flex;
  }
}

@media screen and (min-width: 1400px) {
  .header {
    --header-part-gap: var(--spacing-10);
    --header-link-list-spacing: var(--spacing-10);
  }
}

.js .header[allow-transparency] {
  transition: color .25s, background .25s, -webkit-backdrop-filter .25s, backdrop-filter .25s;
}

.js .header[allow-transparency]:not(.is-filled) {
  --header-background-opacity: 0;
  --header-logo-opacity: 0;
  --header-background-blur-radius: 0px;
  --text-color: var(--header-transparent-text-color);
}

.js .header[allow-transparency] .header__logo-image {
  transition: opacity .25s;
}

@supports selector(:has(> *)) {
  .js :has(.shopify-section:first-child [allow-transparent-header]) .header:not(.is-filled) {
    --header-background-opacity: 0;
    --header-logo-opacity: 0;
    --text-color: var(--header-transparent-text-color);
  }

  .js :has(.shopify-section:first-child [allow-transparent-header]) .shopify-section--header:not(:has(.header.is-filled)) {
    position: relative !important;
    top: 0 !important;
  }

  .header:has([open]) {
    --header-background-opacity: 1;
  }
}

.dropdown-menu {
  gap: var(--spacing-3);
  width: max-content;
  max-width: 350px;
  padding: var(--spacing-5) var(--spacing-7);
  background: rgb(var(--header-background));
  color: rgb(var(--header-text));
  border-radius: var(--rounded-sm);
  box-shadow: var(--shadow), 0 0 0 1px rgb(var(--text-color) / .12);
  z-index: 1;
  display: grid;
  position: absolute;
  top: calc(100% + var(--spacing-4-5));
}

.dropdown-menu:before {
  content: "";
  width: 100%;
  height: var(--spacing-4-5);
  position: absolute;
  bottom: 100%;
}

.dropdown-menu:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  left: calc(-1 * var(--spacing-4));
}

.dropdown-menu:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  right: calc(-1 * var(--spacing-4));
}

.dropdown-menu--restrictable {
  max-height: 80vh;
  overflow-y: auto;
}

.js .dropdown-menu {
  opacity: 0;
}

.dropdown-menu .dropdown-menu {
  top: calc(-1 * var(--spacing-5));
}

.dropdown-menu .dropdown-menu:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: calc(100% + var(--spacing-7));
}

.dropdown-menu .dropdown-menu:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: calc(100% + var(--spacing-7));
}

.dropdown-menu__item {
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-6);
  opacity: .7;
  transition: opacity .2s ease-in-out;
  display: flex;
}

[open] > .dropdown-menu__item, .dropdown-menu__item:hover {
  opacity: 1;
}

.mega-menu__promo-carousel {
  max-width: 300px;
  position: relative;
}

.mega-menu__carousel {
  grid: auto / auto-flow 45vw;
  display: grid;
}

@media screen and (min-width: 700px) {
  .mega-menu__carousel {
    --content-over-media-column-gap: var(--spacing-8);
    --content-over-media-row-gap: var(--spacing-6);
    --content-over-media-content-max-width: 70%;
    grid: none;
  }

  .mega-menu__carousel > * {
    grid-area: 1 / -1;
  }

  .mega-menu__carousel-controls button {
    transition: transform .2s;
  }

  .mega-menu__carousel-controls button:hover {
    transform: scale(1.1);
  }
}

@media screen and (min-width: 1400px) {
  .mega-menu__promo-carousel {
    max-width: 360px;
  }
}

.mega-menu {
  --mega-menu-block-padding: var(--spacing-10);
  --mega-menu-gap: var(--spacing-12);
  --mega-menu-nav-column-gap: var(--spacing-8);
  width: 100%;
  gap: var(--mega-menu-gap);
  justify-content: var(--mega-menu-justify-content);
  padding: var(--mega-menu-block-padding) max(var(--container-gutter), 50% - var(--container-max-width) / 2);
  max-height: calc(100vh - var(--sticky-announcement-bar-enabled, 0) * var(--announcement-bar-height, 0px)  - var(--header-height, 0px)  - 20px);
  overscroll-behavior-y: contain;
  background: rgb(var(--header-background));
  color: rgb(var(--header-text));
  mix-blend-mode: plus-lighter;
  border-block-start-width: 1px;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 9999px 0 9999px #0006;
}

.mega-menu:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: 0;
}

.mega-menu:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: 0;
}

.js .mega-menu {
  opacity: 0;
}

.mega-menu__nav {
  max-width: var(--column-list-max-width, max-content);
  gap: var(--mega-menu-nav-gap);
  flex-wrap: wrap;
  flex: auto;
  align-items: start;
  display: flex;
}

.mega-menu__nav > li {
  width: var(--mega-menu-nav-column-max-width);
}

@media screen and (min-width: 1400px) {
  .mega-menu {
    --mega-menu-gap: var(--spacing-16);
    --mega-menu-nav-column-gap: var(--spacing-12);
  }
}

@media screen and (min-width: 1600px) {
  .mega-menu {
    --mega-menu-gap: var(--spacing-20);
    --mega-menu-nav-column-gap: var(--spacing-16);
  }
}

.navigation-drawer {
  --panel-link-image-size: var(--spacing-12);
  --drawer-body-padding: var(--spacing-6);
  --navigation-drawer-width: 100vw;
  width: var(--navigation-drawer-width);
}

.navigation-drawer::part(body) {
  gap: var(--spacing-6);
  grid-template-rows: minmax(0, 1fr);
  padding: 0;
  display: grid;
  overflow-y: hidden;
}

.navigation-drawer::part(content) {
  min-height: 70vh;
  grid-template-rows: minmax(0, 1fr);
  display: grid;
}

.navigation-drawer .panel__scroller {
  height: 100%;
  align-content: flex-start;
  overflow-y: auto;
}

.navigation-drawer > [is="close-button"] {
  margin-inline-start: var(--drawer-body-padding);
}

.navigation-drawer[mobile-opening="bottom"] .panel__scroller {
  margin-block-start: calc(var(--drawer-body-padding) * -1);
  padding-block-start: var(--drawer-body-padding);
}

.navigation-drawer[mobile-opening="left"] {
  height: 100%;
}

.navigation-drawer[mobile-opening="left"]::part(body) {
  grid-template-rows: auto minmax(0, 1fr);
  padding-block-start: var(--drawer-body-padding);
}

.navigation-drawer[mobile-opening="left"] > [is="close-button"] {
  display: grid;
}

.navigation-drawer[mobile-opening="left"] .panel__wrapper {
  padding-block-start: 0;
}

.navigation-drawer [is="close-button"] {
  width: var(--spacing-10);
  height: var(--spacing-10);
  border-radius: var(--rounded-full);
  border-width: 1px;
  place-items: center;
  position: static;
}

.panel-list__wrapper {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.panel {
  gap: var(--spacing-8);
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.panel + .panel {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
}

.panel__wrapper {
  justify-content: var(--panel-wrapper-justify-content, space-between);
  gap: var(--spacing-6);
  padding: var(--drawer-body-padding);
  height: 100%;
  flex-direction: column;
  display: flex;
  overflow-y: auto;
}

.panel-footer {
  background-color: rgb(var(--background));
}

.panel-footer__localization-wrapper {
  padding-block-start: var(--spacing-4);
}

.panel-footer__account-link:not(:only-child) {
  margin-inline-start: auto;
}

.panel-link__image {
  min-width: var(--panel-link-image-size);
  width: var(--panel-link-image-size);
  height: var(--panel-link-image-size);
  object-fit: contain;
}

@media screen and (min-width: 700px) {
  .navigation-drawer {
    --panel-link-image-size: 3.25rem;
    --drawer-body-padding: var(--spacing-8);
    --navigation-drawer-width: 412px;
  }

  .navigation-drawer::part(body) {
    gap: var(--spacing-8);
    grid-template-rows: auto minmax(0, 1fr);
    padding-block-start: var(--drawer-body-padding);
  }

  .navigation-drawer [is="close-button"] {
    width: var(--spacing-12);
    min-height: var(--spacing-12);
    height: var(--spacing-12);
  }

  .navigation-drawer[mobile-opening="bottom"] .panel__scroller {
    margin-block-start: 0;
    padding-block-start: 0;
  }

  .panel__wrapper {
    padding-block-start: 0;
  }
}

@media screen and (min-width: 1150px) {
  .navigation-drawer[mega-menu] {
    --panel-link-image-size: 3.75rem;
    --drawer-body-padding: var(--spacing-10);
    --navigation-drawer-width: 472px;
  }

  .navigation-drawer[mega-menu]::part(body) {
    grid-template-rows: auto;
    padding: 0;
  }

  .navigation-drawer[mega-menu]::part(content):before {
    content: "";
    height: 100%;
    width: 1px;
    background-color: rgb(var(--text-color) / .12);
    position: absolute;
    top: 0;
    left: 440px;
  }

  .navigation-drawer[mega-menu] [is="close-button"] {
    display: grid;
  }

  .navigation-drawer[mega-menu] > .panel-list__wrapper {
    grid-template-columns: repeat(2, minmax(440px, 1fr));
    display: grid;
  }

  .navigation-drawer[mega-menu] > .panel-list__wrapper > .panel {
    position: static;
  }

  .navigation-drawer[mega-menu] .panel + .panel > .panel__wrapper {
    padding-block-start: 6.5rem;
  }

  .navigation-drawer[mega-menu] .panel__wrapper {
    padding: var(--drawer-body-padding);
  }

  .navigation-drawer[mega-menu] .panel__scroller {
    overflow-y: initial;
  }

  .navigation-drawer [is="close-button"] {
    transition: transform .2s ease-in-out;
  }

  .navigation-drawer [is="close-button"]:hover {
    transform: rotate(90deg);
  }

  [dir="rtl"] .navigation-drawer[mega-menu]::part(content):before {
    left: auto;
    right: 440px;
  }
}

.mega-menu .navigation-promo__wrapper {
  max-width: max-content;
  flex: 1;
}

.navigation-promo {
  grid: var(--navigation-promo-grid);
  align-content: start;
  align-items: start;
  gap: var(--navigation-promo-gap);
  display: grid;
}

.navigation-promo.navigation-promo--carousel .content-over-media p {
  max-width: 75%;
}

.navigation-promo .product-card {
  padding: var(--spacing-8);
  height: 100%;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.navigation-promo .product-card__figure {
  max-width: 160px;
  margin-block-end: var(--spacing-4);
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.navigation-promo .product-card__quick-buy {
  display: none;
}

.navigation-promo .product-card__info {
  padding: 0;
}

.navigation-promo .product-card__image {
  border-radius: 0;
}

.navigation-promo__carousel-controls {
  gap: var(--spacing-2);
  color: rgb(var(--text-color));
  z-index: 1;
  transition: color .2s ease-in-out;
  display: flex;
  position: absolute;
}

.navigation-promo__carousel-controls:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: var(--content-over-media-column-gap);
}

.navigation-promo__carousel-controls:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: var(--content-over-media-column-gap);
}

@media screen and (min-width: 1150px) {
  .navigation-promo__carousel-controls > button svg {
    transition: scale .2s ease-in-out;
  }

  .navigation-promo__carousel-controls > button:hover svg {
    scale: 1.1;
  }
}

.shopify-section--hot-spots {
  z-index: 1;
}

.hot-spot {
  --hot-spot-dot-size: var(--spacing-8);
  --hot-spot-dot-shadow-size: var(--spacing-2-5);
  top: var(--hot-spot-vertical-position);
  left: var(--hot-spot-horizontal-position);
  grid-area: unset !important;
  z-index: unset !important;
  position: absolute !important;
}

.hot-spot__dot {
  width: var(--hot-spot-dot-size);
  height: var(--hot-spot-dot-size);
  border-radius: var(--rounded-full);
  background: rgb(var(--hot-spot-background));
  color: rgb(var(--hot-spot-text-color));
  place-items: center;
  transition: transform .2s ease-in-out;
  display: grid;
  position: relative;
}

.hot-spot__dot:after {
  content: "";
  box-sizing: content-box;
  top: calc(-1 * var(--hot-spot-dot-shadow-size));
  left: calc(-1 * var(--hot-spot-dot-shadow-size));
  width: 100%;
  height: 100%;
  padding: var(--hot-spot-dot-shadow-size);
  background: radial-gradient(50% 50% at 50% 50%, rgb(var(--hot-spot-background) / 0) 0%, rgb(var(--hot-spot-background) / .3) 100%);
  border-radius: inherit;
  animation: 2s ease-in-out infinite alternate ping;
  position: absolute;
}

.hot-spot__dot[aria-expanded="true"] {
  transform: rotate(45deg);
}

.hot-spot-popover {
  --popover-body-padding: var(--spacing-6);
}

.hot-spot-popover::part(title) {
  display: none;
}

.hot-spot-popover::part(body) {
  text-align: start;
}

@media screen and (min-width: 700px) {
  .hot-spot {
    --hot-spot-dot-size: var(--spacing-12);
    --hot-spot-dot-shadow-size: var(--spacing-4);
  }

  .hot-spot__dot svg {
    width: 12px;
    height: 12px;
  }

  .hot-spot-popover {
    --popover-body-padding: var(--spacing-8);
  }
}

@media screen and (min-width: 1000px) {
  .hot-spot-popover {
    --popover-anchor-inline-spacing: calc(100% + var(--spacing-4-5));
  }

  .hot-spot-popover::part(content) {
    background: rgb(var(--hot-spot-content-background) / var(--hot-spot-content-opacity));
    color: rgb(var(--hot-spot-content-text-color));
    -webkit-backdrop-filter: blur(var(--hot-spot-content-blur-radius));
    backdrop-filter: blur(var(--hot-spot-content-blur-radius));
  }
}

.image-link-blocks {
  grid: var(--image-link-blocks-grid);
  gap: var(--spacing-2);
  align-items: start;
  display: grid;
}

.image-link-blocks__item .content-over-media .circle-chevron {
  background: rgb(var(--text-color));
  color: rgb(var(--background));
  opacity: 0;
  transition: opacity .2s ease-in-out;
}

.image-link-blocks__item .icon-circle-button-right-clipped {
  opacity: 0;
  transition: opacity .2s ease-in-out;
}

.image-link-blocks__inner {
  gap: var(--spacing-3);
  padding: var(--spacing-5);
  display: grid;
}

.image-link-blocks__inner .circle-chevron {
  opacity: 0;
  transition: opacity .2s ease-in-out;
}

.image-link-blocks__title {
  gap: var(--spacing-2-5);
  justify-content: center;
  align-items: center;
  margin-block-end: calc(-1 * var(--spacing-1));
  display: flex;
}

.image-link-blocks__title--animate {
  transform: translateX(calc(var(--transform-logical-flip) * 16px));
  transition: transform .2s;
}

@media screen and (min-width: 700px) {
  .image-link-blocks {
    gap: var(--grid-gutter);
  }

  .image-link-blocks__inner {
    gap: var(--spacing-5);
    padding: var(--spacing-10);
  }

  .image-link-blocks__title {
    margin-block-end: calc(-1 * var(--spacing-4));
  }
}

@media screen and (min-width: 1000px) {
  .image-link-blocks__item {
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }
}

@media screen and (pointer: fine) {
  .image-link-blocks__item:hover .content-over-media .circle-chevron {
    background: rgb(var(--text-color));
    color: rgb(var(--background));
    opacity: 1;
  }

  .image-link-blocks__item:hover .image-link-blocks__inner .circle-chevron, .image-link-blocks__item:hover .icon-circle-button-right-clipped {
    opacity: 1;
  }

  .image-link-blocks__item:hover .image-link-blocks__title--animate {
    transform: translateX(0);
  }
}

.shopify-section--collection-banner + .shopify-section--image-link-blocks {
  --section-outer-spacing-block: var(--grid-gutter);
}

.images-scrolling__content {
  gap: var(--spacing-4);
  display: grid;
}

.images-scrolling__counter {
  align-items: center;
  gap: var(--spacing-4);
  display: flex;
}

.images-scrolling__counter:before {
  content: "";
  height: .125rem;
  width: 1.5rem;
  background: currentColor;
}

.images-scrolling__icon {
  max-width: calc(var(--images-scrolling-item-icon-width) / 1.5);
}

@media screen and (min-width: 700px) {
  .images-scrolling__content {
    gap: var(--spacing-8);
    padding-block: var(--spacing-8);
    order: -1;
    align-content: start;
  }

  .images-scrolling__counter {
    gap: var(--spacing-6);
  }

  .images-scrolling__counter:before {
    width: 2.5rem;
  }

  .images-scrolling__icon {
    max-width: calc(var(--images-scrolling-item-icon-width));
  }
}

.images-scrolling-mobile {
  grid: var(--images-scrolling-grid);
  gap: var(--spacing-12) var(--container-gutter);
  display: grid;
}

.images-scrolling-mobile__item {
  align-content: start;
  gap: var(--spacing-4);
  display: grid;
}

@media screen and (min-width: 700px) {
  .images-scrolling-mobile {
    display: none;
  }
}

.images-scrolling-desktop {
  grid-template-columns: var(--images-scrolling-grid-template-columns);
  grid-template-rows: repeat(var(--images-scrolling-block-count), minmax(0, 1fr));
  column-gap: var(--spacing-8);
  display: grid;
}

.images-scrolling-desktop__media-wrapper {
  top: calc(var(--sticky-area-height)  + 20px);
  grid-column: media;
  position: sticky;
}

.images-scrolling-desktop__media-wrapper > * {
  object-fit: cover;
  object-position: center;
  will-change: transform;
}

.images-scrolling-desktop__media-wrapper > :first-child {
  max-height: calc(100vh - var(--sticky-area-height)  - 40px);
}

@supports (max-height: 100svh) {
  .images-scrolling-desktop__media-wrapper > :first-child {
    max-height: calc(100svh - var(--sticky-area-height)  - 40px);
  }
}

.images-scrolling-desktop__media-wrapper > :not(:first-child) {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.images-scrolling-desktop__content-list {
  grid-area: 1 / content / -1;
  grid-auto-columns: minmax(0, 1fr);
  align-items: center;
  display: grid;
}

@media screen and (min-width: 1000px) {
  .images-scrolling-desktop {
    column-gap: var(--spacing-16);
  }
}

@media screen and (min-width: 1150px) {
  .images-scrolling-desktop {
    column-gap: var(--spacing-28);
  }
}

@media screen and (max-width: 699px) {
  .images-scrolling-desktop {
    display: none;
  }
}

.impact-text {
  --impact-text-spacing: var(--spacing-10);
  gap: var(--impact-text-spacing);
  align-items: start;
  display: grid;
}

.impact-text--scroll {
  grid: auto / auto-flow var(--impact-text-auto-columns);
}

.impact-text--center {
  text-align: center;
  justify-content: safe center;
}

.impact-text--end {
  text-align: end;
  justify-content: end;
}

.impact-text__text {
  font-size: var(--impact-text-font-size);
  letter-spacing: 0;
  line-height: 1;
}

.impact-text__text:not(:only-child) {
  margin-bottom: .2em;
}

.impact-text__content {
  max-width: 750px;
}

.impact-text--center .impact-text__content {
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.impact-text--end .impact-text__content {
  margin-inline-start: auto;
}

@media screen and (min-width: 700px) {
  .impact-text {
    --impact-text-spacing: var(--spacing-8);
    grid: auto / auto-flow var(--impact-text-auto-columns);
  }

  .impact-text--center {
    justify-content: space-evenly;
  }
}

@media screen and (min-width: 1150px) {
  .impact-text {
    --impact-text-spacing: var(--spacing-12);
  }
}

.logo-list {
  grid: var(--logo-list-grid);
  gap: var(--spacing-2);
  display: grid;
}

.logo-list__item {
  padding: var(--spacing-6);
  border: 1px solid rgb(var(--logo-list-items-border));
  justify-content: center;
  align-items: center;
  display: flex;
}

.logo-list__image {
  width: 100%;
  max-width: var(--logo-list-image-max-width, none);
}

@media screen and (min-width: 700px) {
  .logo-list {
    gap: var(--spacing-6);
  }

  .logo-list__item {
    padding: var(--spacing-11);
  }
}

.media-grid {
  align-items: start;
  gap: calc(var(--grid-gutter) / 2);
  grid: auto-flow dense var(--media-grid-row-height) / repeat(2, minmax(0, 1fr));
  display: grid;
}

.media-grid__item {
  grid-area: span min(2, var(--media-grid-row-span)) / span min(2, var(--media-grid-column-span));
  height: 100%;
}

.media-grid__item > * {
  height: 100%;
}

@media screen and (min-width: 700px) {
  .media-grid {
    --calculated-row-height: max(150px, min(100vw / 5, var(--media-grid-row-height)));
    grid: auto-flow dense var(--calculated-row-height) / repeat(4, minmax(0, 1fr));
  }

  .media-grid__item {
    grid-area: span var(--media-grid-row-span) / span var(--media-grid-column-span);
  }
}

@media screen and (min-width: 1000px) {
  .media-grid {
    gap: var(--grid-gutter);
  }
}

.media-with-text {
  --media-with-text-content-padding: var(--spacing-10) var(--spacing-8);
  gap: var(--media-with-text-gap);
  display: grid;
  overflow: hidden;
}

.media-with-text__item {
  gap: min(var(--media-with-text-gap), var(--grid-gutter));
  grid-template: var(--media-with-text-item-grid-template);
  display: grid;
}

.media-with-text__media {
  grid-area: media;
  position: relative;
  overflow: hidden;
}

.media-with-text__media > * {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: inherit;
}

.media-with-text__content {
  padding: var(--media-with-text-content-padding);
  grid-area: content;
  display: grid;
}

.media-with-text__icon {
  max-width: calc(var(--media-with-text-item-icon-width) / 1.5);
  margin-block-end: var(--spacing-1);
  display: inline-block;
}

@media screen and (min-width: 700px) {
  .media-with-text {
    --media-with-text-content-padding: var(--spacing-16);
  }

  .media-with-text__icon {
    max-width: var(--media-with-text-item-icon-width);
    margin-block-end: var(--spacing-2);
  }
}

@media screen and (min-width: 1000px) {
  .media-with-text {
    --media-with-text-content-padding: var(--spacing-12);
  }

  .media-with-text__item {
    gap: var(--media-with-text-gap);
  }
}

@media screen and (min-width: 1150px) {
  .media-with-text {
    --media-with-text-content-padding: var(--spacing-18);
  }
}

@media screen and (min-width: 1400px) {
  .media-with-text {
    --media-with-text-content-padding: var(--spacing-20);
  }
}

.multi-column {
  --multi-column-list-column-max-gap: var(--grid-gutter);
  --multi-column-list-row-min-gap: var(--grid-gutter);
  --multi-column-item-gap: var(--spacing-6);
  grid: var(--multi-column-grid);
  gap: max(var(--multi-column-list-gap), var(--multi-column-list-row-min-gap)) min(var(--multi-column-list-column-max-gap), var(--multi-column-list-gap));
  display: grid;
}

.multi-column__item {
  align-content: start;
  gap: var(--multi-column-item-gap);
  display: grid;
}

@media screen and (min-width: 700px) {
  .multi-column {
    --multi-column-list-column-max-gap: var(--spacing-16);
    grid: auto / auto-flow 38vw;
  }
}

@media screen and (min-width: 1000px) {
  .multi-column {
    --multi-column-list-row-min-gap: var(--spacing-16);
    grid: auto / repeat(12, minmax(0, 1fr));
  }

  .multi-column__item {
    grid-column: var(--multi-column-item-column-count);
  }
}

@media screen and (min-width: 1400px) {
  .multi-column {
    --multi-column-list-column-max-gap: var(--multi-column-list-gap);
  }
}

.multiple-images-with-text {
  grid-template-columns: var(--multiple-images-with-text-grid-template-columns, none);
  gap: var(--section-stack-spacing-block) var(--multiple-images-with-text-column-gap, 0px);
  max-width: var(--multiple-images-with-text-max-width);
  isolation: isolate;
  justify-content: center;
  place-items: center;
  margin-inline-start: auto;
  margin-inline-end: auto;
  display: grid;
}

.multiple-images-with-text__content-with-nav {
  row-gap: var(--spacing-8);
  text-align: var(--multiple-images-with-text-alignment, center);
  justify-items: var(--multiple-images-with-text-alignment, center);
  max-width: 600px;
  width: 100%;
  justify-self: start;
  display: grid;
}

.multiple-images-with-text__image-list[layout="stacked"], .multiple-images-with-text__content-list {
  width: 100%;
  place-items: center;
  display: grid;
}

:is(.multiple-images-with-text__image-list[layout="stacked"], .multiple-images-with-text__content-list) > * {
  width: 100%;
  grid-area: 1 / -1;
}

.multiple-images-with-text__image-list {
  width: 100%;
  grid-template: var(--multiple-images-with-text-images-grid-template, none);
  align-items: var(--multiple-images-with-text-images-alignment, center);
  display: grid;
}

.multiple-images-with-text__image-list > img {
  width: 100%;
  max-width: var(--multiple-images-with-text-image-max-width, 390px);
  will-change: transform;
  outline: 1px solid #0000;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.multiple-images-with-text__image-list:not([layout="stacked"]) > img:first-child {
  grid-area: 1 / 2 / -1;
  margin-block-start: var(--multiple-images-with-text-main-image-offset, 0px);
}

.multiple-images-with-text__content-list {
  place-items: end start;
}

.multiple-images-with-text__image-list[layout="stacked"] {
  --multiple-images-with-text-image-max-width: 520px;
}

.multiple-images-with-text__image-list[layout="collage"] {
  gap: var(--grid-gutter);
}

@media screen and (max-width: 699px) {
  .multiple-images-with-text__content-with-nav .circle-button {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media screen and (min-width: 700px) {
  .multiple-images-with-text__content-with-nav {
    row-gap: var(--spacing-12);
  }
}

@media screen and (min-width: 1150px) {
  .multiple-images-with-text {
    --multiple-images-with-text-image-max-width: 500px;
  }
}

.newsletter-content {
  gap: var(--spacing-6);
  max-width: 780px;
  margin-inline-start: auto;
  margin-inline-end: auto;
  display: grid;
}

.newsletter-box {
  padding: var(--spacing-6);
}

.newsletter-content > .form {
  width: 100%;
}

@media screen and (min-width: 700px) {
  .newsletter {
    border-radius: inherit;
    overflow: hidden;
  }

  .newsletter-content {
    gap: var(--spacing-8);
  }

  .newsletter-content__icon {
    width: 48px;
    height: 48px;
  }

  .newsletter-box {
    padding: var(--spacing-12);
  }
}

@media screen and (min-width: 1000px) {
  .newsletter {
    grid-template-columns: .5fr .5fr;
    display: grid;
  }

  .newsletter-content > .form {
    max-width: 490px;
  }

  .newsletter > .newsletter-box {
    justify-content: start;
    align-items: center;
    display: grid;
  }

  .section-full .newsletter-box {
    background: none;
  }
}

@media screen and (min-width: 1150px) {
  .newsletter-box {
    padding: var(--spacing-16);
  }
}

@media screen and (min-width: 1400px) {
  .newsletter-box {
    padding: var(--spacing-20);
  }
}

.newsletter-drawer {
  --drawer-content-max-height: 80vh;
  --drawer-body-padding: 0;
  height: auto;
}

.newsletter-drawer__content {
  padding: var(--spacing-6) var(--spacing-8) var(--spacing-8);
}

@media screen and (min-width: 700px) {
  .newsletter-drawer {
    --drawer-content-max-height: calc(100vh - var(--spacing-8));
    width: calc(445px + var(--spacing-8));
  }

  .newsletter-drawer button[is="close-button"] {
    top: var(--spacing-8);
  }

  .newsletter-drawer button[is="close-button"]:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    right: var(--spacing-8);
  }

  .newsletter-drawer button[is="close-button"]:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    left: var(--spacing-8);
  }

  .newsletter-drawer__content {
    padding: var(--spacing-10) var(--spacing-12) var(--spacing-12);
  }
}

.not-found {
  --not-found-font-size: 210px;
  padding-block-start: var(--spacing-48);
  padding-block-end: var(--spacing-48);
  position: relative;
  overflow: hidden;
}

.not-found:before {
  content: "404";
  letter-spacing: 0;
  opacity: .1;
  pointer-events: none;
  font-weight: bold;
  font-size: var(--not-found-font-size);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 700px) {
  .not-found {
    --not-found-font-size: 400px;
    padding-block-start: var(--spacing-80);
    padding-block-end: var(--spacing-80);
  }
}

.page {
  gap: var(--spacing-12);
  max-width: var(--page-max-width, 80ch);
  grid-auto-columns: minmax(0, 1fr);
  margin-inline-start: auto;
  margin-inline-end: auto;
  display: grid;
}

.password {
  min-height: 100vh;
  min-height: 100dvh;
  grid-template-rows: 1fr;
  align-items: center;
  padding-block-start: var(--spacing-10);
  padding-block-end: var(--spacing-10);
  display: grid;
  position: relative;
}

.password:after {
  content: "";
  width: calc(100vw - var(--scrollbar-width, 0px));
  height: 60px;
  pointer-events: none;
  background-image: linear-gradient(#0000 0%, #0003 100%);
  margin-inline-start: calc(50% - 50vw);
  position: absolute;
  bottom: 0;
}

.password:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):after {
  left: 0;
}

.password:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):after {
  right: 0;
}

.password__main {
  gap: var(--spacing-10);
  max-width: 500px;
  width: 100%;
  display: grid;
}

.password__social-box {
  gap: var(--spacing-4);
  padding: var(--spacing-5) var(--spacing-8);
  justify-content: center;
  display: grid;
}

.password__aside {
  align-self: end;
  justify-items: center;
  gap: var(--spacing-2);
  margin-top: var(--spacing-8);
  display: grid;
  position: relative;
}

.password__storefront-drawer {
  --drawer-body-padding: var(--spacing-8);
}

.password__storefront-form {
  gap: var(--spacing-6);
  display: grid;
}

@media screen and (min-width: 700px) {
  .password--center {
    justify-items: center;
  }

  .password--end {
    justify-items: end;
  }

  .password__main {
    gap: var(--spacing-12);
  }

  .password__social-box {
    gap: var(--spacing-6);
    padding: var(--spacing-8) var(--spacing-12);
    align-items: center;
    display: flex;
  }

  .password__aside {
    justify-self: end;
    display: flex;
  }

  .password--center .password__aside {
    justify-self: center;
  }

  .password--end .password__aside {
    justify-self: start;
  }

  .password__storefront-drawer {
    --drawer-body-padding: var(--spacing-10);
  }
}

.press {
  --press-padding-inline: var(--spacing-5);
  gap: var(--spacing-6);
  max-width: var(--press-max-width);
  margin-inline-start: auto;
  margin-inline-end: auto;
  padding-inline-start: var(--press-padding-inline);
  padding-inline-end: var(--press-padding-inline);
  display: grid;
}

.press__list {
  align-items: flex-start;
  display: grid;
}

.press__list > * {
  grid-area: 1 / -1;
}

.press__list-item {
  gap: var(--spacing-8);
  justify-items: center;
  display: grid;
}

.press__list-item:not(.is-selected) {
  opacity: 0;
  visibility: hidden;
}

.press__list-item .rating {
  margin-block-end: calc(var(--press-padding-inline) * -1);
}

.press__list-item .blockquote:first-child {
  margin-block-start: 0;
}

.press__logo {
  border: 1px solid rgb(var(--press-items-border));
  justify-content: center;
  align-items: center;
  display: flex;
}

.press__logo.bg-custom {
  padding: var(--spacing-6);
}

.press__image {
  width: 100%;
  max-width: var(--press-image-max-width, none);
}

.press__controls {
  gap: var(--spacing-5);
  grid: auto / auto-flow;
  justify-content: center;
  align-items: center;
  display: grid;
}

@media screen and (min-width: 700px) {
  .press {
    --press-padding-inline: var(--spacing-8);
    gap: var(--spacing-10);
  }

  .press__list-item {
    gap: var(--spacing-10);
  }

  .press .rating__stars svg {
    width: 20px;
    height: 20px;
  }
}

.product {
  grid: var(--product-grid);
  gap: var(--container-gutter) var(--spacing-10);
  align-items: start;
  display: grid;
}

@media screen and (min-width: 1150px) {
  .product {
    column-gap: var(--spacing-12);
  }
}

@media screen and (min-width: 1600px) {
  .product {
    column-gap: var(--spacing-24);
  }
}

.product-info {
  --product-info-block-spacing: var(--spacing-6);
}

.product-info > * + * {
  margin-block-start: var(--product-info-block-spacing);
  margin-block-end: var(--product-info-block-spacing);
}

.product-info > :last-child {
  margin-block-end: 0;
}

.product-info__accordion {
  --product-info-block-spacing: 0;
}

.product-info__vendor, .product-info__title, .product-info__sku, .product-info__price, .product-info__rating, .product-info__payment-terms {
  --product-info-block-spacing: var(--spacing-2);
}

.product-info__badge-list {
  --product-info-block-spacing: var(--spacing-4);
}

.product-info__sku {
  display: block;
}

.product-info__badge-list {
  gap: var(--spacing-2);
  flex-wrap: wrap;
  display: flex;
}

.product-info__price .rating-with-text {
  justify-content: flex-start;
  display: flex;
}

.product-info__price .product-info__badge-list {
  align-self: center;
  margin-inline-start: var(--spacing-1);
}

.product-info__price .rating {
  margin-inline-start: auto;
}

.product-info__offer-list {
  gap: var(--spacing-2);
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  display: grid;
}

.product-info__share-buttons {
  display: none;
}

.native-share--disabled .product-info__share-buttons {
  display: block;
}

.product-info__native-share {
  display: none;
}

.native-share--enabled .product-info__native-share {
  align-items: center;
  gap: var(--spacing-2-5);
  opacity: .7;
  transition: opacity .2s ease-in-out;
  display: flex;
}

.native-share--enabled .product-info__native-share:hover {
  opacity: 1;
}

@media screen and (min-width: 700px) {
  .product-info__vendor, .product-info__title, .product-info__badge-list, .product-info__sku, .product-info__price, .product-info__rating, .product-info__payment-terms {
    --product-info-block-spacing: var(--spacing-4);
  }

  .product-info__buy-buttons {
    --product-info-block-spacing: var(--spacing-8);
  }

  .product-info__offer-list {
    gap: var(--spacing-4);
  }

  .product-info__complementary-products .horizontal-product-list-carousel:not(.separate) {
    border-width: 1px;
  }

  .product-info__complementary-products .horizontal-product-list-carousel > .horizontal-product-list, .product-info__complementary-products .horizontal-product-list-carousel .horizontal-product {
    border: none;
  }
}

@media screen and (min-width: 1000px) {
  .product-info {
    top: calc(var(--sticky-area-height)  + 20px);
    z-index: 1;
    position: sticky;
  }
}

.product-gallery {
  gap: var(--spacing-5);
  grid-auto-columns: minmax(0, 1fr);
  display: grid;
}

.product-gallery__ar-wrapper {
  gap: var(--spacing-2-5);
  display: grid;
}

.product-gallery__media-list-wrapper {
  display: grid;
  position: relative;
}

.product-gallery__media-list {
  grid: var(--product-gallery-media-list-grid);
  gap: var(--product-gallery-media-list-gap);
  align-items: start;
  transition: height .1s;
  display: grid;
}

.product-gallery__media {
  position: relative;
}

.product-gallery__media img {
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.product-gallery .page-dots--blurred {
  z-index: 1;
  justify-self: center;
  margin-inline-start: var(--spacing-2);
  margin-inline-end: var(--spacing-2);
  position: absolute;
  bottom: var(--spacing-2);
}

.product-gallery__thumbnail-list {
  align-items: end;
  gap: var(--spacing-2);
  grid-auto-columns: 56px;
  grid-auto-flow: column;
  display: grid;
}

.product-gallery__thumbnail {
  position: relative;
}

.product-gallery__thumbnail:after {
  content: "";
  width: 100%;
  height: 2px;
  opacity: 0;
  background: currentColor;
  margin-block-start: 4px;
  transition: opacity .15s;
  display: block;
}

.product-gallery__thumbnail[aria-current="true"]:after {
  opacity: 1;
}

.product-gallery__media-badge {
  width: var(--spacing-5);
  height: var(--spacing-5);
  background: rgb(var(--background-primary));
  border-radius: var(--rounded-button);
  border: 1px solid rgb(var(--text-color) / .12);
  place-content: center;
  display: grid;
  position: absolute;
  bottom: var(--spacing-2-5);
}

.product-gallery__media-badge:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: var(--spacing-1);
}

.product-gallery__media-badge:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: var(--spacing-1);
}

.product-gallery__zoom {
  position: absolute;
  top: var(--spacing-4);
}

.product-gallery__zoom:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: var(--spacing-4);
}

.product-gallery__zoom:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: var(--spacing-4);
}

.product-gallery__zoom > .circle-button {
  width: 2.1875rem;
  height: 2.1875rem;
}

.product-gallery__cursor.is-half-start svg {
  transform: rotate(180deg);
}

[data-shopify-xr-hidden] {
  visibility: hidden;
}

@media screen and (min-width: 700px) {
  .product-gallery__thumbnail-list {
    grid-auto-columns: 64px;
  }
}

@media screen and (max-width: 999px) {
  .product-gallery__thumbnail-list-wrapper {
    --scroll-shadow-size: 0px;
  }

  .product-gallery--mobile-dots .product-gallery__thumbnail-list-wrapper {
    display: none;
  }

  .product-gallery--mobile-expanded .product-gallery__media, .product-gallery--mobile-expanded .product-gallery__media > * {
    border-radius: 0;
  }

  .product-gallery--mobile-expanded .product-gallery__zoom:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    right: 0;
  }

  .product-gallery--mobile-expanded .product-gallery__zoom:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    left: 0;
  }
}

@media screen and (min-width: 1000px) {
  .product-gallery {
    gap: var(--spacing-6) var(--spacing-12);
  }

  .product-gallery__thumbnail-list-wrapper {
    align-items: center;
    gap: var(--spacing-4);
    grid-auto-flow: column;
    display: grid;
    position: relative;
  }

  .product-gallery__thumbnail-list {
    gap: var(--spacing-4);
  }

  .product-gallery__thumbnail-list-wrapper > button {
    z-index: 1;
    opacity: 0;
    margin-top: -3px;
    transition: opacity .1s, transform .1s;
    position: absolute;
    transform: scale(.6);
  }

  .product-gallery__thumbnail-list-wrapper > button:first-child:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    left: 8px;
  }

  .product-gallery__thumbnail-list-wrapper > button:first-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    right: 8px;
  }

  .product-gallery__thumbnail-list-wrapper > button:last-child:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    right: 8px;
  }

  .product-gallery__thumbnail-list-wrapper > button:last-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    left: 8px;
  }

  .product-gallery__thumbnail-list-wrapper > button[disabled] {
    opacity: 0;
  }

  .product-gallery__thumbnail-list-wrapper:hover > button:not([disabled]) {
    opacity: 1;
    transform: scale(1);
  }

  .product-gallery--desktop-grid .product-gallery__thumbnail-list-wrapper {
    display: none;
  }

  .product-gallery__media--expand {
    grid-column: span 2;
  }

  .product-gallery__zoom {
    opacity: 0;
    position: absolute;
    top: auto;
    bottom: var(--spacing-6);
    transform: scale(.8);
  }

  .product-gallery__zoom:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    right: var(--spacing-6);
  }

  .product-gallery__zoom:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    left: var(--spacing-6);
  }

  .product-gallery__zoom svg {
    width: 17px;
    height: 17px;
  }

  .product-gallery__zoom > .circle-button {
    width: 3.5rem;
    height: 3.5rem;
  }

  .product-gallery--desktop-grid .product-gallery__zoom {
    transition: opacity .2s ease-in-out, transform .2s ease-in-out;
  }

  .product-gallery--desktop-grid .product-gallery__media:hover .product-gallery__zoom {
    opacity: 1;
    transform: scale(1);
  }

  .product-gallery--desktop-carousel .product-gallery__zoom {
    opacity: 1;
    visibility: visible;
  }

  .product-gallery--desktop-carousel:has([data-media-type*="video"].is-selected) .product-gallery__zoom {
    opacity: 0;
    visibility: hidden;
  }

  [data-shopify-xr-hidden] {
    display: none;
  }
}

@media screen and (min-width: 1150px) {
  .product-gallery--desktop-thumbnails-left {
    grid: auto / auto-flow auto minmax(0, 1fr);
    align-items: start;
  }

  .product-gallery--desktop-thumbnails-left .product-gallery__thumbnail-list-wrapper {
    order: -1;
    grid-auto-flow: row;
    justify-items: center;
    overflow: auto;
  }

  .product-gallery--desktop-thumbnails-left .product-gallery__thumbnail-list {
    gap: var(--spacing-2-5);
    max-height: 600px;
    grid-auto-flow: row;
    overflow: auto;
  }

  .product-gallery--desktop-thumbnails-left .product-gallery__thumbnail-list-wrapper > button {
    transform: rotate(90deg);
  }
}

.revealed-image {
  z-index: -1;
  display: inline;
  position: relative;
}

.revealed-image__scroll-tracker {
  height: 100%;
  position: absolute;
  top: 0;
}

.revealed-image__scroller {
  height: 180vh;
  height: 180lvh;
  display: block;
  position: sticky;
  top: 0;
  overflow: hidden;
}

.revealed-image__wrapper {
  height: 100vh;
  height: 100lvh;
  top: calc(var(--sticky-area-height) / 2);
  grid-auto-rows: minmax(0, 1fr);
  place-items: center;
  display: grid;
  position: relative;
}

.revealed-image__wrapper > * {
  grid-area: 1 / -1;
}

.revealed-image__wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.revealed-image__wrapper > .revealed-image__image-clipper, .revealed-image__wrapper > .revealed-image__content--inside {
  clip-path: inset(37% 37% 41%);
  height: 100%;
  width: 100%;
}

.revealed-image__content {
  height: 100%;
  width: 100%;
  padding-inline: max(var(--container-gutter), 50% - var(--container-max-width) / 2);
  text-align: center;
  z-index: 1;
  opacity: 0;
  place-content: center;
  display: grid;
}

.revealed-image__content-inner {
  max-width: 1300px;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.rich-text {
  display: flex;
}

.rich-text__wrapper {
  max-width: var(--rich-text-max-width);
}

.rich-text .prose, .split-rich-text .prose {
  align-items: start;
  display: grid;
}

.rich-text .image-icon {
  margin-block-end: 0 !important;
}

.split-rich-text {
  display: none;
}

@media screen and (min-width: 1000px) {
  .split-rich-text {
    gap: var(--spacing-24);
    grid-template-columns: repeat(2, minmax(0, 1fr));
    display: grid;
  }
}

@media screen and (min-width: 1150px) {
  .split-rich-text {
    gap: var(--spacing-32);
  }
}

.scrolling-text {
  overflow: hidden;
}

.scrolling-text__wrapper {
  display: grid;
}

.scrolling-text__text {
  line-height: normal;
  font-size: var(--scrolling-text-font-size);
  padding-inline-start: min(1em, 2rem);
  padding-inline-end: min(1em, 2rem);
}

@supports (overflow: clip) {
  .scrolling-text {
    overflow-x: clip;
    overflow-y: visible;
  }

  .scrolling-text__text {
    line-height: 1;
  }
}

@media screen and (min-width: 700px) {
  .scrolling-text__text {
    padding-inline-start: min(1.5em, 4rem);
    padding-inline-end: min(1.5em, 4rem);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .scrolling-text__wrapper {
    grid: auto / auto-flow max-content;
  }

  .scrolling-text--auto .scrolling-text__text {
    animation: translateFull var(--marquee-animation-duration, 0s) linear infinite;
  }

  .scrolling-text--scroll .scrolling-text__wrapper {
    min-width: min-content;
    transform: translateX(calc(var(--transform-logical-flip) * (50vw - 10% * var(--visibility-progress, 0))));
    transition: transform 50ms linear;
  }
}

@media (prefers-reduced-motion: reduce) {
  .scrolling-text {
    --scrolling-text-font-size: var(--text-h0);
  }

  .scrolling-text__wrapper {
    text-align: center;
    justify-content: center;
  }
}

.search-input {
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-4);
  border-bottom-width: 2px;
  padding-block-end: var(--spacing-2-5);
  transition: border-bottom-color .1s;
  display: flex;
}

.search-input:focus-within {
  border-bottom-color: rgb(var(--text-color));
}

.search-input > input {
  -webkit-appearance: none;
  appearance: none;
  width: 0;
  font-size: var(--text-h5);
  background: none;
  border-radius: 0;
  outline: none;
  flex-grow: 1;
  font-weight: bolder;
}

.search-input > input::placeholder {
  color: rgb(var(--text-color) / .5);
}

.search-input > input[type="search"]::-webkit-search-decoration, .search-input > input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

.search-input > [type="reset"] {
  font-size: var(--text-sm);
  opacity: 0;
  transition: opacity .2s;
}

.search-input > input:not(:placeholder-shown) ~ [type="reset"] {
  opacity: 1;
}

@media screen and (min-width: 700px) {
  .search-input {
    padding-block-end: var(--spacing-3);
  }

  .search-input > input {
    font-size: var(--text-h4);
  }

  .search-input > [type="reset"] {
    font-size: var(--text-base);
  }
}

.search-drawer {
  --drawer-body-padding: 1.25rem 1.5rem 1.5rem 1.5rem;
  --drawer-content-max-height: 100%;
  height: 100%;
}

.search-drawer::part(content) {
  height: max-content;
  overflow: auto;
}

.search-drawer::part(outside-close-button), .search-drawer::part(close-button) {
  display: none;
}

@media screen and (min-width: 700px) {
  .search-drawer {
    --drawer-body-padding: 2rem 2.5rem 2.5rem 2.5rem;
  }

  .search-drawer::part(content) {
    height: 100%;
  }
}

.predictive-search {
  min-width: 0;
  display: block;
}

.predictive-search__tabs {
  gap: var(--spacing-4);
  display: grid;
}

.predictive-search__tabs::part(tab-list) {
  white-space: nowrap;
  scrollbar-width: none;
  gap: var(--spacing-4);
  margin-inline: calc(-1 * var(--container-outer-width));
  padding-inline: var(--container-outer-width);
  scroll-padding-inline: var(--container-outer-width);
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  display: grid;
  overflow-x: auto;
  overflow-y: hidden;
}

.predictive-search__tab-item {
  align-items: center;
  gap: var(--spacing-2);
  display: flex;
}

.predictive-search__tab-item [aria-selected] {
  transition: opacity .2s ease-in-out;
}

.predictive-search__tab-item [aria-selected="false"]:not(:hover) {
  opacity: .3;
}

.predictive-search-result {
  align-items: center;
  gap: var(--spacing-5);
  display: flex;
}

.predictive-search-result > img {
  width: 5rem;
  flex-shrink: 0;
}

@media screen and (min-width: 700px) {
  .predictive-search__tabs {
    gap: var(--spacing-6);
  }

  .predictive-search__tabs::part(tab-list) {
    gap: var(--spacing-5);
  }

  .predictive-search-result {
    gap: var(--spacing-6);
  }

  .predictive-search-result > img {
    width: 6rem;
  }
}

@media screen and (min-width: 1000px) {
  .search-drawer--full {
    --search-full-gap: 40px;
    width: 100%;
  }

  .search-drawer--full .search-input {
    padding-block-end: var(--spacing-6);
  }

  .search-drawer--full .predictive-search__tabs {
    gap: var(--spacing-8);
  }

  .search-drawer--full .predictive-search__tabs::part(tab-list), .search-drawer--full .predictive-search__tabs::part(tab-panels), .search-drawer--full .predictive-search__skeleton-full-width {
    grid-template-columns: repeat(var(--predictive-search-column-count, 4), minmax(var(--predictive-search-column-width, 0px), 1fr));
    gap: 0 var(--search-full-gap);
    display: grid;
  }

  .search-drawer--full .predictive-search__tab-content {
    display: grid !important;
  }

  .search-drawer--full .predictive-search__tab-item {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .search-drawer--full .predictive-search__tab-content {
    align-content: start;
  }

  .search-drawer--full .predictive-search__tab-content ~ .predictive-search__tab-content:before {
    content: "";
    margin-left: calc(-1 * var(--search-full-gap) / 2);
    width: 1px;
    height: 100%;
    background: rgb(var(--text-color) / .12);
    position: absolute;
    top: 0;
  }

  .search-drawer--full [role="tab"] {
    pointer-events: none;
    opacity: 1 !important;
  }
}

@media screen and (min-width: 1400px) {
  .search-drawer--full {
    --search-full-gap: 96px;
  }
}

.main-search-form .search-input {
  width: 260px;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

@media screen and (min-width: 700px) {
  .main-search-form .search-input {
    width: 490px;
  }
}

.shop-the-look {
  --shop-the-look-gap: var(--spacing-5);
  --shop-the-look-grid: auto-flow / auto;
  grid: var(--shop-the-look-grid);
  align-items: flex-start;
  gap: var(--shop-the-look-gap);
  display: grid;
}

.shop-the-look__dot {
  --dot-size: 10px;
  top: calc(var(--shop-the-look-dot-top)  - (var(--dot-size) / 2));
  left: calc(var(--shop-the-look-dot-left)  - (var(--dot-size) / 2));
  width: var(--dot-size);
  height: var(--dot-size);
  border-radius: var(--rounded-full);
  background-color: rgb(var(--shop-the-look-dot-background));
  transition: transform .2s ease-in-out;
  position: absolute;
}

.shop-the-look__dot:after {
  content: "";
  top: calc(50% + (var(--spacing-6) * -1));
  left: calc(50% + (var(--spacing-6) * -1));
  width: var(--spacing-12);
  height: var(--spacing-12);
  background: radial-gradient(50% 50% at 50% 50%, rgb(var(--shop-the-look-dot-background) / 0), rgb(var(--shop-the-look-dot-background) / .3));
  border-radius: var(--rounded-full);
  animation: 2s ease-in-out infinite alternate ping;
  position: absolute;
}

.shop-the-look__dot[aria-current="true"] {
  transform: scale(1.3);
}

.shop-the-look__products {
  display: grid;
}

.shop-the-look__carousel > .horizontal-product-list {
  grid: var(--shop-the-look-carousel-grid);
  display: grid;
}

@media screen and (pointer: fine) {
  .shop-the-look__dot:hover {
    transform: scale(1.3);
  }
}

@media screen and (min-width: 700px) {
  .section-boxed .shop-the-look {
    --shop-the-look-gap: var(--calculated-section-spacing-inline);
    --shop-the-look-grid: auto / 55% minmax(0, 1fr);
  }

  .shop-the-look {
    --shop-the-look-gap: var(--spacing-18);
    --shop-the-look-grid: auto / minmax(0, 1fr) 40%;
  }

  .shop-the-look__products {
    justify-items: flex-end;
    gap: var(--spacing-8);
    display: grid;
  }

  .shop-the-look__carousel {
    width: 100%;
    display: grid;
  }

  .shop-the-look__carousel > * {
    grid-area: 1 / -1;
  }

  .shop-the-look__controls {
    gap: var(--spacing-4);
    grid: auto / auto-flow;
    margin-inline-start: auto;
    margin-inline-end: auto;
    display: grid;
  }
}

@media screen and (min-width: 1000px) {
  .section-boxed .shop-the-look__carousel {
    --shop-the-look-carousel-width: 100%;
  }

  .shop-the-look {
    --shop-the-look-gap: 0;
    --shop-the-look-grid: auto / repeat(2, minmax(0, 1fr));
  }

  .shop-the-look__products {
    justify-items: center;
  }

  .shop-the-look__carousel {
    --shop-the-look-carousel-width: 60%;
    width: var(--shop-the-look-carousel-width);
  }
}

@media screen and (min-width: 1150px) {
  .section-boxed .shop-the-look {
    --shop-the-look-grid: auto / repeat(2, 1fr);
  }

  .section-boxed .shop-the-look__carousel {
    --shop-the-look-carousel-width: 60%;
  }
}

.slideshow {
  background: var(--slideshow-background);
}

.slideshow, .slideshow__carousel {
  color: rgb(var(--text-color));
  display: block;
  position: relative;
}

.slideshow__slide {
  position: relative;
}

.slideshow__slide:not(.is-selected) {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.slideshow__slide-background {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.slideshow__controls {
  z-index: 1;
  position: absolute;
  bottom: var(--container-gutter);
}

.slideshow__controls:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: max(var(--container-gutter), 50% - var(--container-max-width) / 2);
}

.slideshow__controls:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: max(var(--container-gutter), 50% - var(--container-max-width) / 2);
}

.slideshow__controls .circle-button--fill {
  background: rgb(var(--slideshow-controls-background));
  color: rgb(var(--slideshow-controls-color));
}

.slideshow__controls .circle-button--bordered {
  color: rgb(var(--slideshow-controls-color));
}

.slideshow__controls .numbered-dots > * {
  color: rgb(var(--slideshow-controls-color) / .7);
  border-color: rgb(var(--slideshow-controls-color) / .3);
}

.slideshow__controls .numbered-dots > [aria-current="true"] {
  color: rgb(var(--slideshow-controls-color));
}

.slideshow__controls .stretching-dots > * {
  background: rgb(var(--slideshow-controls-color));
}

.slideshow__cursor.is-half-start .icon-chevron-right {
  transform: rotate(180deg);
}

.slideshow__cursor-ring {
  --radius: 27;
  --circumference: calc(2px * (22 / 7) * var(--radius));
  position: absolute;
  top: 0;
  left: 0;
}

.slideshow__cursor-ring circle {
  stroke-opacity: 1;
  stroke-dashoffset: 0;
  stroke-dasharray: calc(var(--circumference) * var(--progress, 0)), var(--circumference);
  transform: rotate(-90deg);
}

.slideshow .content-over-media--auto svg {
  height: min(700px, 50vmax);
}

@media screen and (max-width: 699px) {
  .slideshow__controls .circle-button {
    width: var(--spacing-10);
    height: var(--spacing-10);
  }

  .slideshow--multiple-slides :is(.place-self-end-start, .place-self-end-center) {
    padding-block-end: 2.5rem;
  }
}

.slideshow--boxed {
  --content-over-media-gap: var(--section-inner-spacing-inline);
  background: var(--slideshow-background);
  padding-block-start: var(--section-inner-max-spacing-block);
  padding-block-end: var(--section-inner-max-spacing-block);
  padding-inline-start: max(var(--container-gutter), 50% - var(--container-max-width) / 2);
  padding-inline-end: max(var(--container-gutter), 50% - var(--container-max-width) / 2);
}

.slideshow--boxed .content-over-media > :not(img, svg) {
  padding-block-start: 0 !important;
}

.shopify-section:first-child .slideshow--boxed[allow-transparent-header] {
  padding-block-start: max(var(--section-inner-max-spacing-block), var(--header-height));
}

@media screen and (min-width: 1400px) {
  .slideshow__controls {
    bottom: var(--spacing-14);
  }

  .slideshow__controls:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    right: var(--spacing-14);
  }

  .slideshow__controls:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    left: var(--spacing-14);
  }
}

.shopify-payment-button__button[disabled] {
  opacity: 1 !important;
}

.shopify-payment-button__button--branded {
  border-radius: var(--rounded-button) !important;
  min-height: 100% !important;
  overflow: hidden !important;
}

.shopify-payment-button__button--unbranded {
  --button-outline-color: var(--button-background, --button-background-primary);
  padding: var(--shopify-payment-button-padding, var(--spacing-4) var(--spacing-8)) !important;
  -webkit-appearance: none !important;
  border-radius: var(--rounded-button) !important;
  background: rgb(var(--button-background, var(--button-background-primary)) / var(--button-background-opacity, 1)) !important;
  color: rgb(var(--button-text-color, var(--button-text-primary))) !important;
  font-family: var(--text-font-family) !important;
  font-style: var(--text-font-style) !important;
  font-weight: bolder !important;
  font-size: var(--shopify-payment-button-font-size, var(--text-base)) !important;
  text-align: center !important;
  line-height: inherit !important;
  letter-spacing: var(--text-letter-spacing) !important;
  text-decoration: none !important;
  transition: background-color .15s ease-in-out, color .15s ease-in-out, box-shadow .15s ease-in-out !important;
  display: inline-block !important;
  position: relative !important;
  box-shadow: inset 0 0 0 2px #0000 !important;
}

.shopify-payment-button__button--branded.shopify-payment-button__button--branded:focus-within {
  outline: 2px solid Highlight;
  outline: 5px auto -webkit-focus-ring-color;
}

.shopify-payment-button__button--branded.shopify-payment-button__button--branded [role="button"] {
  line-height: inherit !important;
  padding: var(--shopify-payment-button-padding, var(--spacing-4) var(--spacing-8)) !important;
}

.shopify-payment-button__more-options[aria-hidden="true"] {
  display: none;
}

.shopify-payment-button__more-options {
  text-underline-position: under !important;
  text-align: center !important;
  margin-top: .5em !important;
  text-decoration: underline !important;
  text-decoration-thickness: 1px !important;
}

@media screen and (min-width: 700px) {
  .shopify-payment-button__button--unbranded, .shopify-payment-button__button--branded.shopify-payment-button__button--branded [role="button"] {
    padding: var(--shopify-payment-button-padding, 1.075rem var(--spacing-10)) !important;
  }
}

.shopify-policy__container {
  gap: var(--spacing-12) !important;
  max-width: none !important;
  padding: var(--spacing-14) 0 !important;
  grid-auto-columns: minmax(0, 1fr) !important;
  margin-inline-start: max(var(--container-gutter), 50% - 80ch / 2) !important;
  margin-inline-end: max(var(--container-gutter), 50% - 80ch / 2) !important;
  display: grid !important;
}

.shopify-challenge__container, .shopify-email-marketing-confirmation__container {
  padding: var(--spacing-14) 0 !important;
}

@media screen and (min-width: 700px) {
  .shopify-policy__container, .shopify-challenge__container, .shopify-email-marketing-confirmation__container {
    padding-block-start: var(--spacing-16) !important;
    padding-block-end: var(--spacing-16) !important;
  }
}

.tabs {
  max-width: var(--tabs-max-width);
  margin-inline-start: auto;
  margin-inline-end: auto;
  display: grid;
}

@media screen and (max-width: 699px) {
  .tabs-inner {
    display: none;
  }
}

@media screen and (min-width: 700px) {
  .tabs-inner {
    text-align: center;
    gap: var(--spacing-10);
    display: grid;
  }

  .tabs-inner::part(tab-list) {
    box-shadow: inset 0 -2px rgb(var(--text-color) / .1);
    grid: auto / auto-flow 1fr;
    align-items: end;
    display: grid;
    position: relative;
  }

  .tabs-inner::part(tab-list):after {
    content: "";
    height: 2px;
    width: calc(100% / var(--item-count));
    background: rgb(var(--text-color));
    transform: translateX(calc(var(--selected-index) * var(--transform-logical-flip) * 100%));
    transition: transform .3s;
    position: absolute;
    bottom: 0;
  }

  .tabs-inner::part(tab-list):not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):after {
    left: 0;
  }

  .tabs-inner::part(tab-list):is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):after {
    right: 0;
  }

  .tabs-nav__button {
    padding-block-start: var(--spacing-6);
    padding-block-end: var(--spacing-6);
    transition: color .2s ease-in-out;
  }

  .tabs-nav__button[aria-selected="true"] {
    color: rgb(var(--text-color));
  }
}

.testimonial-list {
  grid: var(--testimonial-list-grid);
  gap: var(--grid-gutter);
  align-items: start;
  display: grid;
}

.testimonial {
  align-content: start;
  gap: var(--spacing-4);
  padding: var(--spacing-6);
  scroll-snap-align: center;
  scroll-snap-stop: always;
  display: grid;
}

.testimonial__image {
  width: var(--spacing-10);
}

@media screen and (min-width: 700px) {
  .testimonial {
    gap: var(--spacing-5);
    padding: var(--spacing-8);
  }

  .testimonial__image {
    width: var(--spacing-14);
  }
}

@media screen and (min-width: 1150px) {
  .testimonial {
    scroll-snap-align: none;
  }
}

@media screen and (min-width: 1400px) {
  .testimonial {
    gap: var(--spacing-6);
    padding: var(--spacing-10);
  }
}

.text-with-icons {
  gap: var(--spacing-8);
  text-align: var(--text-with-icons-text-align);
  justify-content: var(--text-with-icons-justify);
  display: grid;
}

.text-with-icons__list {
  grid: auto / var(--text-with-icons-template);
  row-gap: var(--spacing-6);
  min-width: 0;
  align-items: start;
  display: grid;
}

.text-with-icons__item {
  gap: var(--text-with-icons-gap);
  justify-items: var(--text-with-icons-justify);
  padding-inline-start: var(--spacing-6);
  padding-inline-end: var(--spacing-6);
  display: grid;
}

@media screen and (min-width: 1000px) {
  :not(.is-scrollable) .text-with-icons__list {
    gap: var(--spacing-6);
  }

  :not(.is-scrollable) .text-with-icons__item {
    padding-inline-start: 0;
    padding-inline-end: 0;
  }
}

@media screen and (min-width: 1400px) {
  :not(.is-scrollable) .text-with-icons__list {
    gap: var(--spacing-10);
  }
}

.timeline {
  gap: var(--spacing-8);
  grid-template-columns: minmax(0, 1fr);
  display: grid;
}

.timeline__slider {
  align-items: start;
  display: grid;
}

.timeline__slider > * {
  grid-area: 1 / -1;
}

.timeline__slide {
  gap: var(--spacing-6);
  text-align: center;
  grid-template-columns: minmax(0, 1fr);
  align-items: start;
  display: grid;
}

.timeline__image {
  width: 100%;
  max-width: 410px;
  margin-inline-start: auto;
  margin-inline-end: auto;
  position: relative;
}

.timeline__controls {
  --timeline-dot-size: var(--spacing-4);
  --timeline-dot-padding-inline-end: var(--spacing-4);
  padding-block-start: calc((var(--timeline-dot-size) / 2));
}

.timeline__nav {
  grid: auto / repeat(var(--timeline-nav-item-count), minmax(0, 1fr));
  width: max-content;
  min-width: 100%;
  display: grid;
}

.timeline__nav-bar {
  width: 100%;
  height: 2px;
  background-color: rgb(var(--text-color) / .12);
  position: absolute;
  top: -1px;
}

.timeline__nav-bar:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: 0;
}

.timeline__nav-bar:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: 0;
}

.timeline__nav-item {
  gap: var(--spacing-3);
  max-width: 200px;
  min-width: 100px;
  align-content: flex-start;
  padding-inline-end: var(--timeline-dot-padding-inline-end);
  display: grid;
  position: relative;
}

.timeline__nav-item:before {
  content: "";
  width: var(--timeline-dot-size);
  height: var(--timeline-dot-size);
  border-radius: var(--rounded-full);
  background-color: rgb(var(--background));
  border-width: 2px;
  margin-block-start: calc((var(--timeline-dot-size) / 2) * -1);
  transition: border-color .2s ease-in-out, background-color .2s ease-in-out;
}

.timeline__nav-item[aria-current="true"]:before {
  border-color: rgb(var(--text-color));
  background-color: rgb(var(--text-color));
}

.timeline__nav-label {
  opacity: .5;
  transition: opacity .2s ease-in-out;
}

.timeline__nav-item[aria-current="true"] .timeline__nav-label {
  opacity: 1;
}

@media screen and (min-width: 700px) {
  .timeline {
    gap: var(--spacing-14);
  }

  .timeline__slide {
    gap: var(--spacing-10);
  }

  .timeline__slide > .prose {
    padding-inline-start: var(--spacing-14);
    padding-inline-end: var(--spacing-14);
  }

  .timeline__content {
    gap: var(--spacing-6);
  }

  .timeline__controls {
    --timeline-dot-size: var(--spacing-5);
    --timeline-dot-padding-inline-end: var(--spacing-5);
  }

  .timeline__nav-item {
    min-width: 160px;
  }
}

@media screen and (min-width: 1000px) {
  .timeline__slide {
    text-align: left;
    grid: auto / repeat(2, minmax(0, 1fr));
    gap: 0;
  }

  .timeline__slide > .timeline__image-wrapper {
    padding-inline-end: 16.5%;
  }

  .timeline__slide > .prose {
    z-index: 1;
    padding-inline-start: 0;
    padding-inline-end: 0;
    position: relative;
  }

  .timeline__image {
    max-width: initial;
  }

  .timeline__image-wrapper {
    position: relative;
  }

  .timeline__controls {
    align-items: flex-start;
    gap: var(--spacing-10);
    display: flex;
  }

  .timeline__controls.scroll-area {
    overflow: visible;
  }

  .timeline__buttons {
    gap: var(--spacing-4);
    margin-block-start: calc(var(--spacing-6) * -1);
    display: flex;
  }

  .timeline__nav {
    width: 100%;
    min-width: auto;
    padding-block-start: 0;
  }

  .timeline__nav-item {
    min-width: auto;
  }
}

@media screen and (min-width: 1150px) {
  .timeline__slide > .prose {
    padding-inline-end: 16.5%;
  }

  .timeline__content {
    gap: var(--spacing-8);
  }
}

@media screen and (min-width: 1400px) {
  .timeline__slide > .prose {
    max-width: 85%;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only[type="checkbox"]:focus-visible + label, .sr-only[type="radio"]:focus-visible + label {
  outline: 2px solid Highlight;
  outline: 2px solid -webkit-focus-ring-color;
}

.skip-to-content:focus-visible {
  z-index: 9999;
  width: auto;
  height: auto;
  clip: auto;
  color: #fff;
  background: #000;
  padding: 6px 12px;
  font-weight: bold;
  overflow: auto;
}

.tap-area {
  position: relative;
}

.tap-area:before {
  content: "";
  inset: calc(-1 * var(--spacing-2-5));
  position: absolute;
}

.js .js\:hidden, .no-js .no-js\:hidden {
  display: none;
}

@media print {
  .print\:hidden {
    display: none;
  }
}

@keyframes translateFull {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% * var(--transform-logical-flip)));
  }
}

@keyframes ping {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(.8);
  }
}

@media screen and (pointer: fine) {
  .zoom-image--enabled .zoom-image {
    transform-origin: center;
    transition: transform 1.5s cubic-bezier(.22, 1, .36, 1);
  }

  .zoom-image--enabled .group:hover .zoom-image {
    transform: scale(1.06);
  }
}

.reveal {
  opacity: 0;
}

.reveal-invisible {
  opacity: 0;
  visibility: hidden;
}

@media (prefers-reduced-motion: no-preference) {
  .js [reveal-js], .js [reveal-on-scroll="true"] {
    opacity: 0;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .js reveal-items {
    opacity: 0;
  }
}

.border {
  border-width: 1px;
}

.border-x {
  border-left-width: 1px;
  border-right-width: 1px;
}

.border-y {
  border-block-start-width: 1px;
  border-block-end-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-s {
  border-block-start-width: 1px;
}

.border-e {
  border-block-end-width: 1px;
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  border-inline-start-width: 1px;
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  border-block-start-width: 1px;
}

.ring {
  box-shadow: 0 0 0 1px rgb(var(--text-color) / .12);
}

.ring-current {
  box-shadow: 0 0 0 1px rgb(var(--text-color));
}

.ring-inset {
  box-shadow: inset 0 0 0 1px rgb(var(--text-color) / .12);
}

.rounded-xs {
  border-radius: var(--rounded-xs);
}

.rounded-sm {
  border-radius: var(--rounded-sm);
}

.rounded {
  border-radius: var(--rounded);
}

.rounded-lg {
  border-radius: var(--rounded-lg);
}

.rounded-full {
  border-radius: var(--rounded-full);
}

.text-custom {
  color: rgb(var(--text-color));
}

.text-accent {
  color: rgb(var(--accent));
}

.text-success {
  color: rgb(var(--success-text));
}

.text-warning {
  color: rgb(var(--warning-text));
}

.text-error {
  color: rgb(var(--error-text));
}

.text-subdued {
  color: rgb(var(--text-color) / .7);
}

.text-on-sale {
  color: rgb(var(--on-sale-text));
}

.text-stroke {
  -webkit-text-stroke: 1px currentColor;
  -webkit-text-fill-color: transparent;
}

.text-gradient {
  background-image: var(--gradient);
  color: #0000;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  -webkit-background-clip: text;
  background-clip: text;
}

.text-stroke.text-gradient {
  color: rgb(var(--background));
  -webkit-text-stroke-color: transparent;
  -webkit-text-fill-color: unset;
}

.bg-custom {
  background: rgb(var(--background));
}

.bg-gradient {
  background-image: var(--gradient);
}

.bg-text {
  background: rgb(var(--text-color));
}

.bg-error {
  background: rgb(var(--error-background));
}

.bg-success {
  background: rgb(var(--success-background));
}

.bg-warning {
  background: rgb(var(--warning-background));
}

.bg-secondary {
  background: rgb(var(--text-color) / .05);
}

.opacity-0 {
  opacity: 0;
}

.backdrop-blur {
  -webkit-backdrop-filter: blur(var(--backdrop-blur, 0px));
  backdrop-filter: blur(var(--backdrop-blur, 0px));
}

.filter-invert {
  filter: invert();
}

.shadow-sm {
  filter: drop-shadow(var(--shadow-sm));
}

.shadow {
  filter: drop-shadow(var(--shadow));
}

.shadow-md {
  filter: drop-shadow(var(--shadow-md));
}

.shadow-block {
  box-shadow: var(--shadow-block);
}

.cross-fade {
  display: block;
}

.cross-fade > * {
  transition: opacity .2s ease-in-out;
}

.cross-fade > :last-child {
  display: none;
}

@media screen and (pointer: fine) {
  .cross-fade > :last-child {
    opacity: 0;
    display: block;
  }

  .cross-fade:hover > :first-child {
    opacity: 0;
  }

  .cross-fade:hover > :last-child {
    opacity: 1;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.scroll-area {
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  overscroll-behavior-x: contain;
  overflow-x: auto;
  overflow-y: hidden;
}

.scroll-area::-webkit-scrollbar {
  display: none;
}

.snap-start {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.snap-center {
  scroll-snap-align: center;
  scroll-snap-stop: always;
}

:not(.is-scrollable) ~ .peer-not-scrollable\:hidden, .hidden, .empty\:hidden:empty {
  display: none;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.contents {
  display: contents;
}

@media screen and (min-width: 700px) {
  .sm\:hidden {
    display: none;
  }

  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:table-cell {
    display: table-cell;
  }
}

@media screen and (min-width: 1000px) {
  .md\:hidden {
    display: none;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:table-cell {
    display: table-cell;
  }
}

@media screen and (min-width: 1150px) {
  .lg\:hidden {
    display: none;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:table-cell {
    display: table-cell;
  }
}

@media screen and (min-width: 1400px) {
  .xl\:hidden {
    display: none;
  }

  .xl\:block {
    display: block;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:grid {
    display: grid;
  }

  .xl\:table-cell {
    display: table-cell;
  }
}

@media screen and (min-width: 1600px) {
  .\32 xl\:hidden {
    display: none;
  }

  .\32 xl\:block {
    display: block;
  }

  .\32 xl\:flex {
    display: flex;
  }

  .\32 xl\:grid {
    display: grid;
  }

  .\32 xl\:table-cell {
    display: table-cell;
  }
}

@media screen and (max-width: 699px) {
  .sm-max\:hidden {
    display: none;
  }
}

@media screen and (max-width: 999px) {
  .md-max\:hidden {
    display: none;
  }
}

@media screen and (max-width: 1149px) {
  .lg-max\:hidden {
    display: none;
  }
}

@media screen and (max-width: 1399px) {
  .xl-max\:hidden {
    display: none;
  }
}

@media screen and (max-width: 1599px) {
  .\32 xl-max\:hidden {
    display: none;
  }
}

@media screen and (pointer: fine) {
  .pointer-fine\:hidden {
    display: none;
  }

  .pointer-fine\:block {
    display: block;
  }
}

@media not screen and (pointer: fine) {
  .pointer-coarse\:hidden {
    display: none;
  }

  .pointer-coarse\:block {
    display: block;
  }
}

@media (prefers-reduced-motion: reduce) {
  .motion-reduce\:hidden {
    display: none;
  }

  .motion-reduce\:block {
    display: block;
  }
}

.invisible {
  visibility: hidden;
}

.relative {
  position: relative;
}

.wrap {
  flex-wrap: wrap;
}

.grow {
  flex-grow: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.justify-start {
  justify-content: start;
}

.justify-center {
  justify-content: safe center;
}

.justify-end {
  justify-content: end;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-center {
  justify-self: center;
}

.justify-self-end {
  justify-self: end;
}

.justify-items-start {
  justify-items: start;
}

.justify-items-center {
  justify-items: safe center;
}

.justify-items-end {
  justify-items: end;
}

.align-start {
  align-items: start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: end;
}

.align-self-start {
  align-self: start;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: end;
}

.align-self-stretch {
  align-self: stretch;
}

.place-self-start {
  place-self: start;
}

.place-self-start-center {
  place-self: start center;
}

.place-self-start-end {
  place-self: start end;
}

.place-self-center {
  place-self: center;
}

.place-self-center-start {
  place-self: center start;
}

.place-self-center-end {
  place-self: center end;
}

.place-self-end {
  place-self: end;
}

.place-self-end-start {
  place-self: end start;
}

.place-self-end-center {
  place-self: end center;
}

@media screen and (min-width: 700px) {
  .sm\:text-start {
    text-align: start;
  }

  .sm\:text-center {
    text-align: center;
  }

  .sm\:text-end {
    text-align: end;
  }

  .sm\:place-self-start {
    place-self: start;
  }

  .sm\:place-self-start-center {
    place-self: start center;
  }

  .sm\:place-self-start-end {
    place-self: start end;
  }

  .sm\:place-self-center {
    place-self: center;
  }

  .sm\:place-self-center-start {
    place-self: center start;
  }

  .sm\:place-self-center-end {
    place-self: center end;
  }

  .sm\:place-self-end {
    place-self: end;
  }

  .sm\:place-self-end-start {
    place-self: end start;
  }

  .sm\:place-self-end-center {
    place-self: end center;
  }

  .sm\:justify-items-center {
    justify-items: safe center;
  }
}

.align-top {
  vertical-align: top;
}

.align-center {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.w-0 {
  width: 0;
}

.min-w-full {
  min-width: 100%;
}

.min-h-full {
  min-height: 100%;
}

.mx-auto {
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.my-auto {
  margin-block-start: auto;
  margin-block-end: auto;
}

.aspect-short {
  aspect-ratio: 4 / 3;
}

.aspect-tall {
  aspect-ratio: 2 / 3;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.aspect-video iframe {
  width: 100%;
  height: 100%;
}

.gap-0\.5 {
  gap: var(--spacing-0-5);
}

.gap-1 {
  gap: var(--spacing-1);
}

.gap-1\.5 {
  gap: var(--spacing-1-5);
}

.gap-2 {
  gap: var(--spacing-2);
}

.gap-2\.5 {
  gap: var(--spacing-2-5);
}

.gap-3 {
  gap: var(--spacing-3);
}

.gap-3\.5 {
  gap: var(--spacing-3-5);
}

.gap-4 {
  gap: var(--spacing-4);
}

.gap-4\.5 {
  gap: var(--spacing-4-5);
}

.gap-5 {
  gap: var(--spacing-5);
}

.gap-5\.5 {
  gap: var(--spacing-5-5);
}

.gap-6 {
  gap: var(--spacing-6);
}

.gap-8 {
  gap: var(--spacing-8);
}

.gap-10 {
  gap: var(--spacing-10);
}

.gap-12 {
  gap: var(--spacing-12);
}

@media screen and (min-width: 700px) {
  .sm\:gap-0\.5 {
    gap: var(--spacing-0-5);
  }

  .sm\:gap-1 {
    gap: var(--spacing-1);
  }

  .sm\:gap-1\.5 {
    gap: var(--spacing-1-5);
  }

  .sm\:gap-2 {
    gap: var(--spacing-2);
  }

  .sm\:gap-2\.5 {
    gap: var(--spacing-2-5);
  }

  .sm\:gap-3 {
    gap: var(--spacing-3);
  }

  .sm\:gap-3\.5 {
    gap: var(--spacing-3-5);
  }

  .sm\:gap-4 {
    gap: var(--spacing-4);
  }

  .sm\:gap-4\.5 {
    gap: var(--spacing-4-5);
  }

  .sm\:gap-5 {
    gap: var(--spacing-5);
  }

  .sm\:gap-5\.5 {
    gap: var(--spacing-5-5);
  }

  .sm\:gap-6 {
    gap: var(--spacing-6);
  }

  .sm\:gap-8 {
    gap: var(--spacing-8);
  }

  .sm\:gap-10 {
    gap: var(--spacing-10);
  }

  .sm\:gap-12 {
    gap: var(--spacing-12);
  }
}

.overflow-hidden {
  overflow: hidden;
}

.object-fill {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}

.object-fill:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: 0;
}

.object-fill:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: 0;
}

.object-fill, .object-fill-safe, .object-cover {
  object-fit: cover;
  object-position: center;
}

.object-contain {
  object-fit: contain;
  object-position: center;
}

.placeholder {
  background: rgb(var(--text-primary) / .3);
  fill: rgb(var(--text-primary) / .7);
  color: rgb(var(--text-primary) / .7);
  display: block;
}

.bold {
  font-weight: bold;
}

.text-xxs {
  font-size: 10px;
  line-height: 1.7;
}

.text-xs {
  font-size: var(--text-xs);
  line-height: 1.7;
}

.text-sm {
  font-size: var(--text-sm);
  line-height: 1.6;
}

.text-base {
  font-size: var(--text-base);
  line-height: 1.6;
}

.text-lg {
  font-size: var(--text-lg);
  line-height: 1.6;
}

.heading, .h0, .h1, .h2, .h3, .h4, .h5, .h6, .prose :is(h1, h2, h3, h4, h5, h6) {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  font-style: var(--heading-font-style);
  letter-spacing: var(--heading-letter-spacing);
  text-transform: var(--heading-text-transform);
  overflow-wrap: anywhere;
}

.h0 {
  font-size: var(--text-h0);
  line-height: 1;
}

.h1, .prose h1:not(.h0, .h1, .h2, .h3, .h4, .h5, .h6) {
  font-size: var(--text-h1);
  line-height: 1.1;
}

.h2, .prose h2:not(.h0, .h1, .h2, .h3, .h4, .h5, .h6) {
  font-size: var(--text-h2);
  line-height: 1.1;
}

.h3, .prose h3:not(.h0, .h1, .h2, .h3, .h4, .h5, .h6) {
  font-size: var(--text-h3);
  line-height: 1.2;
}

.h4, .prose h4:not(.h0, .h1, .h2, .h3, .h4, .h5, .h6) {
  font-size: var(--text-h4);
  line-height: 1.3;
}

.h5, .prose h5:not(.h0, .h1, .h2, .h3, .h4, .h5, .h6) {
  font-size: var(--text-h5);
  line-height: 1.4;
}

.h6, .prose h6:not(.h0, .h1, .h2, .h3, .h4, .h5, .h6) {
  font-size: var(--text-h6);
  line-height: 1.4;
}

@media screen and (min-width: 700px) {
  .sm\:text-xxs {
    font-size: 10px;
    line-height: 1.7;
  }

  .sm\:text-xs {
    font-size: var(--text-xs);
    line-height: 1.7;
  }

  .sm\:text-sm {
    font-size: var(--text-sm);
    line-height: 1.6;
  }

  .sm\:text-base {
    font-size: var(--text-base);
    line-height: 1.6;
  }

  .sm\:text-lg {
    font-size: var(--text-lg);
    line-height: 1.6;
  }

  .sm\:h0 {
    font-size: var(--text-h0);
    line-height: 1;
  }

  .sm\:h1 {
    font-size: var(--text-h1);
    line-height: 1.1;
  }

  .sm\:h2 {
    font-size: var(--text-h2);
    line-height: 1.1;
  }

  .sm\:h3 {
    font-size: var(--text-h3);
    line-height: 1.2;
  }

  .sm\:h4 {
    font-size: var(--text-h4);
    line-height: 1.3;
  }

  .sm\:h5 {
    font-size: var(--text-h5);
    line-height: 1.4;
  }

  .sm\:h6 {
    font-size: var(--text-h6);
    line-height: 1.4;
  }
}

.line-through {
  text-decoration: line-through;
}

.break-all {
  overflow-wrap: anywhere;
}

.hyphenate {
  -webkit-hyphens: auto;
  hyphens: auto;
}

.truncate-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}

.line-clamp {
  -webkit-line-clamp: var(--line-clamp-count, 2);
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}
